import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Header } from '../../components/Header/Header';
import { BackgroundStyled } from '../../components/BackgroundStyled/BackgroundStyled';
import { GroupRowTable } from '../../components/Tables/GroupRowTable/GroupRowTable';
import {
  getRolesList,
  setFilterRoleStatus,
  getRolesData,
} from '../../redux/actions/roleManagement';
import { Api } from '../../api/Api';
import { getColumnsForRoleManagement } from '../../components/Tables/GroupRowTable/columns/roleManagementColumns';
import { RoleManagementHeader } from '../../components/Tables/GroupRowTable/headers/RoleManagementHeader';
import { Modals } from '../../components/Modals/Modals';
import { setUserData } from '../../redux/actions/login';
import { setUserDataToLocalStorage } from '../../utils/auth';

export const RoleManagement = () => {
  const FIRST_PAGE = 1;

  const [show, setShow] = useState(false);
  const [currentRoleId, setCurrentRoleId] = useState('');
  const [modalName, setModalName] = useState('');
  const [countItemsPerPage, setCountItemsPerPage] = useState('');

  const api = useMemo(() => new Api(), []);

  const theme = useSelector((state) => state.theme.theme);
  const rolesList = useSelector((state) => state.roleManagement.rolesList);
  const currentPage = useSelector((state) => state.roleManagement.currentPage);
  const lastPage = useSelector((state) => state.roleManagement.lastPage);
  const firstPage = useSelector((state) => state.roleManagement.firstPage);
  const nextPage = useSelector((state) => state.roleManagement.nextPage);
  const prevPage = useSelector((state) => state.roleManagement.prevPage);
  const totalCount = useSelector((state) => state.roleManagement.totalCount);
  const filterStatus = useSelector((state) => state.roleManagement.filterStatus);
  const defaultItemsPerPage = useSelector((state) => state.roleManagement.defaultItemsPerPage);
  const userData = useSelector((state) => state.login.userData);
  const itemsPerPage = useSelector((state) => state.login.userData.user_settings.items_per_page);

  const dispatch = useDispatch();

  const getFormattedRolesData = (data) => {
    if (!data) return [];
    const formattedData = [];

    data.forEach((el) => {
      let applications = [];

      el.permissions &&
      el.permissions.forEach((permission) => {
        if (!applications.find((item) => item.application === permission.app_id)) {
          applications.push({
            application: permission.app_id,
            view: permission.action === 'View' ? [permission.name] : [],
            create: permission.action === 'Create' ? [permission.name] : [],
            edit: permission.action === 'Edit' ? [permission.name] : [],
            disable: permission.action === 'Delete' ? [permission.name] : [],
          });
        } else {
          applications = applications.map((el) => {
            return {
              ...el,
              view: permission.action === 'View' ? [...el.view, permission.name] : [...el.view],
              create:
                permission.action === 'Create' ? [...el.create, permission.name] : [...el.create],
              edit: permission.action === 'Edit' ? [...el.edit, permission.name] : [...el.edit],
              disable:
                permission.action === 'Delete'
                  ? [...el.disable, permission.name]
                  : [...el.disable],
            };
          });
        }
      });

      formattedData.push({
        id: el.id,
        role_title: el.name,
        entities: el.entities[0]?.name !== undefined ? '_' + el.entities[0]?.name : '',
        applications: applications,
      });
    });

    return formattedData;
  };

  const [searchItem, setSearchItem] = useState('');

  useEffect(() => {
    let unmounted = false;

    const roles = api.getRoles(FIRST_PAGE, searchItem, filterStatus.value);
    roles.then((response) => {
      if (!unmounted && response) {
        const applications = api.getApplications();
        applications.then((response) => {
          if (!unmounted && response) {
            setApplicationsData(response.data);
          }
        });
        if (response) {
          dispatch(getRolesData(response));
          dispatch(getRolesList(getFormattedRolesData(response.data.data)));
        }
      }
    });

    return () => {
      unmounted = true;
    };
  }, [api, dispatch, searchItem, filterStatus, countItemsPerPage]);

  const [applicationsData, setApplicationsData] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = (id, modalName) => {
    setModalName(modalName);
    setCurrentRoleId(id);
    setShow(true);
  };

  const handleDeleteRole = (id) => {
    const deleteRole = api.deleteRole(id);
    deleteRole.then((response) => {
      if (response?.status === 200) {
        const users = api.getRoles(FIRST_PAGE, searchItem, filterStatus.value);
        users.then((response) => {
          if (response) {
            dispatch(getRolesData(response));
            dispatch(getRolesList(getFormattedRolesData(response.data.data)));
          }
        });
      }
    });
  };

  const handleRestoreRole = (id) => {
    const restoreRole = api.restoreRole(id);
    restoreRole.then((response) => {
      if (response?.status === 200) {
        const roles = api.getRoles(FIRST_PAGE, searchItem, filterStatus.value);
        roles.then((response) => {
          if (response) {
            dispatch(getRolesData(response));
            dispatch(getRolesList(getFormattedRolesData(response.data.data)));
          }
        });
      }
    });
  };

  const handlePageChange = (page, searchItem) => {
    const roles = api.getRoles(page, searchItem, filterStatus.value);
    roles.then((response) => {
      if (response) {
        dispatch(getRolesData(response));
        dispatch(getRolesList(getFormattedRolesData(response.data.data)));
      }
    });
  };

  const handleChangeItemsPerPage = (value) => {
    const updatedUserData = { ...userData };
    updatedUserData.user_settings.items_per_page = value;
    setUserDataToLocalStorage(updatedUserData);
    dispatch(setUserData(updatedUserData));
  };

  const handleSendItemsPerPage = async (e) => {
    if (itemsPerPage !== '' && e.key === 'Enter') {
      await api.patchItemsPerPage({ 'items_per_page': +itemsPerPage });
      setCountItemsPerPage(itemsPerPage);
      await api.userInfo();
    }
  };

  const handleChangeNameSearch = (value) => {
    setSearchItem(value);
  };

  const handleSelectedFilterStatus = (name, value) => {
    dispatch(setFilterRoleStatus(value));
  };

  return (
    <BackgroundStyled display={'block'}>
      <Header />
      {modalName === 'disable' && (
        <Modals
          onHandleClose={handleClose}
          show={show}
          onHandleSuccess={() => handleDeleteRole([currentRoleId])}
          primaryButtonName={'Disable'}
          secondaryButtonName={'Close'}
          message={'Are you sure you want to disable this role?'}
        />
      )}
      {modalName === 'restore' && (
        <Modals
          onHandleClose={handleClose}
          show={show}
          onHandleSuccess={() => handleRestoreRole(currentRoleId)}
          primaryButtonName={'Restore'}
          secondaryButtonName={'Close'}
          message={'Are you sure you want to restore this role?'}
        />
      )}
      <GroupRowTable
        tableColumns={getColumnsForRoleManagement(handleShow, filterStatus, applicationsData)}
        tableData={rolesList}
        currentPage={currentPage}
        lastPage={lastPage}
        firstPage={firstPage}
        nextPage={nextPage}
        prevPage={prevPage}
        totalCount={totalCount}
        itemsPerPage={`${itemsPerPage}`}
        defaultItemsPerPage={defaultItemsPerPage}
        onHandlePageChange={handlePageChange}
        onSendItemsPerPage={handleSendItemsPerPage}
        header={
          <RoleManagementHeader
            onHandleChangeNameSearch={handleChangeNameSearch}
            theme={theme}
            filterStatus={filterStatus}
            onHandleSelectedFilterStatus={handleSelectedFilterStatus}
          />
        }
        onHandleChangeItemsPerPage={handleChangeItemsPerPage}
        searchItem={searchItem}
      />
    </BackgroundStyled>
  );
};
