import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const ErrorMessage = styled.div`
  color: ${({ theme }) => theme.errorMessageValidation};
  position: absolute;
  text-align: left;
  font-size: 12px;
  width: 200px;
`;
