import { TableNav, TableNavLeftPart, TableNavRightPart } from '../StyledComponents';
import PropTypes from 'prop-types';
import { InputContainer, Wrapper } from '../../StyledComponents';
import { Link } from 'react-router-dom';
import { ButtonPrimary } from '../../../ButtonPrimary/ButtonPrimary';
import { InputStyled } from '../../../InputStyled/InputStyled';
import { SearchStyled } from '../../../../utils/iconsStyled';
import { SelectStyled } from '../../../SelectStyled/SelectStyled';
import _ from 'lodash';

export const PermissionsHeader = (props) => {
  const { onHandleChangeNameSearch, theme, filterStatus, onHandleSelectedFilterStatus } = props;

  const delayedQuery = _.debounce((q) => onHandleChangeNameSearch(q), 1000);
  const onSearchQueryChange = (e) =>
    e.target.value.length > 3 ? delayedQuery(e.target.value) : undefined;
  const onKeyDownSearchQuery = (e) =>
    e.target.value.length < 4 && e.key === 'Enter'
      ? onHandleChangeNameSearch(e.target.value)
      : undefined;

  const dataPermissionsStatus = [
    { value: 'active', label: 'Active' },
    { value: 'disabled', label: 'Disabled' },
  ];
  return (
    <TableNav>
      <TableNavLeftPart>
        <Link to={'/permissions/addPermission'}>
          <ButtonPrimary buttonName='Add Permission' />
        </Link>
      </TableNavLeftPart>
      <TableNavRightPart>
        <Wrapper>
          <SelectStyled
            id={'roleStatus'}
            name={'roleStatus'}
            maxMenuHeight={150}
            options={dataPermissionsStatus}
            width={'130px'}
            theme={theme}
            value={filterStatus}
            onChange={onHandleSelectedFilterStatus}
            isDot={false}
          />
        </Wrapper>
        <InputContainer>
          <InputStyled
            paddingLeft={'28px'}
            width={'160px'}
            placeholder={'Search...'}
            onChange={onSearchQueryChange}
            onKeyDown={onKeyDownSearchQuery}
          />
          <SearchStyled size={20} position={'absolute'} left={'6px'} top={'5px'} />
        </InputContainer>
      </TableNavRightPart>
    </TableNav>
  );
};

PermissionsHeader.propTypes = {
  onHandleChangeNameSearch: PropTypes.func,
  theme: PropTypes.object,
  filterStatus: PropTypes.object,
  onHandleSelectedFilterStatus: PropTypes.func,
};
