export const SET_USER_DATA = 'SET_USER_DATA';
export const SHOW_TOAST = 'SHOW_TOAST';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const IS_THEME = 'IS_THEME';
export const GET_USERS_LIST = 'GET_USERS_LIST';
export const SET_USER_FILTER_STATUS = 'SET_USER_FILTER_STATUS';
export const GET_ROLES_DATA = 'GET_ROLES_DATA';
export const GET_ROLES_LIST = 'GET_ROLES_LIST';
export const SET_SELECTED_USERS = 'SET_SELECTED_USERS';
export const SET_FILTER_ROLE_STATUS = 'SET_FILTER_ROLE_STATUS';
export const GET_PERMISSIONS_LIST = 'GET_PERMISSIONS_LIST';
export const GET_PERMISSIONS_SETTINGS = 'GET_PERMISSIONS_SETTINGS';
export const SET_FILTER_PERMISSIONS_STATUS = 'SET_FILTER_PERMISSIONS_STATUS';
export const CLEAR_STORE = 'CLEAR_STORE';
export const GET_ORGANIZATIONS_LIST = 'GET_ORGANIZATIONS_LIST';
export const SET_USER_PERMISSIONS = 'SET_USER_PERMISSIONS';
export const SET_ITEMS_PER_PAGE_PERMISSIONS = 'SET_ITEMS_PER_PAGE_PERMISSIONS';
export const SET_ENTITY_AND_ROLE = 'SET_ENTITY_AND_ROLE';



