import styled from 'styled-components';
import { SIZE } from '../../constants/size';

export const ContainerStyled = styled.div`
  max-width: 580px;
`;

export const CardStyledLeftBlock = styled.div`
  width: 254px;
  text-align: center;
  margin: auto;
`;

export const CardStyledRightBlock = styled.div`
  width: 254px;
`;

export const Wrapper = styled.div`
  padding: 0 20px 13px
`;

export const CardStyledContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${(props) => props.flexDirection || 'row'};
  justify-content: ${(props) => props.justifyContent || 'space-around'};
  padding-top: ${(props) => props.paddingTop || '0px'};
  padding-bottom: ${(props) => props.paddingBottom || '20px'};
  padding-left: ${(props) => props.paddingLeft || '20px'};
  padding-right: ${(props) => props.paddingRight || '20px'};
  align-items: ${(props) => props.alignItems || 'space-around'};
`;

export const TitleStyled = styled.h1`
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1.5px;
  text-transform: capitalize;
  color: ${({ theme }) => theme.AppCardTitleColor};
  margin: ${(props) => props.margin || '0 10px'};
`;

export const DescriptionCardStyled = styled.h1`
  font-weight: 500;
  font-size: 12px;
  color: ${({ theme }) => theme.AppCardDescriptionColor};
  padding: 20px 15px;
  line-height: 20px;
  width: 100%;
`;

export const AppsWrapperStyled = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 0;
  padding-bottom: 50px;
  @media (max-width: ${SIZE.tabletS}) {
    justify-content: space-around;
  }
`;

export const AppCardStyled = styled.a`
  width: 286px;
  height: 176px;
  border: ${({ theme }) => `2px solid ${theme.AppCardBorderColor}`};
  border-radius: 2px;
  background-color: ${({ theme }) => theme.AppCardBackgroundColor};
  margin: 2px;
  cursor: pointer;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  text-decoration: none;

  &:hover {
    background-color: ${({ theme }) => theme.AppCardHoverBackgroundColor};
  }
`;


