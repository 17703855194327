import styled from 'styled-components';

export const PaginationContainer = styled.div`
  display: inline-block;
`;

export const PaginationBtn = styled.a`
  color: ${({ theme }) => theme.paginationBtnTextColor};
  border: ${({ theme }) => `1px solid ${theme.paginationBtnBorderColor}`};
  background-color: ${({ theme }) => theme.paginationBtnBackgroundColor};
  float: left;
  padding: 4px 12px;
  text-decoration: none;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  &:hover {
    color: ${({ theme }) => theme.paginationBtnTextHoverColor};
    border: ${({ theme }) => `1px solid ${theme.paginationBtnBorderHoverColor}`};
    background-color: ${({ theme }) => theme.paginationActiveBtnBackgrounColor};
  }
  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

export const PaginationActiveBtn = styled.a`
  float: left;
  color: ${({ theme }) => theme.paginationActiveBtnTextColor};
  border: ${({ theme }) => `1px solid ${theme.paginationActiveBtnBorderColor}`};
  background-color: ${({ theme }) => theme.paginationBtnBackgroundColor};
  padding: 4px 12px;
  text-decoration: none;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  &:hover {
    color: ${({ theme }) => theme.paginationActiveBtnTextColor};
    border: ${({ theme }) => `1px solid ${theme.paginationActiveBtnBorderColor}`};
    background-color: ${({ theme }) => theme.paginationBtnBackgroundColor};
  }
`;

export const PaginationDisabledBtn = styled.a`
  pointer-events: none;
  color: ${({ theme }) => theme.paginationBtnDisabledTextColor};
  border: ${({ theme }) => `1px solid ${theme.paginationBtnDisabledBackgrounColor}`};
  background-color: ${({ theme }) => theme.paginationBtnDisabledBorderColor};
  float: left;
  padding: 4px 12px;
  text-decoration: none;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;
