import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Path = styled(Link)`
  color: ${({ theme }) => theme.linkRouterColor};
  text-decoration: none;
  font-size: ${(props) => props.fontSize || '12px'};
  &:hover {
    color: ${({ theme }) => theme.linkRouterColor};
    text-decoration: underline;
  }
`;

export const LinkRouterStyled = (props) => {
  return <Path {...props} />;
};

LinkRouterStyled.propTypes = {
  fontSize: PropTypes.string,
};
