import { showErrorToast } from '../utils/common';
import { BASE_API_URL, axiosInstance, API_PATHS } from './ApiConfig';

export class ApiPermissions {
  async getPermissions(page, name, filterStatus, per_page) {
    let url = name
      ? `${BASE_API_URL}${API_PATHS.permissions}?page=${page}&state=${filterStatus}&name=${name}&per_page=${per_page}`
      : `${BASE_API_URL}${API_PATHS.permissions}?page=${page}&state=${filterStatus}&per_page=${per_page}`;

    try {
      return await axiosInstance.get(url);
    } catch (error) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('Permission api Error: ' + error.response?.data?.message);
      }

      showErrorToast(error.response?.data?.message || 'Permission error has occurred');
    }
  }

  async deletePermission(id) {
    let url = `${BASE_API_URL}${API_PATHS.permissions}/${id}`;

    try {
      return await axiosInstance.delete(url);
    } catch (error) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('Permission api Error: ' + error.response?.data?.message);
      }

      showErrorToast(error.response?.data?.message || 'Permission error has occurred');
    }
  }

  async restorePermission(id) {
    let url = `${BASE_API_URL}${API_PATHS.permissions}/${id}/restore`;

    try {
      return await axiosInstance.post(url);
    } catch (error) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('Permission api Error: ' + error.response?.data?.message);
      }

      showErrorToast(error.response?.data?.message || 'Permission error has occurred');
    }
  }

  async getPermission(id) {
    let url = `${BASE_API_URL}${API_PATHS.permissions}/${id}`;

    try {
      return await axiosInstance.get(url);
    } catch (error) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('Permission api Error: ' + error.response?.data?.message);
      }

      showErrorToast(error.response?.data?.message || 'Permission error has occurred');
    }
  }

  async createPermission(permission) {
    let url = `${BASE_API_URL}${API_PATHS.permissions}`;

    try {
      return await axiosInstance.post(url, permission);
    } catch (error) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('Permission api Error: ' + error.response?.data?.message);
      }

      showErrorToast(error.response?.data?.message || 'Permission error has occurred');
    }
  }

  async editPermission(id, permission) {
    let url = `${BASE_API_URL}${API_PATHS.permissions}/${id}`;

    try {
      return await axiosInstance.put(url, permission);
    } catch (error) {
      if (process.env.NODE_ENV !== 'production') {
        console.log('Permission api Error: ' + error.response?.data?.message);
      }

      showErrorToast(error.response?.data?.message || 'Permission error has occurred');
    }
  }
}