import React from 'react';
import PropTypes from 'prop-types';
import { ButtonPrimary } from '../ButtonPrimary/ButtonPrimary';
import { ButtonSecondary } from '../ButtonSecondary/ButtonSecondary';
import {
  ModalStyled,
  ModalBodyStyled,
  ModalWapperStyled,
  ModalActionWrapperStyled,
  ModalTextStyled,
  SelectWrapper,
  LabelStyled,
  DescriptionStyled,
} from './StyledComponents';
import { SelectStyled } from '../SelectStyled/SelectStyled';

export const Modals = (props) => {
  const {
    message,
    onHandleClose,
    show,
    onHandleSuccess,
    primaryButtonName,
    secondaryButtonName,
    isSelect,
    theme,
    options,
    defaultSelect,
    onHandleChangeSelected,
    name,
    id,
    selectLabel,
    isDescription,
    descriptionText,
  } =
    props;

  return (
    <div>
      <ModalStyled size='sm' animation={false} show={show} onHide={onHandleClose}>
        <ModalBodyStyled>
          <ModalWapperStyled>
            <ModalTextStyled>{message}</ModalTextStyled>
            {isDescription === true && (
              <DescriptionStyled>{descriptionText}</DescriptionStyled>
            )}
            {isSelect === true && (
              <SelectWrapper>
                <LabelStyled>{selectLabel}</LabelStyled>
                <SelectStyled
                  id={id}
                  name={name}
                  maxMenuHeight={150}
                  options={options}
                  width={'100%'}
                  theme={theme}
                  value={defaultSelect}
                  onChange={onHandleChangeSelected}
                  isDot={false}
                />
              </SelectWrapper>
            )}
            <ModalActionWrapperStyled>
              {primaryButtonName !== '' && (
                <ButtonPrimary
                  buttonName={primaryButtonName}
                  onClick={() => {
                    onHandleSuccess();
                    onHandleClose();
                  }}
                />
              )}
              {secondaryButtonName !== '' && (
                <ButtonSecondary buttonName={secondaryButtonName} onClick={onHandleClose} />
              )}
            </ModalActionWrapperStyled>
          </ModalWapperStyled>
        </ModalBodyStyled>
      </ModalStyled>
    </div>
  );
};

Modals.propTypes = {
  message: PropTypes.string,
  onHandleClose: PropTypes.func,
  show: PropTypes.bool,
  onHandleSuccess: PropTypes.func,
  primaryButtonName: PropTypes.string,
  secondaryButtonName: PropTypes.string,
  isSelect: PropTypes.bool,
  theme: PropTypes.object,
  options: PropTypes.array,
  defaultSelect: PropTypes.object,
  onHandleChangeSelected: PropTypes.func,
};
