import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { Formik, Field, Form } from 'formik';
import { Link, useHistory } from 'react-router-dom';
import _ from 'lodash';
import { Header } from '../../../../components/Header/Header';
import { BackgroundStyled } from '../../../../components/BackgroundStyled/BackgroundStyled';
import { LabelStyled } from '../../../../components/LabelStyled/LabelStyled';
import { addUserValidation } from '../../../../utils/validationSchema';
import { InputValidation } from '../../../../components/InputValidation/InputValidation';
import { GroupStyled } from '../../../../components/GroupStyled/GroupStyled';
import { ButtonPrimary } from '../../../../components/ButtonPrimary/ButtonPrimary';
import { ButtonSecondary } from '../../../../components/ButtonSecondary/ButtonSecondary';
import { Api } from '../../../../api/Api';
import {
  CardStyled,
  Container,
  PageTitleStyled,
  CardTitleStyled,
  FlexStyled, WrapperStyled, MenuNavigationStyled, BtnWrapperStyled,
} from '../../../../components/StyledComponents/StyledComponents';
import { CheckBoxList } from '../../../../components/CheckBoxList/CheckBoxList';
import { RadioButtonList } from '../../../../components/RadioButtonList/RadioButtonList';
import { ChipStyled } from '../../../../components/ChipStyled/ChipStyled';
import { ROUTES } from '../../../../constants/routes';
import { ScrollStyled } from '../../../../components/ScrollStyled/ScrollStyled';
import { useSelector } from 'react-redux';
import { UploadWrapper } from '../../../../components/LogoUpload/UploadWrapper';
import { PlusCircleStyled } from '../../../../utils/iconsStyled';

const SIDE_MENU = [
  {
    label: 'Personal Information',
    activeClass: 'active',
    to: 'personalInformation',
    spy: true,
    smooth: false,
    offset: -100,
    duration: 500,
  },
  {
    label: 'Contact Information',
    activeClass: 'active',
    to: 'contactInformation',
    spy: true,
    smooth: false,
    offset: -100,
    duration: 500,
  },
  {
    label: 'Access ',
    activeClass: 'active',
    to: 'access',
    spy: true,
    smooth: false,
    offset: -100,
    duration: 500,
  },
];

export const AddUser = () => {
    const api = useMemo(() => new Api(), []);
    const history = useHistory();
    const FIRST_PAGE = 1;

    const routerPush = () => {
      history.push(ROUTES.userManagement);
    };

    const adminOrganizationType = useSelector((state) => state.login.userData.is_organization_admin);
    const adminType = useSelector((state) => state.login.userData.is_admin);
    const userOrganization = useSelector((state) => state.login.userData.admin_of_entity_id);
    const loginUserId = useSelector((state) => state.login.userData.id);

    const [userData, setUserData] = useState({
      name: '',
      email: '',
      first_name: '',
      last_name: '',
      phone_number: '',
      enabled: 0,
      is_admin: 0,
      is_tester: 0,
      is_organization_admin: 0,
    });

    const handleCheckboxChange = (e, id) => {
      let checkBoxDataCopy = [...rolesCheckedList];
      checkBoxDataCopy.forEach((item) => {
        item.list.forEach(e => {
          if (e.id === id) {
            e.isChecked = !e.isChecked;
          }
        });

      });
      setRolesCheckedList(checkBoxDataCopy);
    };

    const userTypeAdmin = [
      { label: 'Default', id: 'default', value: 'default' },
      { label: 'Admin - manages  all organizations', id: 'is_admin', value: 'is_admin' },
      {
        label: 'Organization Admin - administrates one organization but can also be a member  of other  organizations',
        id: 'is_organization_admin',
        value: 'is_organization_admin',
      },
      { label: 'Tester - belongs to a default demo organization', id: 'is_tester', value: 'is_tester' },
    ];

    const [checkedUserTypeValue, setCheckedUserTypeValue] = useState('default');

    const handleChangeUserType = (e) => {
      if (e.target.id === 'is_admin') {
        setUserData((prevState) => ({
          ...userData,
          is_admin: 1,
          is_tester: 0,
          is_organization_admin: 0,
        }));
        setChipsData({
          userOrganizations: [],
          suggestions: [],
          adminOrganization: [],
        });
        setCheckedUserTypeValue(e.target.value);
      } else if (e.target.id === 'is_tester') {
        setCheckedUserTypeValue(e.target.value);
        setUserData((prevState) => ({
          ...userData,
          is_admin: 0,
          is_tester: 1,
          is_organization_admin: 0,
        }));
        setChipsData({
          userOrganizations: [],
          suggestions: [],
          adminOrganization: [],
        });
      } else if (e.target.id === 'is_organization_admin') {
        setCheckedUserTypeValue(e.target.value);
        setUserData((prevState) => ({
          ...userData,
          is_admin: 0,
          is_tester: 0,
          is_organization_admin: 1,
        }));
        setChipsData({
          userOrganizations: [],
          suggestions: [],
          adminOrganization: [],
        });
      } else if (e.target.id === 'default') {
        setCheckedUserTypeValue(e.target.value);
        setUserData((prevState) => ({
          ...userData,
          is_admin: 0,
          is_tester: 0,
          is_organization_admin: 0,
        }));
        setChipsData({
          userOrganizations: [],
          suggestions: [],
          adminOrganization: [],
        });
      }
    };

    const [chipsData, setChipsData] = useState({
      userOrganizations: userOrganization === null ? [] : [userOrganization],
      adminOrganization: [],
    });

    const [organizationsListData, setOrganizationsListData] = useState(
      {
        suggestions: [],
        data: [],
      });

    const handleDeleteChip = (i, whom) => {
      const previousChipsList = [...chipsData[whom]];
      const newChipsList = previousChipsList.slice(0);
      newChipsList.splice(i, 1);
      setChipsData(prevState => ({
        ...prevState,
        [whom]: newChipsList,
      }));
    };

    const handleAddChip = (el, whom) => {
      const previousChipsList = [...chipsData[whom]];
      const newChipsList = [].concat(previousChipsList, el);
      const uniqueChipsList = [
        ...new Map(newChipsList.map((item) => [item['id'], item])).values(),
      ];
      setChipsData(prevState => ({
        ...prevState,
        [whom]: uniqueChipsList,
      }));
    };

    const handleFormattedSuggestions = (data) => {
      const suggestions = [];
      data.forEach(el => {
        suggestions.push({ id: el.id, name: el.name });
      });
      return suggestions;
    };

    const createFormattedRolesList = (arr) => {
      const roleList = [];
      arr.forEach(el => roleList.push({ id: el.id, isChecked: false, name: el.name }));
      return roleList;
    };

    const createOrganizationRoles = useCallback((arr, data) => {
      const checkBoxList = [];
      arr.forEach(el => checkBoxList.push(...data.filter(e => e.id === el.id)));
      return checkBoxList;
    }, []);

    const createCheckBoxList = useCallback((arr, previousArr) => {
      const checkBoxList = [];
      const uniqueCheckBoxList = [];
      arr.forEach(el => checkBoxList.push(
        {
          id: el.id,
          name: el.name,
          list: createFormattedRolesList(el.roles),
        }));
      checkBoxList.forEach(el => uniqueCheckBoxList.push(...previousArr.filter(e => e.id !== el.id)));
      return checkBoxList.filter(item => previousArr.every(el => el.id !== item.id));
    }, []);

    const createCheckBoxListForOrganizationAdmin = useCallback((arr) => {
      const checkBoxList = [];

      arr.forEach(el => checkBoxList.push(
        {
          id: el.id,
          name: el.name,
          list: createFormattedRolesList(el.roles),
        }));
      return checkBoxList;
    }, []);

    const [rolesCheckedList, setRolesCheckedList] = useState([]);

    useEffect(() => {
      if (userOrganization === null && adminType) {
        const roleList = createOrganizationRoles(chipsData.userOrganizations, organizationsListData.data);
        setRolesCheckedList(roleList);
      }

      if (userOrganization !== null) {
        (async () => {
          const organizationData = await api.getEntityById(userOrganization);
          console.log('organizationData: ', organizationData);
          const roleList = createCheckBoxListForOrganizationAdmin([organizationData.data]);
          setRolesCheckedList(roleList);
        })();
      }
    }, [adminType, createCheckBoxListForOrganizationAdmin, loginUserId, userOrganization, api, createOrganizationRoles, chipsData.userOrganizations, organizationsListData]);

    const getSelectedId = (data) => {
      const idsArr = [];
      if (data) {
        data.forEach((el) => {
          el.list.forEach(e => {
            if (e.isChecked === true) {
              idsArr.push(e.id);
            }
          });
        });
      }
      return idsArr;
    };

    const handleSearchOrganization = async (el) => {
      const organizationsData = await api.getEntitiesPaginated(FIRST_PAGE, el);

      if (organizationsData) {
        const previousRolesList = [...rolesCheckedList];
        const data = previousRolesList.concat(createCheckBoxList(organizationsData.data.data, previousRolesList));
        const uniqueDataList = [...new Map(data.map((item) => [item['id'], item])).values()];
        setOrganizationsListData({
          suggestions: handleFormattedSuggestions(organizationsData.data.data),
          data: uniqueDataList,
        });
      }
    };

    const selectedOrganizationIds = (data) => {
      const organizationIds = [];
      data.forEach(el => {
        organizationIds.push(el.id);
      });
      return organizationIds;
    };

    const delayedQuery = _.debounce((q) => handleSearchOrganization(q), 1000);

    const [imageBlob, setImageBlob] = useState(null);

    const handleUserLogo = (imageBlob) => {
      setImageBlob(imageBlob);
    };

    const handleChangeInput = (e) => {
      setUserData((prev) => ({
        ...prev,
        [e.target.id]: e.target.value,
      }));
    };

    return (
      <BackgroundStyled display={'block'}>
        <Header />
        <Container>
          <FlexStyled
            direction={'row'}
            align={'flex-start'}
            justify={'center'}
            wrap={'wrap'}
          >
            <MenuNavigationStyled>
              <PageTitleStyled padding={'20px 20px 0'}>Add User</PageTitleStyled>
              <ul>
                {SIDE_MENU.map(item =>
                  <li key={item.label}>
                    <ScrollStyled
                      activeClass={item.activeClass}
                      to={item.to}
                      spy={item.spy}
                      smooth={item.smooth}
                      offset={item.offset}
                      duration={item.duration}
                    >{item.label}
                    </ScrollStyled>
                  </li>)}
              </ul>
            </MenuNavigationStyled>
            <WrapperStyled width={'580px'}>
              <Formik
                validationSchema={addUserValidation}
                validateOnChange={false}
                validateOnBlur={false}
                enableReinitialize={true}
                initialValues={{
                  ...userData,
                  name: userData.first_name,
                  email: userData.email,
                  first_name: userData.first_name,
                  last_name: userData.last_name,
                  organization: userData.organization,
                  department: userData.department,
                  phone_number: userData.phone_number,
                  entity_ids: userOrganization === null && adminType ? selectedOrganizationIds(chipsData.userOrganizations) : [userOrganization],
                  admin_of_entity_id: chipsData.adminOrganization[0]?.id ? +chipsData.adminOrganization[0]?.id : null,
                  roles_id: getSelectedId(rolesCheckedList),
                  enabled: userData.enabled,
                  is_admin: userData.is_admin,
                  is_tester: userData.is_tester,
                  is_organization_admin: userData.is_organization_admin,
                  user_settings: {
                    theme: 'theme-dark',
                  },
                }}
                onSubmit={async (values, { resetForm }) => {
                  const user = {
                    ...userData,
                    name: values.name,
                    email: values.email,
                    first_name: values.first_name,
                    last_name: values.last_name,
                    organization: values.organization,
                    department: values.department,
                    phone_number: values.phone_number,
                    roles_id: values.roles_id,
                    entity_ids: values.entity_ids,
                    enabled: values.enabled,
                    is_admin: values.is_admin,
                    is_tester: values.is_tester,
                    is_organization_admin: values.is_organization_admin,
                    admin_of_entity_id: values.admin_of_entity_id,
                    user_settings: {
                      theme: values.user_settings.theme,
                    },
                  };
                  const creatUser = await api.createUser(user);
                  if (creatUser.status === 200 && imageBlob !== null) {
                    await api.updateUserLogo(imageBlob, creatUser.data.id);
                    resetForm();
                    routerPush();
                  } else if (creatUser.status === 200 && imageBlob === null) {
                    resetForm();
                    routerPush();
                  }
                }}
              >
                {({ handleSubmit, handleChange, setFieldValue, values }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    {adminType || adminOrganizationType ? (
                      <>
                        <FlexStyled
                          direction={'column'}
                          align={'stretch'}
                          wrap={'wrap'}
                        >
                          <CardStyled marginBottom={'15px'}>
                            <CardTitleStyled id='personalInformation'>PERSONAL INFORMATION</CardTitleStyled>
                            <FlexStyled
                              direction={'row'}
                              justify={'space-between'}
                              wrap={'nowrap'}
                            >
                              <FlexStyled
                                paddingLeft={'20px'}
                                paddingRight={'20px'}
                                bottom={'20px'}
                                direction={'column'}
                                justify={'space-between'}
                              >
                                <GroupStyled top={'13px'} bottom={'13px'}>
                                  <LabelStyled bottom={'8px'}>First Name</LabelStyled>
                                  <Field
                                    id={'first_name'}
                                    component={InputValidation}
                                    type='text'
                                    width={'256px'}
                                    name={'first_name'}
                                    placeholder={'First Name'}
                                    value={userData?.first_name}
                                    onChange={handleChangeInput}
                                  />
                                </GroupStyled>
                                <GroupStyled top={'13px'} bottom={'13px'}>
                                  <LabelStyled bottom={'8px'}>Last Name</LabelStyled>
                                  <Field
                                    id={'last_name'}
                                    component={InputValidation}
                                    type='text'
                                    width={'256px'}
                                    name={'last_name'}
                                    placeholder={'Last Name'}
                                    value={userData?.last_name}
                                    onChange={handleChangeInput}
                                  />
                                </GroupStyled>
                              </FlexStyled>
                              <div style={{ margin: 'auto' }}>
                                <UploadWrapper
                                  originalImageSrc={userData.image_url}
                                  btnName={'Add'}
                                  onHandleImage={handleUserLogo}
                                >
                                  <PlusCircleStyled size={'120px'} />
                                </UploadWrapper>
                              </div>
                            </FlexStyled>
                          </CardStyled>
                          <CardStyled marginBottom={'15px'}>
                            <CardTitleStyled id='contactInformation'>CONTACT INFORMATION</CardTitleStyled>
                            <FlexStyled
                              paddingLeft={'20px'}
                              paddingRight={'20px'}
                              bottom={'20px'}
                              direction={'column'}
                              justify={'start'}
                              wrap={'wrap'}
                              align={'top'}
                            >
                              <GroupStyled top={'13px'} bottom={'13px'}>
                                <LabelStyled bottom={'8px'}>Email</LabelStyled>
                                <Field
                                  id={'email'}
                                  component={InputValidation}
                                  type='email'
                                  width={'256px'}
                                  name={'email'}
                                  placeholder={'email'}
                                  value={userData?.email}
                                  onChange={handleChangeInput}
                                />
                              </GroupStyled>
                              <GroupStyled top={'13px'} bottom={'13px'}>
                                <LabelStyled bottom={'8px'}>Phone</LabelStyled>
                                <Field
                                  id={'phone_number'}
                                  component={InputValidation}
                                  type='tel'
                                  width={'256px'}
                                  name={'phone_number'}
                                  placeholder={'Phone'}
                                  value={userData?.phone_number}
                                  onChange={handleChangeInput}
                                />
                              </GroupStyled>
                            </FlexStyled>
                          </CardStyled>
                          <CardStyled marginBottom={'15px'}>
                            <CardTitleStyled id='access'>ACCESS</CardTitleStyled>
                            {adminType && (
                              <FlexStyled
                                paddingLeft={'20px'}
                                paddingRight={'20px'}
                                bottom={'0'}
                                direction={'column'}
                                justify={'start'}
                                wrap={'wrap'}
                                align={'top'}
                              >
                                <GroupStyled top={'13px'} bottom={'25px'}>
                                  <LabelStyled bottom={'8px'}>User Type</LabelStyled>
                                  <RadioButtonList
                                    name={'userTypeData'}
                                    radioButtonSettings={userTypeAdmin}
                                    checkedValue={checkedUserTypeValue}
                                    onChangeValue={handleChangeUserType}
                                  />
                                </GroupStyled>
                                {userOrganization === null && adminType && (
                                  <>
                                    {!userData.is_admin && !userData.is_organization_admin && (
                                      <GroupStyled top={'13px'} bottom={'10px'}>
                                        <LabelStyled bottom={'8px'}>Add User Organizations</LabelStyled>
                                        <ChipStyled
                                          tags={chipsData.userOrganizations}
                                          suggestions={organizationsListData.suggestions}
                                          onDelete={el => handleDeleteChip(el, 'userOrganizations')}
                                          onAddition={el => handleAddChip(el, 'userOrganizations')}
                                          onInput={delayedQuery}
                                          placeholderText={'search...'}
                                          width={'256px'}
                                        />
                                      </GroupStyled>
                                    )}
                                    {Boolean(userData.is_organization_admin) && (
                                      <>
                                        <GroupStyled top={'13px'} bottom={'10px'}>
                                          <LabelStyled bottom={'8px'}>Admin of organization</LabelStyled>
                                          <ChipStyled
                                            tags={chipsData.adminOrganization}
                                            suggestions={organizationsListData.suggestions}
                                            onDelete={el => handleDeleteChip(el, 'adminOrganization')}
                                            onAddition={el => handleAddChip(el, 'adminOrganization')}
                                            onInput={delayedQuery}
                                            placeholderText={'search...'}
                                            display={chipsData.adminOrganization.length > 0 ? 'none' : 'block'}
                                            width={'256px'}
                                          />
                                        </GroupStyled>
                                        <GroupStyled top={'13px'} bottom={'0'}>
                                          <LabelStyled bottom={'8px'}>Add User Organizations</LabelStyled>
                                          <ChipStyled
                                            tags={chipsData.userOrganizations}
                                            suggestions={organizationsListData.suggestions}
                                            onDelete={el => handleDeleteChip(el, 'userOrganizations')}
                                            onAddition={el => handleAddChip(el, 'userOrganizations')}
                                            onInput={delayedQuery}
                                            placeholderText={'search...'}
                                            width={'256px'}
                                          />
                                        </GroupStyled>
                                      </>
                                    )}
                                  </>
                                )}
                              </FlexStyled>
                            )}
                            <FlexStyled
                              paddingLeft={'20px'}
                              paddingRight={'20px'}
                              bottom={'0'}
                              direction={'row'}
                              justify={'start'}
                              wrap={'wrap'}
                              align={'top'}
                            >
                              <GroupStyled top={'10px'} bottom={'10px'}>
                                {rolesCheckedList.length !== 0 && rolesCheckedList.map(el => {
                                    return (
                                      <div key={el.id}>
                                        <LabelStyled bottom={'8px'}>{el.name} Organization Roles</LabelStyled>
                                        <CheckBoxList
                                          data={el.list}
                                          onCheckboxChange={handleCheckboxChange}
                                        />
                                      </div>
                                    );
                                  },
                                )}
                              </GroupStyled>
                            </FlexStyled>
                          </CardStyled>
                        </FlexStyled>
                        <BtnWrapperStyled>
                          <FlexStyled direction={'row'} justify={'flex-end'} top={'10px'}>
                            <ButtonPrimary type='submit' buttonName='Create' />
                            <Link to={ROUTES.userManagement}>
                              <ButtonSecondary buttonName='Cancel' />
                            </Link>
                          </FlexStyled>
                        </BtnWrapperStyled>
                      </>
                    ) : <h1>You should have user type Admin or Organization Admin</h1>}
                  </Form>
                )}
              </Formik>
            </WrapperStyled>
          </FlexStyled>
        </Container>
      </BackgroundStyled>
    );
  }
;
