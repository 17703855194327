import React, { useEffect, useMemo } from 'react';
import { Field, Form, Formik } from 'formik';
import { qrCodeValidation } from '../../utils/validationSchema';
import { FlexStyled } from '../../components/FlexStyled/FlexStyled';
import { InputValidation } from '../../components/InputValidation/InputValidation';
import { ButtonPrimary } from '../../components/ButtonPrimary/ButtonPrimary';
import { BackgroundStyled } from '../../components/BackgroundStyled/BackgroundStyled';
import { TwoFactor } from '../../api/TwoFactor';
import { useDispatch, useSelector } from 'react-redux';
import { setUserData } from '../../redux/actions/login';
import { hideLoader, showLoader } from '../../utils/common';
import {
  FormDescriptionStyled, FormHeaderStyled,
  FormInputWrapperStyled, FormStyled,
  FormTitleTextStyled,
} from '../../components/FormStyled/FormStyled';

export const TwoFactorAuthPage = () => {
  const dispatch = useDispatch();
  const twoFactor = useMemo(() => new TwoFactor(), []);

  const userData = useSelector((state) => state.login.userData);

  useEffect(() => {
    hideLoader();
  }, []);

  return (
    <BackgroundStyled justify='center'>
      <FormStyled>
        <FormHeaderStyled>
          <FlexStyled align={'start'}>
            <FormTitleTextStyled>Two-factor authentication</FormTitleTextStyled>
          </FlexStyled>
        </FormHeaderStyled>
        <Formik
          validationSchema={qrCodeValidation}
          validateOnChange={false}
          validateOnBlur={false}
          enableReinitialize={false}
          initialValues={{
            code: '',
          }}
          onSubmit={(values, { resetForm }) => {
            const verifyCode = values.code;
            (async () => {
              showLoader();
              const [twoFactorAuthentication] = await Promise.all([
                await twoFactor.authenticate(verifyCode),
              ]);

              if (twoFactorAuthentication) {
                const userDataCopy = { ...userData };
                userDataCopy.sso_session.two_fa_authenticated = true;
                dispatch(setUserData({
                  userDataCopy,
                }));
                resetForm();
              } else {
                hideLoader();
              }
            })();
          }}
        >
          {({ handleSubmit, handleChange }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <FormDescriptionStyled margin={'50px 10px 25px'}>Please enter the one-time password generated by your
                Authenticator mobile application.</FormDescriptionStyled>
              <FormInputWrapperStyled margin={'0 auto'} width={'170px'} mediaWidth={'170px'} padding={'10px 0 25px'}>
                <Field
                  id={'code'}
                  component={InputValidation}
                  type='text'
                  width={'170px'}
                  name={'code'}
                  placeholder={'Enter the 6-digit code'}
                  onChange={handleChange}
                />
              </FormInputWrapperStyled>
              <FlexStyled bottom={'15px'} direction={'row'}>
                <ButtonPrimary type='submit' buttonName='Confirm' />
              </FlexStyled>
            </Form>
          )}
        </Formik>
      </FormStyled>
    </BackgroundStyled>
  );
};