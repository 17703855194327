import * as type from '../types';

export const getUsersList = (data) => {
  return {
    type: type.GET_USERS_LIST,
    payload: data,
  };
};

export const setFilterStatus = (value) => {
  return {
    type: type.SET_USER_FILTER_STATUS,
    payload: value,
  };
};

export const setSelectedUsers = (value) => {
  return {
    type: type.SET_SELECTED_USERS,
    payload: value,
  };
};
