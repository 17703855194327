import React from 'react';
import PropTypes from 'prop-types';
import { InputStyled } from '../InputStyled/InputStyled';
import { InputContainer, IconButton, Container, ErrorMessage } from './StyledComponents';
import { HideIconStyled, EyeIconStyled } from '../../utils/iconsStyled';

export const MaskInput = ({
                            field,
                            form: { errors, touched },
                            width,
                            placeholder,
                            onChange,
                            name,
                            id,
                          }) => {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Container>
      <InputContainer>
        <InputStyled
          {...field}
          width={width}
          placeholder={placeholder}
          onChange={onChange}
          value={field.value === undefined ? '' : field.value}
          name={name}
          id={id}
          type={showPassword ? 'text' : 'password'}
        />
        <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
          {showPassword ? <EyeIconStyled size={16} /> : <HideIconStyled size={16} />}
        </IconButton>
      </InputContainer>
      {errors[field.name] && touched[field.name] ? (
        <div>
          <ErrorMessage>{errors[field.name]}</ErrorMessage>
        </div>
      ) : null}
    </Container>
  );
};

MaskInput.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  width: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  id: PropTypes.string,
};
