import React from 'react';
import PropTypes from 'prop-types';
import { SelectWrapper } from './StyledComponents';

export const SelectStyled = (props) => {
  const { theme, maxMenuHeight, options, onOptionSelect, isDot, width, name } = props;

  const handleSelectOption = (value) => {
    props.onChange(name, value);
  };

  const dot = (color = `${theme.selectDotColor}`) => ({
    alignItems: 'center',
    display: 'flex',
    ':before': {
      backgroundColor: color,
      borderRadius: 10,
      content: '" "',
      display: 'block',
      marginRight: 8,
      height: 8,
      width: 8,
    },
  });

  const colourStyles = {
    menu: (styles) => {
      return {
        ...styles,
        zIndex: 1000,
        backgroundColor: `${theme.selectMenuBackgroundColor}`,
        border: `1px solid ${theme.selectMenuBorderColor}`,
      };
    },
    control: (styles) => ({
      ...styles,
      backgroundColor: `${theme.selectControlBackgroundColor}`,
      border: 0,
      borderRadius: 4,
      width: `${width}`,
      minHeight: '26px',
      height: '26px',
      cursor: 'pointer',
      transitionDuration: '0.3s',
    }),
    indicatorSeparator: () => {}, // removes the "stick"
    dropdownIndicator: (defaultStyles) => ({
      ...defaultStyles,
      padding: 0,
      color: `${theme.selectDropdownIndicator}`, // your changes to the arrow
    }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected
          ? `${theme.selectOptionBackgroundIsSelectedColor}`
          : isFocused
          ? `${theme.selectOptionBackgroundIsFocusedColor}`
          : `${theme.selectOptionBackgroundColor}`,
        color: `${theme.selectOptionColor}`,
        cursor: isDisabled ? 'not-allowed' : 'pointer',
        ':active': {
          ...styles[':active'],
          backgroundColor: `${theme.selectOptionBackgroundActiveColor}`,
          color: `${theme.selectOptionBackgroundActiveColor}`,
        },
        ':hover': {
          backgroundColor: `${theme.selectOptionBackgroundHoverColor}`,
          color: `${theme.selectOptionHoverColor}`,
        },
      };
    },
    input: (styles) => (isDot ? { ...styles, padding: 0, ...dot() } : { ...styles, padding: 0 }),
    placeholder: (styles) => (isDot ? { ...styles, ...dot() } : { ...styles }),
    singleValue: isDot
      ? (styles, { data }) => ({
          ...styles,
          color: `${theme.selectSingleValueColor}`,
          ...dot(data.color),
        })
      : (styles) => ({
          ...styles,
          color: `${theme.selectSingleValueColor}`,
        }),
  };

  // onHandleSelectedfilterStatus('onHandleSelectedfilterStatus');
  return (
    <SelectWrapper
      maxMenuHeight={maxMenuHeight}
      options={options}
      styles={colourStyles}
      onOptionSelect={onOptionSelect}
      width={width}
      onChange={handleSelectOption}
      value={props.value}
    />
  );
};

SelectStyled.propTypes = {
  theme: PropTypes.object,
  maxMenuHeight: PropTypes.number,
  options: PropTypes.array,
  onOptionSelect: PropTypes.func,
  isDot: PropTypes.bool,
  width: PropTypes.string,
  name: PropTypes.string,
};
