import styled from 'styled-components';
import PropTypes from 'prop-types';

export const Container = styled.div`
  margin: ${(props) => props.margin || '10px'};
  width: ${(props) => props.width || '400px'};
  height: ${(props) => props.height || '500px'};
  text-align: ${(props) => props.textAlign || 'center'};
  background: ${({ theme }) => theme.containerBackgroundColor};
  border-radius: ${(props) => props.radius || '7px'};
  display: flex;
  flex-direction: ${(props) => props.direction || 'column'};
  align-items: ${(props) => props.align || 'center'};
  justify-content: ${(props) => props.justify || 'center'};
`;

export const ContainerStyled = (props) => {
  return <Container {...props} />;
};

ContainerStyled.propTypes = {
  margin: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  textAlign: PropTypes.string,
  radius: PropTypes.string,
  direction: PropTypes.string,
  align: PropTypes.string,
  justify: PropTypes.string,
};
