import PropTypes from 'prop-types';
import { Container, ErrorMessage } from './StyledComponents';
import { SelectStyled } from '../SelectStyled/SelectStyled';

export const SelectValidation = ({
                                   field,
                                   form: { errors, touched },
                                   theme,
                                   onChange,
                                   options,
                                   width,
                                   isDot,
                                   maxMenuHeight,
                                   id,
                                   value,
                                 }) => {
  const defaultValue = (options, value) => {
    return options
      ? options.find((option) => {
        return option.value === value;
      })
      : null;
  };
  return (
    <Container>
      <SelectStyled
        {...field}
        theme={theme}
        maxMenuHeight={maxMenuHeight}
        onChange={onChange}
        value={defaultValue(options, value)}
        options={options}
        width={width}
        isDot={isDot}
        id={id}
      />
      {errors[field.name] && touched[field.name] ? (
        <div>
          <ErrorMessage width={width}>{errors[field.name]}</ErrorMessage>
        </div>
      ) : null}
    </Container>
  );
};

SelectValidation.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  theme: PropTypes.object,
  onChange: PropTypes.func,
  options: PropTypes.array,
  width: PropTypes.string,
  isDot: PropTypes.bool,
  maxMenuHeight: PropTypes.number,
};
