import { hideLoader, showLoader, showToastWithTimeout } from '../utils/common';
import store from '../redux/store';
import { setSelectedUsers } from '../redux/actions/userManagement';
import { AxiosFactory } from './axios/AxiosFactory';
import * as authStatus from '../utils/auth';
import { handleResponseError } from './axios/interceptors/responseError';
import { setUserData } from '../redux/actions/login';
import { getUserDataFromLocalStorage, setUserDataToLocalStorage } from '../utils/auth';

export const ROOT_URL = process.env.REACT_APP_URL;

export const API_PATHS = {
  userManagementPaths: {
    users: '/api/users',
    patchItemsPerPage: '/api/users/items-per-page',
    changeUsersPassword: '/api/users/profile/password',
    updateUserProfile: '/api/users/profile/personal',
    updateUserPassword: '/api/users/profile/password',
    updateUserProfileLogo: '/api/users/profile/image',
  },
  accountSession: {
    getListOfAllowedEntitiesAndRoles: '/api/account-session/allowed',
    getChosenEntityAndRole: '/api/account-session',
    setRoleAndEntity: '/api/account-session',
  },
  authPaths: {
    login: '/api/login',
    user: '/api/user',
    logout: '/api/logout',
    userInfo: '/api/user',
    forgotPassword: '/api/forgot-password',
    resetPassword: '/api/reset-password',
  },
  roleManagementPaths: {
    roles: '/api/roles',
  },
  permissionPaths: {
    permissions: '/api/permissions',
  },
  applicationPaths: {
    applications: '/api/apps',
  },
  entitiesPaths: {
    entities: '/api/entities',
    entitiesPaginated: '/api/entities/paginated',
  },
  entityPaths: {
    entityTypesCollection: '/api/entity-types',
  },
  configPaths: {
    config: '/api/config/crm',
  },
  importCsvPaths: {
    example: '/csv/example.csv',
    import: '/api/users/import/csv',
  },
};

const axiosInstance = new AxiosFactory()
  .addInterceptor(handleResponseError)
  .getInstance();

export class Api {
  constructor() {
    this.API_URL = ROOT_URL;
  }

  async updateUserProfileLogo(imageBlob) {
    showLoader();

    let url = `${this.API_URL}${API_PATHS.userManagementPaths.updateUserProfileLogo}`;
    let formData = new FormData();
    formData.append('image', imageBlob);

    try {
      const response = await axiosInstance({
        method: 'POST',
        url: url,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        showToastWithTimeout({ success: true, text: 'Successfully completed', isShown: 'visible' });
        return response;
      }
    } catch (error) {
      console.log('Api call updateUserLogo : ' + error.message);
      showToastWithTimeout({ success: false, text: `${error.message}`, isShown: 'visible' });
    } finally {
      hideLoader();
    }
  }

  async updateUserLogo(imageBlob, userId) {
    showLoader();
    const updateUserLogoPath = `/api/users/${userId}/image`;

    let url = `${this.API_URL}` + updateUserLogoPath;
    let formData = new FormData();
    formData.append('image', imageBlob);

    try {
      const response = await axiosInstance({
        method: 'POST',
        url: url,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        showToastWithTimeout({ success: true, text: 'Successfully completed', isShown: 'visible' });
        return response;
      }
    } catch (error) {
      console.log('Api call updateUserLogo : ' + error.message);
      showToastWithTimeout({ success: false, text: `${error.message}`, isShown: 'visible' });
    } finally {
      hideLoader();
    }
  }

  async userInfo() {
    try {
      let url = `${this.API_URL}${API_PATHS.authPaths.user}`;
      const userDataResponse = await axiosInstance.get(url);

      if (userDataResponse.status === 200) {
        setUserDataToLocalStorage(userDataResponse.data);
        store.dispatch(setUserData(getUserDataFromLocalStorage()));

        return userDataResponse;
      }
    } catch (error) {
      console.log('checkAuth: ' + error.message);
    }
  }

  async getListOfAllowedEntitiesAndRoles() {
    let url = `${this.API_URL}${API_PATHS.accountSession.getListOfAllowedEntitiesAndRoles}`;
    try {
      const response = await axiosInstance.get(url);
      if (response.status === 200) {
        return response;
      }
    } catch (error) {
      console.log('Api call getListOfAllowedEntitiesAndRoles : ' + error.message);
    }
  }

  async getChosenEntityAndRole() {
    let url = `${this.API_URL}${API_PATHS.accountSession.getChosenEntityAndRole}`;
    try {
      const response = await axiosInstance.get(url);
      if (response.status === 200) {
        return response;
      }
    } catch (error) {
      console.log('Api call getChosenEntityAndRole : ' + error.message);
    }
  }

  async setRoleAndEntity(value) {
    showLoader();
    let url = `${this.API_URL}${API_PATHS.accountSession.setRoleAndEntity}`;
    try {
      const response = await axiosInstance.post(url, value);
      if (response.status === 200) {
        return response;
      }
    } catch (error) {
      console.log('Api call setRoleAndEntity : ' + error.message);
      showToastWithTimeout({ success: false, text: 'An error has occurred', isShown: 'visible' });
    } finally {
      hideLoader();
    }
  }

  async login(user) {
    try {
      let url = `${this.API_URL}${API_PATHS.authPaths.login}`;
      const response = await axiosInstance.post(url, user);

      if (response.status === 200 || response.status === 204) {
        if (response.data?.error) {
          showToastWithTimeout({ success: false, text: 'An error has occurred', isShown: 'visible' });
        }
        return response;
      }
    } catch (error) {
      console.log('Api call login : ' + error.message);
      showToastWithTimeout({ success: false, text: 'An error has occurred', isShown: 'visible' });
    }
  }

  async logout() {
    showLoader();
    try {
      let url = `${this.API_URL}${API_PATHS.authPaths.logout}`;
      const response = await axiosInstance.post(url);

      if (response.status === 200 || response.status === 204) {
        authStatus.setLoggedOut();
        return response;
      }
    } catch (error) {
      console.log('Api call login : ' + error.message);
    } finally {
      hideLoader();
    }
  }

  async forgotPassword(email, resetForm) {
    showLoader();
    let url = `${this.API_URL}${API_PATHS.authPaths.forgotPassword}`;
    try {
      const response = await axiosInstance.post(url, email);
      if (response.status === 200) {
        showToastWithTimeout({ success: true, text: 'Successfully completed', isShown: 'visible' });
        resetForm();
        return response;
      }
    } catch (error) {
      console.log('Api call forgotPassword : ' + error.message);
      showToastWithTimeout({ success: false, text: 'An error has occurred', isShown: 'visible' });
    } finally {
      hideLoader();
    }
  }

  async resetPassword(data, resetForm) {
    showLoader();
    let url = `${this.API_URL}${API_PATHS.authPaths.resetPassword}`;
    try {
      const response = await axiosInstance.post(url, data);
      if (response.status === 200) {
        showToastWithTimeout({ success: true, text: 'Successfully completed', isShown: 'visible' });
        resetForm();
        return response;
      }
    } catch (error) {
      console.log('Api call resetPassword : ' + error.message);
      showToastWithTimeout({ success: false, text: 'An error has occurred', isShown: 'visible' });
    } finally {
      hideLoader();
    }
  }

  async updateUserProfile(userSettings) {
    showLoader();
    let url = `${this.API_URL}${API_PATHS.userManagementPaths.updateUserProfile}`;
    try {
      const response = await axiosInstance({
        method: 'PUT',
        url: url,
        data: userSettings,
      });

      if (response.status === 200) {
        showToastWithTimeout({ success: true, text: 'Successfully completed', isShown: 'visible' });
        return response;
      }
    } catch (error) {
      console.log('Api call updateUserProfile : ' + error.message);
      showToastWithTimeout({ success: false, text: `An error has occurred`, isShown: 'visible' });
    } finally {
      hideLoader();
    }
  }

  async updateUserPassword(password, resetForm) {
    showLoader();
    let url = `${this.API_URL}${API_PATHS.userManagementPaths.updateUserPassword}`;
    try {
      const response = await axiosInstance.patch(url, password);
      if (response.status === 200) {
        showToastWithTimeout({ success: true, text: 'Successfully completed', isShown: 'visible' });
        resetForm();
        return response;
      }
    } catch (error) {
      console.log('Api call updateUserPassword : ' + error.message);
      showToastWithTimeout({ success: false, text: `${error.message}`, isShown: 'visible' });
    } finally {
      hideLoader();
    }
  }

  async getUsers(page, name, filterStatus) {
    showLoader();
    let url = name
      ? `${this.API_URL}${API_PATHS.userManagementPaths.users}?page=${page}&status=${filterStatus}&search=${name}`
      : `${this.API_URL}${API_PATHS.userManagementPaths.users}?page=${page}&status=${filterStatus}`;
    try {
      const response = await axiosInstance.get(url);
      if (response.status === 200) {
        showToastWithTimeout({ success: true, text: 'Successfully completed', isShown: 'visible' });
        return response;
      }
    } catch (error) {
      console.log('Api call getUsers : ' + error.message);
      showToastWithTimeout({ success: false, text: 'An error has occurred', isShown: 'visible' });
    } finally {
      hideLoader();
    }
  }

  async getUser(id) {
    showLoader();
    let url = `${this.API_URL}${API_PATHS.userManagementPaths.users}/${id}`;
    try {
      const response = await axiosInstance.get(url);
      if (response.status === 200) {
        showToastWithTimeout({ success: true, text: 'Successfully completed', isShown: 'visible' });
        return response;
      }
    } catch (error) {
      console.log('Api call getUser : ' + error.message);
      showToastWithTimeout({ success: false, text: 'An error has occurred', isShown: 'visible' });
    } finally {
      hideLoader();
    }
  }

  async deleteUsers(users) {
    showLoader();
    let url = `${this.API_URL}${API_PATHS.userManagementPaths.users}/batch`;
    try {
      const data = users;
      const response = await axiosInstance.delete(url, { data });
      if (response.status === 200) {
        showToastWithTimeout({ success: true, text: 'Successfully completed', isShown: 'visible' });
        store.dispatch(setSelectedUsers([]));
        return response;
      }
    } catch (error) {
      console.log('Api call deleteUser:' + error.message);
      showToastWithTimeout({ success: false, text: 'An error has occurred', isShown: 'visible' });
    } finally {
      hideLoader();
    }
  }

  async restoreUser(id) {
    showLoader();
    let url = `${this.API_URL}${API_PATHS.userManagementPaths.users}/${id}/restore?`;
    try {
      const params = {};
      const response = await axiosInstance.patch(url, params);
      if (response.status === 200) {
        showToastWithTimeout({ success: true, text: 'Successfully completed', isShown: 'visible' });
        return response;
      }
    } catch (error) {
      console.log('Api call restoreUser:' + error.message);
      showToastWithTimeout({ success: false, text: 'An error has occurred', isShown: 'visible' });
    } finally {
      hideLoader();
    }
  }

  async resetUserPassword(id) {
    showLoader();
    let url = `${this.API_URL}${API_PATHS.userManagementPaths.users}/reset-password`;
    try {
      const response = await axiosInstance.post(url, { id });
      if (response.status === 200) {
        showToastWithTimeout({ success: true, text: 'Successfully completed', isShown: 'visible' });
        return response;
      }
    } catch (error) {
      console.log('Api call resetUserPassword:' + error.message);
      showToastWithTimeout({ success: false, text: 'An error has occurred', isShown: 'visible' });
    } finally {
      hideLoader();
    }
  }

  async editUser(id, user, routerPush, resetForm) {
    showLoader();
    let url = `${this.API_URL}${API_PATHS.userManagementPaths.users}/${id}`;
    try {
      const response = await axiosInstance.put(url, user);
      if (response.status === 200) {
        showToastWithTimeout({ success: true, text: 'Successfully completed', isShown: 'visible' });
        resetForm();
        routerPush();
        return response;
      }
    } catch (error) {
      console.log('Api call editUser : ' + error.message);
      showToastWithTimeout({ success: false, text: 'An error has occurred', isShown: 'visible' });
    } finally {
      hideLoader();
    }
  }

  async patchUserStatus(id, status) {
    showLoader();
    let url = `${this.API_URL}${API_PATHS.userManagementPaths.users}/${id}/status`;
    try {
      const response = await axiosInstance.patch(url, status);
      if (response.status === 200) {
        showToastWithTimeout({ success: true, text: 'Successfully completed', isShown: 'visible' });
        return response;
      }
    } catch (error) {
      console.log('Api call patchUserStatus : ' + error.message);
      showToastWithTimeout({ success: false, text: 'An error has occurred', isShown: 'visible' });
    } finally {
      hideLoader();
    }
  }

  async patchCollectionUserStatus(users) {
    showLoader();
    let url = `${this.API_URL}${API_PATHS.userManagementPaths.users}/status/batch`;
    try {
      const response = await axiosInstance.patch(url, users);
      if (response.status === 200) {
        showToastWithTimeout({ success: true, text: 'Successfully completed', isShown: 'visible' });
        return response;
      }
    } catch (error) {
      console.log('Api call patchCollectionUserStatus : ' + error.message);
      showToastWithTimeout({ success: false, text: 'An error has occurred', isShown: 'visible' });
    } finally {
      hideLoader();
    }
  }

  async createUser(user) {
    showLoader();
    let url = `${this.API_URL}${API_PATHS.userManagementPaths.users}`;
    try {
      const response = await axiosInstance.post(url, user);
      if (response.status === 200) {
        showToastWithTimeout({ success: true, text: 'Successfully completed', isShown: 'visible' });
        return response;
      }
    } catch (error) {
      console.log('Api call createUser : ' + error.message);
      showToastWithTimeout({ success: false, text: error.message ?? 'An error has occurred', isShown: 'visible' });
      return error.message;
    } finally {
      hideLoader();
    }
  }

  async changeUserPassword(password, resetForm) {
    showLoader();
    let url = `${this.API_URL}${API_PATHS.userManagementPaths.changeUsersPassword}`;
    try {
      const response = await axiosInstance.patch(url, password);
      if (response.status === 200) {
        showToastWithTimeout({ success: true, text: 'Successfully completed', isShown: 'visible' });
        resetForm();
        return response;
      }
    } catch (error) {
      console.log('Api call changeUserPassword:' + error.message);
      showToastWithTimeout({ success: false, text: 'An error has occurred', isShown: 'visible' });
    } finally {
      hideLoader();
    }
  }

  async patchItemsPerPage(items) {
    showLoader();
    let url = `${this.API_URL}${API_PATHS.userManagementPaths.patchItemsPerPage}`;
    try {
      const response = await axiosInstance.patch(url, items);
      if (response.status === 200) {
        showToastWithTimeout({ success: true, text: 'Successfully completed', isShown: 'visible' });
        return response;
      }
    } catch (error) {
      console.log('Api call patchItemsPerPage:' + error.message);
      showToastWithTimeout({ success: false, text: 'An error has occurred', isShown: 'visible' });
    } finally {
      hideLoader();
    }
  }

  async getRoles(page, name, filterStatus) {
    showLoader();
    let url = name
      ? `${this.API_URL}${API_PATHS.roleManagementPaths.roles}?page=${page}&status=${filterStatus}&search=${name}`
      : `${this.API_URL}${API_PATHS.roleManagementPaths.roles}?page=${page}&status=${filterStatus}`;
    try {
      const response = await axiosInstance.get(url);
      if (response.status === 200) {
        showToastWithTimeout({ success: true, text: 'Successfully completed', isShown: 'visible' });
        return response;
      }
    } catch (error) {
      console.log('Api call getRoles : ' + error.message);
      showToastWithTimeout({ success: false, text: 'An error has occurred', isShown: 'visible' });
    } finally {
      hideLoader();
    }
  }

  async getActiveRoles() {
    showLoader();
    let url = `${this.API_URL}${API_PATHS.roleManagementPaths.roles}/active/collection`;
    try {
      const response = await axiosInstance.get(url);
      if (response.status === 200) {
        showToastWithTimeout({
          success: true,
          text: 'Successfully completed',
          isShown: 'visible',
        });
        return response;
      }
    } catch (error) {
      console.log('Api call getActiveRoles : ' + error.message);
      showToastWithTimeout({ success: false, text: 'An error has occurred', isShown: 'visible' });
    } finally {
      hideLoader();
    }
  }

  async getRole(id) {
    showLoader();
    let url = `${this.API_URL}${API_PATHS.roleManagementPaths.roles}/${id}`;
    try {
      const response = await axiosInstance.get(url);
      if (response.status === 200) {
        return response;
      }
    } catch (error) {
      console.log('Api call getRole : ' + error.message);
      showToastWithTimeout({ success: false, text: 'An error has occurred', isShown: 'visible' });
    }
  }

  async createRole(role, routerPush, resetForm) {
    showLoader();
    let url = `${this.API_URL}${API_PATHS.roleManagementPaths.roles}`;
    try {
      const response = await axiosInstance.post(url, role);
      if (response.status === 200) {
        showToastWithTimeout({ success: true, text: 'Successfully completed', isShown: 'visible' });
        resetForm();
        routerPush();
        return response;
      }
    } catch (error) {
      console.log('Api call createRole : ' + error.message);
      showToastWithTimeout({ success: false, text: `${error.message}`, isShown: 'visible' });
    } finally {
      hideLoader();
    }
  }

  async editRole(id, role, routerPush, resetForm) {
    showLoader();
    let url = `${this.API_URL}${API_PATHS.roleManagementPaths.roles}/${id}`;
    try {
      const response = await axiosInstance.put(url, role);
      if (response.status === 200) {
        showToastWithTimeout({ success: true, text: 'Successfully completed', isShown: 'visible' });
        resetForm();
        routerPush();
        return response;
      }
    } catch (error) {
      console.log('Api call editRole : ' + error.message);
      showToastWithTimeout({ success: false, text: `${error.message}`, isShown: 'visible' });
    } finally {
      hideLoader();
    }
  }

  async deleteRole(id) {
    showLoader();
    let url = `${this.API_URL}${API_PATHS.roleManagementPaths.roles}/${id}`;
    try {
      const response = await axiosInstance.delete(url);
      if (response.status === 200) {
        showToastWithTimeout({ success: true, text: 'Successfully completed', isShown: 'visible' });
        return response;
      }
    } catch (error) {
      console.log('Api call deleteRole :' + error.message);
      showToastWithTimeout({ success: false, text: `${error.message}`, isShown: 'visible' });
    } finally {
      hideLoader();
    }
  }

  async restoreRole(id) {
    showLoader();
    let url = `${this.API_URL}${API_PATHS.roleManagementPaths.roles}/${id}/restore`;
    try {
      const params = {};
      const response = await axiosInstance.patch(url, params);
      if (response.status === 200) {
        showToastWithTimeout({ success: true, text: 'Successfully completed', isShown: 'visible' });
        return response;
      }
    } catch (error) {
      console.log('Api call restoreRole:' + error.message);
      showToastWithTimeout({ success: false, text: 'An error has occurred', isShown: 'visible' });
    } finally {
      hideLoader();
    }
  }

  async getPermissionsActive() {
    let url = `${this.API_URL}${API_PATHS.permissionPaths.permissions}/active`;
    try {
      const response = await axiosInstance.get(url);
      if (response.status === 200) {
        return response;
      }
    } catch (error) {
      console.log('Api call getPermissionsActive : ' + error.message);
      showToastWithTimeout({ success: false, text: 'An error has occurred', isShown: 'visible' });
    }
  }

  async getPermissions(page, name, filterStatus) {
    showLoader();
    let url = name
      ? `${this.API_URL}${API_PATHS.permissionPaths.permissions}?page=${page}&status=${filterStatus}&search=${name}`
      : `${this.API_URL}${API_PATHS.permissionPaths.permissions}?page=${page}&status=${filterStatus}`;
    try {
      const response = await axiosInstance.get(url);
      if (response.status === 200) {
        showToastWithTimeout({
          success: true, text: 'Successfully completed', isShown: 'visible',
        });
        return response;
      }
    } catch (error) {
      console.log('Api call getPermissions : ' + error.message);
      showToastWithTimeout({ success: false, text: 'An error has occurred', isShown: 'visible' });
    } finally {
      hideLoader();
    }
  }

  async getPermission(id) {
    showLoader();
    let url = `${this.API_URL}${API_PATHS.permissionPaths.permissions}/${id}`;
    try {
      const response = await axiosInstance.get(url);
      if (response.status === 200) {
        showToastWithTimeout({ success: true, text: 'Successfully completed', isShown: 'visible' });
        return response;
      }
    } catch (error) {
      console.log('Api call getPermission : ' + error.message);
      showToastWithTimeout({ success: false, text: 'An error has occurred', isShown: 'visible' });
    } finally {
      hideLoader();
    }
  }

  async createPermission(permission, routerPush, resetForm) {
    showLoader();
    let url = `${this.API_URL}${API_PATHS.permissionPaths.permissions}`;
    try {
      const response = await axiosInstance.post(url, permission);
      if (response.status === 200) {
        showToastWithTimeout({ success: true, text: 'Successfully completed', isShown: 'visible' });
        resetForm();
        routerPush();
        return response;
      }
    } catch (error) {
      console.log('Api call createPermission: ' + error.message);
      showToastWithTimeout({ success: false, text: `${error.message}`, isShown: 'visible' });
    } finally {
      hideLoader();
    }
  }

  async editPermission(id, permission, routerPush, resetForm) {
    showLoader();
    let url = `${this.API_URL}${API_PATHS.permissionPaths.permissions}/${id}`;
    try {
      const response = await axiosInstance.put(url, permission);
      if (response.status === 200) {
        showToastWithTimeout({ success: true, text: 'Successfully completed', isShown: 'visible' });
        resetForm();
        routerPush();
        return response;
      }
    } catch (error) {
      console.log('Api call editPermission : ' + error.message);
      showToastWithTimeout({ success: false, text: `${error.message}`, isShown: 'visible' });
    } finally {
      hideLoader();
    }
  }

  async deletePermission(id) {
    showLoader();
    let url = `${this.API_URL}${API_PATHS.permissionPaths.permissions}/${id}`;
    try {
      const response = await axiosInstance.delete(url);
      if (response.status === 200) {
        showToastWithTimeout({ success: true, text: 'Successfully completed', isShown: 'visible' });
        return response;
      }
    } catch (error) {
      console.log('Api call deletePermission : ' + error.message);
      showToastWithTimeout({ success: false, text: 'An error has occurred', isShown: 'visible' });
    } finally {
      hideLoader();
    }
  }

  async restorePermission(id) {
    showLoader();
    let url = `${this.API_URL}${API_PATHS.permissionPaths.permissions}/${id}/restore`;
    try {
      const params = {};
      const response = await axiosInstance.patch(url, params);
      if (response.status === 200) {
        showToastWithTimeout({ success: true, text: 'Successfully completed', isShown: 'visible' });
        return response;
      }
    } catch (error) {
      console.log('Api call restorePermission:' + error.message);
      showToastWithTimeout({ success: false, text: 'An error has occurred', isShown: 'visible' });
    } finally {
      hideLoader();
    }
  }

  async getApplications() {
    showLoader();
    let url = `${this.API_URL}${API_PATHS.applicationPaths.applications}`;
    try {
      const response = await axiosInstance.get(url);
      if (response.status === 200) {
        showToastWithTimeout({
          success: true,
          text: 'Successfully completed',
          isShown: 'visible',
        });
        return response;
      }
    } catch (error) {
      console.log('Api call getApplications:' + error.message);
      showToastWithTimeout({ success: false, text: 'An error has occurred', isShown: 'visible' });
    } finally {
      hideLoader();
    }
  }

  async getEntitiesPaginated(page, name) {
    showLoader();
    let url = name
      ? `${this.API_URL}${API_PATHS.entitiesPaths.entitiesPaginated}?page=${page}&search=${name}`
      : `${this.API_URL}${API_PATHS.entitiesPaths.entitiesPaginated}?page=${page}`;
    try {
      const response = await axiosInstance.get(url);
      if (response.status === 200) {
        showToastWithTimeout({ success: true, text: 'Successfully completed', isShown: 'visible' });
        return response;
      }
    } catch (error) {
      console.log('Api call getEntitiesPaginated : ' + error.message);
      showToastWithTimeout({ success: false, text: 'An error has occurred', isShown: 'visible' });
    } finally {
      hideLoader();
    }
  }

  async getEntities() {
    showLoader();
    let url = `${this.API_URL}${API_PATHS.entitiesPaths.entities}`;
    try {
      const response = await axiosInstance.get(url);
      if (response.status === 200) {
        showToastWithTimeout({ success: true, text: 'Successfully completed', isShown: 'visible' });
        return response;
      }
    } catch (error) {
      console.log('Api call getEntities : ' + error.message);
      showToastWithTimeout({ success: false, text: 'An error has occurred', isShown: 'visible' });
    } finally {
      hideLoader();
    }
  }

  async postEntity(organization, routerPush, resetForm) {
    showLoader();
    let url = `${this.API_URL}${API_PATHS.entitiesPaths.entities}`;
    try {
      const response = await axiosInstance.post(url, organization);
      if (response.status === 200) {
        showToastWithTimeout({ success: true, text: 'Successfully completed', isShown: 'visible' });
        resetForm();
        routerPush();
        return response;
      }
    } catch (error) {
      console.log('Api call postEntity : ' + error.message);
      showToastWithTimeout({ success: false, text: 'An error has occurred', isShown: 'visible' });
    } finally {
      hideLoader();
    }
  }

  async putEntity(id, organization, routerPush, resetForm) {
    showLoader();
    let url = `${this.API_URL}${API_PATHS.entitiesPaths.entities}/${id}`;
    try {
      const response = await axiosInstance.put(url, organization);
      if (response.status === 200) {
        showToastWithTimeout({ success: true, text: 'Successfully completed', isShown: 'visible' });
        resetForm();
        routerPush();
        return response;
      }
    } catch (error) {
      console.log('Api call putEntity : ' + error.message);
      showToastWithTimeout({ success: false, text: 'An error has occurred', isShown: 'visible' });
    } finally {
      hideLoader();
    }
  }

  async getEntityById(id) {
    showLoader();
    let url = `${this.API_URL}${API_PATHS.entitiesPaths.entities}/${id}`;
    try {
      const response = await axiosInstance.get(url);
      if (response.status === 200) {
        showToastWithTimeout({ success: true, text: 'Successfully completed', isShown: 'visible' });
        return response;
      }
    } catch (error) {
      console.log('Api call getEntityById : ' + error.message);
      showToastWithTimeout({ success: false, text: 'An error has occurred', isShown: 'visible' });
    } finally {
      hideLoader();
    }
  }

  async getEntityTypesCollection() {
    showLoader();
    let url = `${this.API_URL}${API_PATHS.entityPaths.entityTypesCollection}`;
    try {
      const response = await axiosInstance.get(url);
      if (response.status === 200) {
        return response;
      }
    } catch (error) {
      console.log('Api call getEntityTypesCollection : ' + error.message);
      showToastWithTimeout({ success: false, text: 'An error has occurred', isShown: 'visible' });
    } finally {
      hideLoader();
    }
  }

  async getEntityTypeById(id) {
    showLoader();
    let url = `${this.API_URL}${API_PATHS.entityPaths.entityTypesCollection}/${id}`;
    try {
      const response = await axiosInstance.get(url);
      if (response.status === 200) {
        return response;
      }
    } catch (error) {
      console.log('Api call getEntityTypesCollection : ' + error.message);
      showToastWithTimeout({ success: false, text: 'An error has occurred', isShown: 'visible' });
    } finally {
      hideLoader();
    }
  }

  async getConfig() {
    showLoader();
    let url = `${this.API_URL}${API_PATHS.configPaths.config}`;
    try {
      const response = await axiosInstance.get(url);
      if (response.status === 200) {
        return response;
      }
    } catch (error) {
      console.log('Api call getConfig : ' + error.message);
      showToastWithTimeout({ success: false, text: 'An error has occurred', isShown: 'visible' });
    } finally {
      hideLoader();
    }
  }
}
