import React, { useEffect, useMemo, useState } from 'react';
import { Header } from '../../components/Header/Header';
import { BackgroundStyled } from '../../components/BackgroundStyled/BackgroundStyled';
import { AppCardStyled, AppsWrapperStyled, ContainerStyled, DescriptionStyled, TitleStyled } from './StyledComponents';
import { FlexStyled } from '../../components/FlexStyled/FlexStyled';
import { ImgStyled } from '../../components/ImgStyled/ImgStyled';
import AppLogo from '../../assets/logos/logo-now-cm.png';
import { ApiApplications } from '../../api/ApiApplications';
import { hideLoader, showLoader } from '../../utils/common';
import { setWelcomePage } from '../../utils/auth';

export const WelcomePage = () => {
  const apiApplications = useMemo(() => new ApiApplications(), []);
  const [allowedUserApps, setAllowedUserApps] = useState([]);

  useEffect(() => {
    let unmounted = false;
    setWelcomePage('false');
    (async () => {
      showLoader();

      const [allowedApps] = await Promise.all([
        await apiApplications.getAllowedUserApps(),
      ]);

      if (!unmounted && allowedApps) {
        setAllowedUserApps(allowedApps.data.data);
      }
      hideLoader();
    })();

    return () => {
      unmounted = true;
    };
  }, [apiApplications]);

  return (
    <BackgroundStyled align={'flex-start'}>
      <Header />
      <ContainerStyled>
        <FlexStyled align={'start'}>
          <ImgStyled bottom={'30px'} src={AppLogo} alt='logo' />
        </FlexStyled>
        <TitleStyled>Welcome to NowCM </TitleStyled>
        <TitleStyled>Available to you Applications: </TitleStyled>
        <AppsWrapperStyled>
          {allowedUserApps?.length !== 0 && (
            allowedUserApps.map((el, i) => {
              return (
                <AppCardStyled href={el?.url} key={i}>
                  <FlexStyled align={'center'}>
                    <ImgStyled height={'64px'} bottom={'5px'} top={'5px'} src={el?.logo_url} alt='logo' />
                  </FlexStyled>
                  <TitleStyled margin={'0 24px'}>{el?.name}</TitleStyled>
                  <DescriptionStyled>{el?.short_desc}</DescriptionStyled>
                </AppCardStyled>
              );
            })
          )
          }
        </AppsWrapperStyled>
      </ContainerStyled>
    </BackgroundStyled>
  );
};