import { showErrorToast } from '../utils/common';
import { axiosInstance, API_URL, API_URL_OLD } from './ApiConfig';

const API_PATHS = {
  allowedUserApps: API_URL + '/profile/apps',
  applications: API_URL_OLD + '/apps',
};

export class ApiApplications {

  async handleApiCall(requestConfig = {}) {
    try {
      return await axiosInstance(requestConfig);

    } catch (error) {
      showErrorToast(error.response?.data?.message || 'An error has occurred');

      if (process.env.NODE_ENV !== 'production') {
        console.log('ApiApplications: ' + error.response?.data?.message);
      }
    }
  }

  async getApplications() {
    return this.handleApiCall({
      method: 'GET',
      url: API_PATHS.applications,
    });
  }

  async getAllowedUserApps() {
    return this.handleApiCall({
      method: 'GET',
      url: API_PATHS.allowedUserApps,
    });
  }
}