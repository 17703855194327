import PropTypes from 'prop-types';
import { Container, ErrorMessage } from './StyledComponents';
import { CheckboxStyled } from '../CheckboxStyled/CheckboxStyled';

export const CheckboxValidation = ({ field, form: { errors, touched }, type, onChange }) => {
  return (
    <Container>
      <CheckboxStyled {...field} type={type} onChange={onChange} />
      {errors[field.name] && touched[field.name] ? (
        <ErrorMessage>{errors[field.name]}</ErrorMessage>
      ) : null}
    </Container>
  );
};

CheckboxValidation.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  type: PropTypes.string,
  onChange: PropTypes.func,
};
