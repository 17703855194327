import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { hasPermission } from '../../../utils/common';
import { AccessDenied } from '../../../pages/AccessDenied/AccessDenied';
import { ROUTES } from '../../../constants/routes';

export const PermissionRoute = ({ component: Component, permissionGranted, ...rest }) => {
  let isLogin = localStorage.getItem('login');

  const userPermissions = useSelector((state) => state.login.userPermissions);

  const isPermissionGranted = hasPermission(userPermissions, permissionGranted);

  const adminOrganizationType = useSelector((state) => state.login?.userData?.is_organization_admin);
  const adminType = useSelector((state) => state.login?.userData?.is_admin);

  const userAccess = adminType || adminOrganizationType;

  return (
    <Route
      {...rest}
      render={(props) =>
        isLogin === 'true' ? (
          isPermissionGranted && userAccess ? <Component key={props.match.params.id || 'empty'} {...props} /> :
            <AccessDenied />
        ) : (
          <Redirect to={{ pathname: ROUTES.login, state: { from: props.location } }} />
        )
      }
    />
  );
};