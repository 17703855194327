import { useSelector } from 'react-redux';
import { hasPermission } from '../../utils/common';

export const PermissionsGate = ({ children, permissionGranted }) => {

  const userPermissions = useSelector((state) => state.login.userPermissions);
  const isPermissionGranted = hasPermission(userPermissions, permissionGranted);

  if (!isPermissionGranted) return <></>;

  return <>{children}</>;
};