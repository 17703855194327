import React from 'react';
import PropTypes from 'prop-types';
import { ButtonPrimary } from '../ButtonPrimary/ButtonPrimary'

export const ButtonPrimaryFileUpload = (props) => {
  const { top, bottom, left, right, fontSize, type, buttonName, handleFile } = props;

  const hiddenFileInput = React.useRef(null);

  const handleButtonClick = () => {
    hiddenFileInput.current.value = null;
    hiddenFileInput.current.click();
  };

  const handleInputChange = () => {
    const fileUploaded = hiddenFileInput.current.files[0];
    handleFile(fileUploaded);
  };

  return (
    <>
      <input
        type="file"
        id="importCsvFile"
        style={{display: 'none'}}
        onChange={handleInputChange}
        ref={hiddenFileInput}
        accept={".csv"}
      />
      <ButtonPrimary
        top={top}
        bottom={bottom}
        left={left}
        right={right}
        type={type}
        onClick={handleButtonClick}
        fontSize={fontSize}
        buttonName={buttonName}
      />
    </>
  );
};

ButtonPrimaryFileUpload.propTypes = {
  handleFile: PropTypes.func,
  ...ButtonPrimary.propTypes
}
