import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const Template = styled.button`
  color: ${({ theme }) => theme.btnDisabledTextColor};
  background: ${({ theme }) => theme.btnDisabledBackgroundColor};
  margin-top: ${(props) => props.top || '5px'};
  margin-bottom: ${(props) => props.bottom || '5px'};
  margin-left: ${(props) => props.left || '5px'};
  margin-right: ${(props) => props.right || '5px'};
  font-size: ${(props) => props.fontSize || '12px'};
  border: none;
  padding: 2px 8px;
  display: inline-block;
  min-height: 24px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: center;
  text-decoration: none;
  border-radius: 2px;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  &:hover {
    background: ${({ theme }) => theme.btnDisabledBackgroundHoverColor};
    color: ${({ theme }) => theme.btnDisabledTextHoverColor};
  }
  cursor: pointer;
`;

export const ButtonDisabled = (props) => {
  const { top, bottom, left, right, fontSize, onClick, type, buttonName } = props;

  return (
    <Template
      top={top}
      bottom={bottom}
      left={left}
      right={right}
      type={type}
      onClick={onClick}
      fontSize={fontSize}
    >
      {buttonName}
    </Template>
  );
};

ButtonDisabled.propTypes = {
  top: PropTypes.string,
  bottom: PropTypes.string,
  left: PropTypes.string,
  right: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  fontSize: PropTypes.string,
};
