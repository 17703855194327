const initialState = {
  organizationsList: [],
  currentPage: null,
  lastPage: null,
  firstPage: null,
  nextPage: null,
  prevPage: null,
  totalCount: null,
  defaultItemsPerPage: null,
};

export const organizations = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ORGANIZATIONS_LIST':
      return {
        ...state,
        organizationsList: action.payload?.data.data,
        currentPage: action.payload.data.current_page,
        lastPage: action.payload.data.last_page,
        firstPage: action.payload.data.first_page_url,
        nextPage: action.payload.data.next_page_url,
        prevPage: action.payload.data.prev_page_url,
        defaultItemsPerPage: action.payload.data.per_page,
        totalCount: action.payload.data.total,
      };
    default:
      return state;
  }
};
