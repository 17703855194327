import { AxiosFactory } from './axios/AxiosFactory';
import { handleResponseError } from './axios/interceptors/responseError';

export const axiosInstance = new AxiosFactory()
  .addInterceptor(handleResponseError)
  .getInstance();

export const BASE_API_URL = process.env.REACT_APP_URL;

const API_VERSION = '/api/V1_2';
const API_VERSION_OLD = '/api';

export const API_URL = BASE_API_URL + API_VERSION;
export const API_URL_OLD = BASE_API_URL + API_VERSION_OLD;

export const API_PATHS = {
  permissions: `${API_VERSION}/permissions`,
  applicationPaths: {
    applications: `${API_VERSION_OLD}/apps`,
  },
};

