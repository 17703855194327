const initialState = {
  theme: {},
};

export const theme = (state = initialState, action) => {
  switch (action.type) {
    case 'IS_THEME':
      return {
        ...state,
        theme: action.payload,
      };
    default:
      return state;
  }
};
