import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import { Link, useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';
import { Header } from '../../../../components/Header/Header';
import { BackgroundStyled } from '../../../../components/BackgroundStyled/BackgroundStyled';
import { LabelStyled } from '../../../../components/LabelStyled/LabelStyled';
import { editUserValidation } from '../../../../utils/validationSchema';
import { InputValidation } from '../../../../components/InputValidation/InputValidation';
import { GroupStyled } from '../../../../components/GroupStyled/GroupStyled';
import { ButtonPrimary } from '../../../../components/ButtonPrimary/ButtonPrimary';
import { ButtonSecondary } from '../../../../components/ButtonSecondary/ButtonSecondary';
import { Api } from '../../../../api/Api';
import { SelectValidation } from '../../../../components/SelectValidation/SelectValidation';
import {
  CardStyled,
  Container,
  PageTitleStyled,
  CardTitleStyled,
  FlexStyled, MenuNavigationStyled, WrapperStyled, BtnWrapperStyled,
} from '../../../../components/StyledComponents/StyledComponents';
import { CheckBoxList } from '../../../../components/CheckBoxList/CheckBoxList';
import { RadioButtonList } from '../../../../components/RadioButtonList/RadioButtonList';
import { ChipStyled } from '../../../../components/ChipStyled/ChipStyled';
import { ROUTES } from '../../../../constants/routes';
import { ScrollStyled } from '../../../../components/ScrollStyled/ScrollStyled';
import { UploadWrapper } from '../../../../components/LogoUpload/UploadWrapper';
import { PlusCircleStyled } from '../../../../utils/iconsStyled';
import { Modals } from '../../../../components/Modals/Modals';
import { PermissionsGate } from '../../../../components/PermissionsGate/PermissionsGate';
import { PERMISSIONS } from '../../../../constants/permission';

const SIDE_MENU = [
  {
    label: 'Personal Information',
    activeClass: 'active',
    to: 'personalInformation',
    spy: true,
    smooth: false,
    offset: -100,
    duration: 500,
  },
  {
    label: 'Contact Information',
    activeClass: 'active',
    to: 'contactInformation',
    spy: true,
    smooth: false,
    offset: -100,
    duration: 500,
  },
  {
    label: 'Change User Status',
    activeClass: 'active',
    to: 'changeUserStatus',
    spy: true,
    smooth: false,
    offset: -100,
    duration: 500,
  },
  {
    label: 'Access',
    activeClass: 'active',
    to: 'access',
    spy: true,
    smooth: false,
    offset: -100,
    duration: 500,
  },
];

export const EditUser = () => {
  const theme = useSelector((state) => state.theme.theme);
  const location = useLocation();
  const FIRST_PAGE = 1;
  const REGEX = /\d+/g;
  const ID = location.pathname.match(REGEX)[0];
  const dispatch = useDispatch();
  const history = useHistory();
  const api = useMemo(() => new Api(), []);

  const routerPush = () => {
    history.push(ROUTES.userManagement);
  };

  const adminOrganizationType = useSelector((state) => state.login.userData.is_organization_admin);
  const adminType = useSelector((state) => state.login.userData.is_admin);
  const userOrganization = useSelector((state) => state.login.userData.admin_of_entity_id);

  const [userData, setUserData] = useState({});

  const createFormattedRolesCheckedList = useCallback((arr, ids) => {
    const roleList = [];
    const rolesId = createRolesId(ids);

    arr.forEach(el => roleList.push({
      id: el.id,
      isChecked: rolesId.includes(el.id),
      name: el.name,
    }));
    return roleList;
  }, []);

  const createFormattedRolesListWithCheckbox = useCallback((list, ids) => {
    const rolesList = [];
    list?.forEach(el => rolesList.push(
      {
        id: el.id,
        name: el.name,
        list: createFormattedRolesCheckedList(el.roles, ids),
      }));
    return rolesList;
  }, [createFormattedRolesCheckedList]);

  useEffect(() => {
    let unmounted = false;

    (async () => {
      const user = await api.getUser(ID);

      if (!unmounted && user?.status === 200) {
        setUserData(user.data);
        if (user.data.is_admin === 1 && user.data.is_tester === 0 && user.data.is_organization_admin === 0) {
          setCheckedUserTypeValue('is_admin');
          setChipsData({
            userOrganizations: createFormattedUserOrganizationsList(user.data?.entity),
            adminOrganization: [],
          });
        } else if (user.data.is_admin === 0 && user.data.is_tester === 1 && user.data.is_organization_admin === 0) {
          setCheckedUserTypeValue('is_tester');
          setChipsData({
            userOrganizations: createFormattedUserOrganizationsList(user.data?.entity),
            adminOrganization: [],
          });
        } else if (user.data.is_admin === 0 && user.data.is_tester === 0 && user.data.is_organization_admin === 1) {
          setCheckedUserTypeValue('is_organization_admin');
          if (user.data.admin_of_entity_id !== null) {
            const organization = await api.getEntityById(user.data.admin_of_entity_id);
            setChipsData({
              userOrganizations: createFormattedUserOrganizationsList(user.data?.entity),
              adminOrganization: [{ id: organization?.data?.id, name: organization?.data?.name }],
            });
          } else {
            setChipsData({
              userOrganizations: createFormattedUserOrganizationsList(user.data?.entity),
              adminOrganization: [],
            });
          }
        } else if (user.data.is_admin === 0 && user.data.is_tester === 0 && user.data.is_organization_admin === 0) {
          setCheckedUserTypeValue('default');
          setChipsData({
            userOrganizations: createFormattedUserOrganizationsList(user.data?.entity),
            adminOrganization: [],
          });
        }
        setOrganizationsListData({
          suggestions: [],
          data: createFormattedRolesListWithCheckbox(user.data?.entity, user.data.roles),
        });
      }
    })();

    return () => {
      unmounted = true;
    };
  }, [createFormattedRolesListWithCheckbox, api, dispatch, ID]);


  const dataUserStatus = [
    { value: '0-select', label: 'Inactive' },
    { value: '1-select', label: 'Active' },
  ];

  const handleCheckboxChange = (e, id) => {
    let checkBoxDataCopy = [...rolesCheckedList];
    checkBoxDataCopy.forEach((item) => {
      item.list.forEach(e => {
        if (e.id === id) {
          e.isChecked = !e.isChecked;
        }
      });

    });
    setRolesCheckedList(checkBoxDataCopy);
  };

  const userTypeAdmin = [
    { label: 'Default', id: 'default', value: 'default' },
    { label: 'Admin - manages  all organizations', id: 'is_admin', value: 'is_admin' },
    {
      label: 'Organization Admin - administrates one organization but can also be a member  of other  organizations',
      id: 'is_organization_admin',
      value: 'is_organization_admin',
    },
    { label: 'Tester - belongs to a default demo organization', id: 'is_tester', value: 'is_tester' },
  ];

  const [checkedUserTypeValue, setCheckedUserTypeValue] = useState('');

  const handleChangeUserType = (e) => {
    if (e.target.id === 'is_admin') {
      setUserData((prevState) => ({
        ...userData,
        is_admin: 1,
        is_tester: 0,
        is_organization_admin: 0,
      }));
      setChipsData({
        userOrganizations: [],
        suggestions: [],
        adminOrganization: [],
      });
      setCheckedUserTypeValue(e.target.value);
    }
    if (e.target.id === 'is_tester') {
      setCheckedUserTypeValue(e.target.value);
      setUserData((prevState) => ({
        ...userData,
        is_admin: 0,
        is_tester: 1,
        is_organization_admin: 0,
      }));
      setChipsData({
        userOrganizations: [],
        suggestions: [],
        adminOrganization: [],
      });
    }
    if (e.target.id === 'is_organization_admin') {
      setCheckedUserTypeValue(e.target.value);
      setUserData((prevState) => ({
        ...userData,
        is_admin: 0,
        is_tester: 0,
        is_organization_admin: 1,
      }));
      setChipsData({
        userOrganizations: [],
        suggestions: [],
        adminOrganization: [],
      });
    } else if (e.target.id === 'default') {
      setCheckedUserTypeValue(e.target.value);
      setUserData((prevState) => ({
        ...userData,
        is_admin: 0,
        is_tester: 0,
        is_organization_admin: 0,
      }));
      setChipsData({
        userOrganizations: [],
        suggestions: [],
        adminOrganization: [],
      });
    }
  };

  const getSelectedId = (data) => {
    const idsArr = [];
    if (data) {
      data.forEach((el) => {
        el.list.forEach(e => {
          if (e.isChecked === true) {
            idsArr.push(e.id);
          }
        });
      });
    }
    return idsArr;
  };


  //Select organizations
  const [chipsData, setChipsData] = useState({
    userOrganizations: userOrganization === null ? [] : [userOrganization],
    adminOrganization: [],
  });

  const [organizationsListData, setOrganizationsListData] = useState(
    {
      suggestions: [],
      data: [],
    });

  const createFormattedUserOrganizationsList = (data) => {
    const list = [];
    if (data.length !== 0) {
      data.forEach(el => {
        list.push({ id: el.id, name: el.name });
      });
    }
    return list;
  };

  const createRolesId = (data) => {
    const ids = [];
    data.forEach(e => ids.push(e.id));
    return ids;
  };

  const handleDeleteChip = (i, whom) => {
    const previousChipsList = [...chipsData[whom]];
    const newChipsList = previousChipsList.slice(0);
    newChipsList.splice(i, 1);
    setChipsData(prevState => ({
      ...prevState,
      [whom]: newChipsList,
    }));
  };

  const handleAddChip = (el, whom) => {
    const previousChipsList = [...chipsData[whom]];
    const newChipsList = [].concat(previousChipsList, el);
    const uniqueChipsList = [
      ...new Map(newChipsList.map((item) => [item['id'], item])).values(),
    ];
    setChipsData(prevState => ({
      ...prevState,
      [whom]: uniqueChipsList,
    }));
  };

  const handleFormattedSuggestions = (data) => {
    const suggestions = [];
    data.forEach(el => {
      suggestions.push({ id: el.id, name: el.name });
    });
    return suggestions;
  };

  const createFormattedRolesList = (arr) => {
    const roleList = [];
    arr.forEach(el => roleList.push({ id: el.id, isChecked: false, name: el.name }));
    return roleList;
  };

  const createOrganizationRoles = useCallback((arr, data) => {
    const checkBoxList = [];
    arr.forEach(el => checkBoxList.push(...data.filter(e => e.id === el.id)));
    return checkBoxList;
  }, []);

  const createCheckBoxList = useCallback((arr, previousArr) => {
    const checkBoxList = [];
    const uniqueCheckBoxList = [];
    arr.forEach(el => checkBoxList.push(
      {
        id: el.id,
        name: el.name,
        list: createFormattedRolesList(el.roles),
      }));
    checkBoxList.forEach(el => uniqueCheckBoxList.push(...previousArr.filter(e => e.id !== el.id)));
    return checkBoxList.filter(item => previousArr.every(el => el.id !== item.id));
  }, []);

  const [rolesCheckedList, setRolesCheckedList] = useState([]);

  useEffect(() => {
    const roleList = createOrganizationRoles(chipsData.userOrganizations, organizationsListData.data);
    setRolesCheckedList(roleList);
  }, [createOrganizationRoles, chipsData.userOrganizations, organizationsListData]);

  const handleSearchOrganization = async (el) => {
    const organizationsData = await api.getEntitiesPaginated(FIRST_PAGE, el);

    if (organizationsData) {
      const previousRolesList = [...rolesCheckedList];
      const data = previousRolesList.concat(createCheckBoxList(organizationsData.data.data, previousRolesList));
      const uniqueDataList = [...new Map(data.map((item) => [item['id'], item])).values()];
      setOrganizationsListData({
        suggestions: handleFormattedSuggestions(organizationsData.data.data),
        data: uniqueDataList,
      });
    }
  };

  const selectedOrganizationIds = (data) => {
    const organizationIds = [];
    data.forEach(el => {
      organizationIds.push(el.id);
    });
    return organizationIds;
  };

  const delayedQuery = _.debounce((q) => handleSearchOrganization(q), 1000);

  const handleUserLogo = (imageBlob) => {
    api.updateUserLogo(imageBlob, userData.id);
    api.userInfo();
  };

  const [modalName, setModalName] = useState('');
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (modalName) => {
    setModalName(modalName);
    setShow(true);
  };
  const handleResetUserPassword = async (id) => {
    await api.resetUserPassword(id);
  };

  const handleChangeInput = (e) => {
    setUserData((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
  };

  return (
    <BackgroundStyled display={'block'}>
      <Header />
      {modalName === 'resetUserPassword' && (
        <Modals
          onHandleClose={handleClose}
          show={show}
          onHandleSuccess={() => handleResetUserPassword(ID)}
          primaryButtonName={'Reset Password'}
          secondaryButtonName={'Cancel'}
          message={'RESET PASSWORD'}
          //Description property
          isDescription={true}
          descriptionText={'After you reset password user will be log out from all nowCM apps.'}
        />
      )}
      <Container>
        <FlexStyled
          direction={'row'}
          align={'flex-start'}
          justify={'center'}
          wrap={'wrap'}
        >
          <MenuNavigationStyled>
            <PageTitleStyled padding={'20px 20px 0'}>Edit User</PageTitleStyled>
            <ul>
              {SIDE_MENU.map(item =>
                <li key={item.label}>
                  <ScrollStyled
                    activeClass={item.activeClass}
                    to={item.to}
                    spy={item.spy}
                    smooth={item.smooth}
                    offset={item.offset}
                    duration={item.duration}
                  >{item.label}
                  </ScrollStyled>
                </li>)}
            </ul>
          </MenuNavigationStyled>
          <WrapperStyled width={'580px'}>
            <Formik
              validateOnChange={false}
              validateOnBlur={false}
              enableReinitialize={true}
              validationSchema={editUserValidation}
              initialValues={{
                name: userData.first_name ? userData.first_name : '',
                email: userData.email ? userData.email : '',
                first_name: userData.first_name ? userData.first_name : '',
                last_name: userData.last_name ? userData.last_name : '',
                phone_number: userData.phone_number ? userData.phone_number : '',
                entities_id: selectedOrganizationIds(chipsData.userOrganizations),
                admin_of_entity_id: chipsData.adminOrganization[0]?.id ? +chipsData.adminOrganization[0]?.id : null,
                roles_id: getSelectedId(rolesCheckedList),
                user_status: { value: `${userData.enabled}-select` }
                  ? { value: `${userData.enabled}-select` }
                  : '',
                is_admin: userData.is_admin,
                is_tester: userData.is_tester,
                is_organization_admin: userData.is_organization_admin,
                user_settings: {
                  theme: userData.theme ? userData.theme : '',
                },
              }}
              onSubmit={(values, { resetForm }) => {
                const user = {
                  name: values.name,
                  email: values.email,
                  first_name: values.first_name,
                  last_name: values.last_name,
                  roles_id: values.roles_id,
                  entities_id: values.entities_id,
                  admin_of_entity_id: values.admin_of_entity_id,
                  phone_number: values.phone_number,
                  is_actual_testing_account: false,
                  is_admin: values.is_admin,
                  is_tester: values.is_tester,
                  is_organization_admin: values.is_organization_admin,
                  user_settings: {
                    theme: 'dark',
                    is_2fa_enabled: false,
                    new_device_login: false,
                  },
                };
                const userStatus = {
                  status: values.user_status.value.slice(0, 1),
                };
                (async () => {
                  await api.patchUserStatus(ID, userStatus);
                  await api.editUser(ID, user, routerPush, resetForm);
                })();
              }}
            >
              {({ handleSubmit, handleChange, setFieldValue, values }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  {adminType || adminOrganizationType ? (
                    <>
                      <FlexStyled
                        direction={'column'}
                        align={'stretch'}
                        wrap={'wrap'}
                      >
                        <CardStyled marginBottom={'15px'}>
                          <CardTitleStyled id='personalInformation'>PERSONAL INFORMATION</CardTitleStyled>
                          <FlexStyled
                            direction={'row'}
                            justify={'space-between'}
                            wrap={'nowrap'}
                          >
                            <FlexStyled
                              paddingLeft={'20px'}
                              paddingRight={'20px'}
                              bottom={'20px'}
                              direction={'column'}
                              justify={'space-between'}
                              wrap={'wrap'}
                            >
                              <GroupStyled top={'13px'} bottom={'13px'}>
                                <LabelStyled bottom={'8px'}>First Name</LabelStyled>
                                <Field
                                  id={'first_name'}
                                  component={InputValidation}
                                  type='text'
                                  width={'256px'}
                                  name={'first_name'}
                                  placeholder={'First Name'}
                                  value={userData?.first_name}
                                  onChange={handleChangeInput}
                                />
                              </GroupStyled>
                              <GroupStyled top={'13px'} bottom={'13px'}>
                                <LabelStyled bottom={'8px'}>Last Name</LabelStyled>
                                <Field
                                  id={'last_name'}
                                  component={InputValidation}
                                  type='text'
                                  width={'256px'}
                                  name={'last_name'}
                                  placeholder={'Last Name'}
                                  value={userData?.last_name}
                                  onChange={handleChangeInput}
                                />
                              </GroupStyled>
                            </FlexStyled>
                            <div style={{ margin: 'auto' }}>
                              <UploadWrapper originalImageSrc={userData.image_url} onHandleImage={handleUserLogo}>
                                <PlusCircleStyled size={'120px'} />
                              </UploadWrapper>
                            </div>
                          </FlexStyled>
                        </CardStyled>
                        <CardStyled marginBottom={'15px'}>
                          <CardTitleStyled id='contactInformation'>CONTACT INFORMATION</CardTitleStyled>
                          <FlexStyled
                            paddingLeft={'20px'}
                            paddingRight={'20px'}
                            bottom={'20px'}
                            direction={'column'}
                            justify={'start'}
                            wrap={'wrap'}
                          >
                            <GroupStyled top={'13px'} bottom={'13px'}>
                              <LabelStyled bottom={'8px'}>Email</LabelStyled>
                              <Field
                                id={'email'}
                                component={InputValidation}
                                type='email'
                                width={'256px'}
                                name={'email'}
                                placeholder={'email'}
                                value={userData?.email}
                                onChange={handleChangeInput}
                              />
                            </GroupStyled>
                            <GroupStyled top={'13px'} bottom={'13px'}>
                              <LabelStyled bottom={'8px'}>Phone</LabelStyled>
                              <Field
                                id={'phone_number'}
                                component={InputValidation}
                                type='tel'
                                width={'256px'}
                                name={'phone_number'}
                                placeholder={'Phone'}
                                value={userData?.phone_number}
                                onChange={handleChangeInput}
                              />
                            </GroupStyled>
                          </FlexStyled>
                        </CardStyled>
                        <CardStyled marginBottom={'15px'}>
                          <CardTitleStyled id='changeUserStatus'>CHANGE USER STATUS</CardTitleStyled>
                          <FlexStyled
                            paddingLeft={'20px'}
                            paddingRight={'20px'}
                            bottom={'20px'}
                            direction={'column'}
                            justify={'start'}
                            wrap={'wrap'}
                            align={'start'}
                          >
                            <GroupStyled top={'13px'} bottom={'10px'} width={'258px'}>
                              <LabelStyled bottom={'8px'}>User status</LabelStyled>
                              <Field
                                id={'user_status'}
                                component={SelectValidation}
                                name={'user_status'}
                                theme={theme}
                                maxMenuHeight={150}
                                value={values.user_status.value}
                                onChange={setFieldValue}
                                options={dataUserStatus}
                                width={'256px'}
                                isDot={false}
                              />
                            </GroupStyled>
                          </FlexStyled>
                        </CardStyled>
                        <CardStyled marginBottom={'15px'}>
                          <CardTitleStyled id='access'>ACCESS</CardTitleStyled>
                          <FlexStyled
                            paddingLeft={'20px'}
                            paddingRight={'20px'}
                            bottom={'0'}
                            direction={'column'}
                            justify={'start'}
                            wrap={'wrap'}
                            align={'top'}
                          >
                            {adminType && (
                              <GroupStyled top={'13px'} bottom={'25px'}>
                                <LabelStyled bottom={'8px'}>User Type</LabelStyled>
                                <RadioButtonList
                                  name={'userTypeData'}
                                  radioButtonSettings={userTypeAdmin}
                                  checkedValue={checkedUserTypeValue}
                                  onChangeValue={handleChangeUserType}
                                />
                              </GroupStyled>
                            )}
                            {userOrganization === null && adminType && (
                              <>
                                {!userData.is_admin && !userData.is_organization_admin && (
                                  <GroupStyled top={'13px'} bottom={'10px'}>
                                    <LabelStyled bottom={'8px'}>Add User Organizations</LabelStyled>
                                    <ChipStyled
                                      tags={chipsData.userOrganizations}
                                      suggestions={organizationsListData.suggestions}
                                      onDelete={el => handleDeleteChip(el, 'userOrganizations')}
                                      onAddition={el => handleAddChip(el, 'userOrganizations')}
                                      onInput={delayedQuery}
                                      placeholderText={'search...'}
                                      width={'256px'}
                                    />
                                  </GroupStyled>
                                )}
                                {Boolean(userData.is_organization_admin) && (
                                  <>
                                    <GroupStyled top={'13px'} bottom={'10px'}>
                                      <LabelStyled bottom={'8px'}>Admin of organization</LabelStyled>
                                      <ChipStyled
                                        tags={chipsData.adminOrganization}
                                        suggestions={organizationsListData.suggestions}
                                        onDelete={el => handleDeleteChip(el, 'adminOrganization')}
                                        onAddition={el => handleAddChip(el, 'adminOrganization')}
                                        onInput={delayedQuery}
                                        placeholderText={'search...'}
                                        display={chipsData.adminOrganization.length > 0 ? 'none' : 'block'}
                                        width={'256px'}
                                      />
                                    </GroupStyled>
                                    <GroupStyled top={'13px'} bottom={'10px'}>
                                      <LabelStyled bottom={'8px'}>Add User Organizations</LabelStyled>
                                      <ChipStyled
                                        tags={chipsData.userOrganizations}
                                        suggestions={organizationsListData.suggestions}
                                        onDelete={el => handleDeleteChip(el, 'userOrganizations')}
                                        onAddition={el => handleAddChip(el, 'userOrganizations')}
                                        onInput={delayedQuery}
                                        placeholderText={'search...'}
                                        width={'256px'}
                                      />
                                    </GroupStyled>
                                  </>
                                )}
                              </>
                            )}
                          </FlexStyled>
                          <FlexStyled
                            paddingLeft={'20px'}
                            paddingRight={'20px'}
                            bottom={'20px'}
                            direction={'row'}
                            justify={'start'}
                            wrap={'wrap'}
                            align={'top'}
                          >
                            <GroupStyled top={'0'} bottom={'10px'}>
                              {rolesCheckedList.length !== 0 && rolesCheckedList.map(el => {
                                  if (userOrganization === null && adminType) {
                                    return (
                                      <div key={el.id}>
                                        <LabelStyled bottom={'8px'}>{el.name} Organization Roles</LabelStyled>
                                        <CheckBoxList
                                          data={el.list}
                                          onCheckboxChange={handleCheckboxChange}
                                        />
                                      </div>
                                    );
                                  } else {
                                    return el.id === userOrganization && (
                                      <div key={el.id}>
                                        <LabelStyled bottom={'8px'}>{el.name} Organization Roles</LabelStyled>
                                        <CheckBoxList
                                          data={el.list}
                                          onCheckboxChange={handleCheckboxChange}
                                        />
                                      </div>
                                    );
                                  }
                                },
                              )}
                            </GroupStyled>
                          </FlexStyled>
                        </CardStyled>
                      </FlexStyled>
                      <BtnWrapperStyled>
                        <FlexStyled direction={'row'} justify={'space-between'} top={'10px'}>
                          <FlexStyled direction={'row'} justify={'flex-start'}>
                            <PermissionsGate
                              permissionGranted={{ name: PERMISSIONS.copa_access_to_button_reset_password }}>
                              <ButtonSecondary
                                type='button'
                                onClick={() => handleShow('resetUserPassword')}
                                buttonName='Reset Password'
                              />
                            </PermissionsGate>
                          </FlexStyled>
                          <FlexStyled direction={'row'} justify={'flex-end'}>
                            <ButtonPrimary type='submit' buttonName='Update' />
                            <Link to={ROUTES.userManagement}>
                              <ButtonSecondary buttonName='Cancel' />
                            </Link>
                          </FlexStyled>
                        </FlexStyled>
                      </BtnWrapperStyled>
                    </>
                  ) : <h1>You should have user type Admin or Organization Admin</h1>}
                </Form>
              )}
            </Formik>
          </WrapperStyled>
        </FlexStyled>
      </Container>
    </BackgroundStyled>
  );
};
