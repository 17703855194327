import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin-top: 14px;
  margin-bottom: 20px;
`;

export const CheckboxName = styled.div`
  color: ${({ theme }) => theme.checkboxNameColor};
  font-size: 14px;
  padding-left: 8px;
`;

export const CheckboxItemName = styled.div`
  color: ${({ theme }) => theme.checkboxItemNameColor};
  font-size: 12px;
  padding-left: 8px;
`;

export const CheckboxListHeader = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 14px;
  border-bottom: ${({ theme }) => `1px solid ${theme.CheckboxListHeaderBorderColor}`};
  margin-bottom: 14px;
`;

export const CheckboxItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 14px;
`;
