import styled from 'styled-components';

export const TabBtn = styled.div`
  margin-bottom: 25px;
  width: 100%;
`;

export const BtnStyled = styled.div`
  color: ${({ theme }) => theme.btnTabColor};
  background: ${({ theme }) => theme.btnTabBackgroundColor};
  margin-top: ${(props) => props.top || '10px'};
  margin-bottom: ${(props) => props.bottom || '10px'};
  margin-left: ${(props) => props.left || '0px'};
  margin-right: ${(props) => props.right || '20px'};
  font-size: ${(props) => props.fontSize || '12px'};
  border: none;
  border-bottom: ${({ theme }) => `2px solid ${theme.btnTabBorderBottomColor}`};
  display: inline-block;
  min-height: 24px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: center;
  text-decoration: none;
  border-radius: 2px;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  &:hover {
    color: ${({ theme }) => theme.btnTabHoverColor};
    border-bottom: ${({ theme }) => `2px solid ${theme.btnTabBorderBottomHoverColor}`};
  }
  cursor: pointer;
`;

export const BtnActiveStyled = styled.div`
  color: ${({ theme }) => theme.btnTabActiveColor};
  background: ${({ theme }) => theme.btnTabBackgroundActiveColor};
  margin-top: ${(props) => props.top || '10px'};
  margin-bottom: ${(props) => props.bottom || '10px'};
  margin-left: ${(props) => props.left || '0px'};
  margin-right: ${(props) => props.right || '20px'};
  font-size: ${(props) => props.fontSize || '12px'};
  border: none;
  border-bottom: ${({ theme }) => `2px solid ${theme.btnTabBorderBottomActiveColor}`};
  display: inline-block;
  min-height: 24px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: center;
  text-decoration: none;
  border-radius: 2px;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  &:hover {
    color: ${({ theme }) => theme.btnTabHoverActiveColor};
    border-bottom: ${({ theme }) => `2px solid ${theme.btnTabBorderBottomHoverActiveColor}`};
  }
  cursor: pointer;
`;
