import styled from 'styled-components';

export const InputContainer = styled.div`
  position: relative;
`;

export const IconButton = styled.div`
  position: absolute;
  right: 5px;
  top: 5px;
`;

export const Container = styled.div`
  position: relative;
  border: 1px solid transparent;
`;

export const ErrorMessage = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.errorMessageValidation};
  position: absolute;
  font-size: 12px;
  text-align: left;
`;
