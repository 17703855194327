import React from 'react';
import { CheckboxStyled } from '../CheckboxStyled/CheckboxStyled';
import { Container, CheckboxItemName, CheckboxItemWrapper } from './StyledComponents';

export const CheckBoxList = (props) => {
  const { data, onCheckboxChange, width } = props;

  const renderList = () => {
    return data?.map((item) => {
      return (
        <CheckboxItemWrapper key={item.id}>
          <CheckboxStyled
            key={item.id}
            type='checkbox'
            name={item.name}
            value={item.name}
            checked={item.isChecked}
            onChange={(e) => onCheckboxChange(e, item.id)}
          />
          <CheckboxItemName>{item.name}</CheckboxItemName>
        </CheckboxItemWrapper>
      );
    });
  };

  return <Container width={width}>{renderList()}</Container>;
};
