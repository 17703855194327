import styled, { keyframes } from 'styled-components';
import { Modal } from 'react-bootstrap';

const animation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const ModalStyled = styled(Modal)`
  animation: ${animation} 0.3s ease-in-out;
  border: 0px;
  color: ${({ theme }) => theme.modalTextColor};
`;

export const ModalBodyStyled = styled(Modal.Body)`
  background-color: ${({ theme }) => theme.modalBodyColor};
  position: absolute;
  top: -3px;
  left: -2px;
  width: 101%;
  border-radius: 3px;
  padding: 0px;
`;

export const ModalWapperStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ModalTextStyled = styled.span`
  padding: 15px 15px;
  border-bottom: ${({ theme }) => `1px solid ${theme.ModalTextBorderBottom}`};
  margin-bottom: 5px;
`;

export const ModalActionWrapperStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 10px 10px;
`;

export const SelectWrapper = styled.div`
  width: 280px;
  margin: ${(props) => props.margin || '0 auto'};
  padding: 0 20px;
`;

export const LabelStyled = styled.h1`
  margin: 10px 0;
  font-size: 12px;
`;

export const DescriptionStyled = styled.h2`
  width: 280px;
  padding: 10px 0;
  margin: 0 auto;
  font-size: 12px;
`;

