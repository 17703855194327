import React from 'react';
import styled from 'styled-components';
import { Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';


const SpinnerTemplate = styled(Spinner)`
  color: ${({ theme }) => theme.spinnerColor};
`;

const SpinnerBodyStyled = styled.div`
  height: 100vh;
  position: fixed;
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  z-index: 90;
  background-color: ${({ theme }) => theme.spinnerBackgroundColor};
`;

const Loader = () => {
  return (
    <SpinnerBodyStyled>
      <SpinnerTemplate animation='border' />
    </SpinnerBodyStyled>
  );
};

export const SpinnerStyled = () => {
  const isShown = useSelector((state) => state.spinner.isLoading);

  return (
    isShown ? <Loader /> : null
  );
};
