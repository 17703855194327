import styled from 'styled-components';
import { Modal } from 'react-bootstrap';

export const Wrapper = styled.div`
  cursor: pointer;
`;

export const CropperContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px solid ${({ theme }) => theme.CropperContainerBorderColor};
  min-height: 440px;

  .cropper-view-box, .cropper-face {
    outline-style: none;
  }

  .cropper-view-box, .cropper-face {
    border-radius: 50%;
  }
`;

export const ModalStyled = styled(Modal)`
  .modal-content {
    border: none;
  }

  .cropperContainer {
    min-height: 440px;
  }
`;

export const ModalDialogStyled = styled(Modal.Dialog)`
  max-width: 680px;
`;

export const ModalBodyStyled = styled(Modal.Body)`
  background-color: ${({ theme }) => theme.modalBodyBackgroundColor};
  color: ${({ theme }) => theme.modalColor};
  border: 0px;
  border-radius: 0px;
  padding: 24px;
  text-align: center;
`;

export const ModalHeaderStyled = styled(Modal.Header)`
  background-color: ${({ theme }) => theme.modalHeaderBackgroundColor};
  color: ${({ theme }) => theme.modalHeaderColor};
  border: 0px;
  border-radius: 0px;
  border-bottom: 1px solid #4A4A52;
`;

export const ModalFooterStyled = styled(Modal.Footer)`
  background-color: ${({ theme }) => theme.modalFooterBackgroundColor};
  color: #fff;
  border: 0px;
  border-radius: 0px;
`;

export const ModalTitleStyled = styled(Modal.Title)`
  color: ${({ theme }) => theme.modalColor};
  font-size: 12px;
  text-transform: uppercase;
  margin-left: 12px;
  margin-top: 8px;
  margin-bottom: 7px;
`;

export const DescriptionStyled = styled.div`
  padding-top: 20px;
`;

export const CropperSlider = styled.input`
  -webkit-appearance: none;
  margin-right: 15px;
  margin-left: 15px;
  width: 240px;
  height: 4px;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.CropperSliderBackgroundColor};

  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.CropperSliderThumbBorderColor};
    background: ${({ theme }) => theme.CropperSliderThumbBackgroundColor};
    cursor: ew-resize;
    box-shadow: none;
  }

  ::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: ${({ theme }) => theme.CropperSliderTrackBackgroundColor};
  }
`;

export const UserLogoStyled = styled.img`
  border-radius: 50%;
  width: 120px;
  height: 120px;
`;