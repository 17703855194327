const initialState = {
  success: true,
  isShown: 'hidden',
  text: '',
};

export const toast = (state = initialState, action) => {
  switch (action.type) {
    case 'SHOW_TOAST':
      return {
        ...state,
        success: action.payload.success,
        isShown: action.payload.isShown,
        text: action.payload.text,
      };
    default:
      return state;
  }
};
