import React from 'react';
import PropTypes from 'prop-types';
import ReactTags from 'react-tag-autocomplete';
import { ReactTagsWrapper } from './StyledComponents';

export const ChipStyled = (props) => {
  const { tags, suggestions, onDelete, onAddition, onInput, placeholderText, display, width } = props;
  return (
    <ReactTagsWrapper
      display={display}
      width={width}
    >
      <ReactTags
        tags={tags}
        suggestions={suggestions}
        onDelete={onDelete}
        onAddition={onAddition}
        onInput={onInput}
        placeholderText={placeholderText}
      />
    </ReactTagsWrapper>
  );
};

ChipStyled.propTypes = {
  tags: PropTypes.array,
  suggestions: PropTypes.array,
  handleDelete: PropTypes.func,
  handleAddition: PropTypes.func,
  placeholderText: PropTypes.string,
  width: PropTypes.string,
};