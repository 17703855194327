export const SIZE = {
  // mobileS: '320px',
  // mobileM: '375px',
  mobileL: '552',
  tabletS: '785px',
  tablet: '1066px',
  // laptop: '1200px',
  // laptopL: '1440px',
  // desktop: '2560px',
};
