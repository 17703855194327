import styled from 'styled-components';
import { ErrorCircle } from '@styled-icons/boxicons-regular/ErrorCircle';
import { ArrowDownUp } from '@styled-icons/bootstrap/ArrowDownUp';
import { ArrowUp } from '@styled-icons/bootstrap/ArrowUp';
import { ArrowDown } from '@styled-icons/bootstrap/ArrowDown';
import { Search } from '@styled-icons/fluentui-system-regular/Search';
import { Trash } from '@styled-icons/bootstrap/Trash';
import { Edit } from '@styled-icons/boxicons-solid/Edit';
import { UserCircle } from '@styled-icons/boxicons-solid/UserCircle';
import { Image } from '@styled-icons/remix-fill/Image';
import { ChevronLeft } from '@styled-icons/bootstrap/ChevronLeft';
import { ChevronRight } from '@styled-icons/bootstrap/ChevronRight';
import { EyeSlash } from '@styled-icons/bootstrap/EyeSlash';
import { Eye } from '@styled-icons/bootstrap/Eye';
import { ArrowCounterclockwise } from '@styled-icons/bootstrap/ArrowCounterclockwise';
import { Organization } from '@styled-icons/octicons/Organization';
import { Plus } from '@styled-icons/boxicons-regular/Plus';
import { Minus } from '@styled-icons/boxicons-regular/Minus';
import { Close } from '@styled-icons/evaicons-solid/Close';
import { PlusCircle } from '@styled-icons/feather/PlusCircle';
import { Duplicate } from '@styled-icons/ionicons-outline/Duplicate';

export const PlusStyled = styled(Plus)`
  color: ${({ theme }) => theme.plusColor};
  cursor: pointer;
  margin-top: ${(props) => props.top || '0px'};
  margin-bottom: ${(props) => props.bottom || '0px'};
  margin-left: ${(props) => props.left || '0px'};
  margin-right: ${(props) => props.right || '0px'};
`;

export const PlusCircleStyled = styled(PlusCircle)`
  color: ${({ theme }) => theme.plusCircleColor};
  cursor: pointer;
  margin-top: ${(props) => props.top || '0px'};
  margin-bottom: ${(props) => props.bottom || '0px'};
  margin-left: ${(props) => props.left || '0px'};
  margin-right: ${(props) => props.right || '0px'};
`;

export const MinusStyled = styled(Minus)`
  color: ${({ theme }) => theme.minusColor};
  cursor: pointer;
  margin-top: ${(props) => props.top || '0px'};
  margin-bottom: ${(props) => props.bottom || '0px'};
  margin-left: ${(props) => props.left || '0px'};
  margin-right: ${(props) => props.right || '0px'};
`;

export const CloseStyled = styled(Close)`
  color: ${({ theme }) => theme.closeColor};
  cursor: pointer;
  margin-top: ${(props) => props.top || '0px'};
  margin-bottom: ${(props) => props.bottom || '0px'};
  margin-left: ${(props) => props.left || '0px'};
  margin-right: ${(props) => props.right || '0px'};
`;


export const HideIconStyled = styled(EyeSlash)`
  color: ${({ theme }) => theme.hideIconIconColor};
  cursor: pointer;
  margin-top: ${(props) => props.top || '0px'};
  margin-bottom: ${(props) => props.bottom || '0px'};
  margin-left: ${(props) => props.left || '0px'};
  margin-right: ${(props) => props.right || '0px'};
`;

export const EyeIconStyled = styled(Eye)`
  color: ${({ theme }) => theme.eyeIconColor};
  cursor: pointer;
  margin-top: ${(props) => props.top || '0px'};
  margin-bottom: ${(props) => props.bottom || '0px'};
  margin-left: ${(props) => props.left || '0px'};
  margin-right: ${(props) => props.right || '0px'};
`;

export const IconErrorStyled = styled(ErrorCircle)`
  color: ${({ theme }) => theme.iconErrorColor};
  position: ${(props) => props.position || 'static'};
  top: ${(props) => props.top || '0px'};
  right: ${(props) => props.right || '0px'};
`;

export const ArrowDownUpIconStyled = styled(ArrowDownUp)`
  color: ${({ theme }) => theme.arrowDownUpIconColor};
  position: ${(props) => props.position || 'static'};
  margin-top: ${(props) => props.top || '0px'};
  margin-bottom: ${(props) => props.bottom || '0px'};
  margin-left: ${(props) => props.left || '0px'};
  margin-right: ${(props) => props.right || '0px'};
`;

export const ArrowUpIconStyled = styled(ArrowUp)`
  color: ${({ theme }) => theme.arrowUpIconColor};
  position: ${(props) => props.position || 'static'};
  margin-top: ${(props) => props.top || '0px'};
  margin-bottom: ${(props) => props.bottom || '0px'};
  margin-left: ${(props) => props.left || '0px'};
  margin-right: ${(props) => props.right || '0px'};
`;

export const ArrowDownIconStyled = styled(ArrowDown)`
  color: ${({ theme }) => theme.arrowDownIconColor};
  position: ${(props) => props.position || 'static'};
  margin-top: ${(props) => props.top || '0px'};
  margin-bottom: ${(props) => props.bottom || '0px'};
  margin-left: ${(props) => props.left || '0px'};
  margin-right: ${(props) => props.right || '0px'};
`;

export const SearchStyled = styled(Search)`
  color: ${({ theme }) => theme.searchIconColor};
  position: ${(props) => props.position || 'static'};
  top: ${(props) => props.top || '0px'};
  left: ${(props) => props.left || '0px'};
`;

export const TrashIconStyled = styled(Trash)`
  color: ${({ theme }) => theme.trashIconColor};
  cursor: pointer;
  margin-top: ${(props) => props.top || '0px'};
  margin-bottom: ${(props) => props.bottom || '0px'};
  margin-left: ${(props) => props.left || '0px'};
  margin-right: ${(props) => props.right || '0px'};
`;

export const ArrowCounterclockwiseStyled = styled(ArrowCounterclockwise)`
  color: ${({ theme }) => theme.arrowCounterclockwiseColor};
  cursor: pointer;
  margin-top: ${(props) => props.top || '0px'};
  margin-bottom: ${(props) => props.bottom || '0px'};
  margin-left: ${(props) => props.left || '0px'};
  margin-right: ${(props) => props.right || '0px'};
`;
export const EditIconStyled = styled(Edit)`
  color: ${({ theme }) => theme.editIconStyled};
  cursor: pointer;
  margin-top: ${(props) => props.top || '0px'};
  margin-bottom: ${(props) => props.bottom || '0px'};
  margin-left: ${(props) => props.left || '0px'};
  margin-right: ${(props) => props.right || '0px'};
`;

export const UserCircleStyled = styled(UserCircle)`
  color: ${({ theme }) => theme.userCircleIconColor};
  position: ${(props) => props.position || 'static'};
  margin-top: ${(props) => props.top || '0px'};
  margin-bottom: ${(props) => props.bottom || '0px'};
  margin-left: ${(props) => props.left || '0px'};
  margin-right: ${(props) => props.right || '0px'};
`;

export const ImageStyled = styled(Image)`
  color: ${({ theme }) => theme.imageIconColor};
  position: ${(props) => props.position || 'static'};
  margin-top: ${(props) => props.top || '0px'};
  margin-bottom: ${(props) => props.bottom || '0px'};
  margin-left: ${(props) => props.left || '0px'};
  margin-right: ${(props) => props.right || '0px'};
`;
export const ChevronLeftStyled = styled(ChevronLeft)`
  color: ${({ theme }) => theme.imageIconColor};
  position: ${(props) => props.position || 'static'};
  margin-top: ${(props) => props.top || '0px'};
  margin-bottom: ${(props) => props.bottom || '0px'};
  margin-left: ${(props) => props.left || '0px'};
  margin-right: ${(props) => props.right || '0px'};
`;
export const ChevronRightStyled = styled(ChevronRight)`
  color: ${({ theme }) => theme.imageIconColor};
  position: ${(props) => props.position || 'static'};
  margin-top: ${(props) => props.top || '0px'};
  margin-bottom: ${(props) => props.bottom || '0px'};
  margin-left: ${(props) => props.left || '0px'};
  margin-right: ${(props) => props.right || '0px'};
`;

export const OrganizationStyled = styled(Organization)`
  color: ${({ theme }) => theme.imageIconColor};
  position: ${(props) => props.position || 'static'};
  margin-top: ${(props) => props.top || '0px'};
  margin-bottom: ${(props) => props.bottom || '0px'};
  margin-left: ${(props) => props.left || '0px'};
  margin-right: ${(props) => props.right || '0px'};
`;

export const DuplicateIconStyled = styled(Duplicate)`
  color: ${({ theme }) => theme.imageIconColor};
  position: ${(props) => props.position || 'static'};
  margin-top: ${(props) => props.top || '0px'};
  margin-bottom: ${(props) => props.bottom || '0px'};
  margin-left: ${(props) => props.left || '0px'};
  margin-right: ${(props) => props.right || '0px'};
  cursor: pointer;
`;
