import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
  margin-top: ${(props) => props.top || '18px'};
  margin-bottom: ${(props) => props.bottom || '18px'};
  margin-left: ${(props) => props.left || '0px'};
  margin-right: ${(props) => props.right || '0px'};
  width: ${(props) => props.width || '100%'};
`;

export const GroupStyled = (props) => {
  return <Container {...props} />;
};

GroupStyled.propTypes = {
  top: PropTypes.string,
  bottom: PropTypes.string,
  left: PropTypes.string,
  right: PropTypes.string,
};
