import styled from 'styled-components';
import { SIZE } from '../../../constants/size';

export const TableContainer = styled.div`
  max-width: 1300px;
  margin: 0 auto;
  padding-top: 109px;
  @media (max-width: ${SIZE.tablet}) {
    padding-top: 85px;
  }
`;

export const TableNav = styled.div`
  padding: 0 20px;
  background-color: ${({ theme }) => theme.tableNavBackgroundColor};
  height: 63px;
  width: 100%;
  z-index: 100;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const TableNavLeftPart = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TableNavRightPart = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Styles = styled.div`
padding: 0px 20px;
overflow-y: scroll;
overflow-x: hidden;
background-color: ${({ theme }) => theme.tableBackgroundColor};
height: 65vh;
  table {
    color: ${({ theme }) => theme.tableTextColor};
    width: 100% ;
    border-collapse: collapse;
    margin-top: 0px;
  }
  table th {        
    border-bottom: ${({ theme }) => `1px solid ${theme.tableBorderBottomColor}`};
    background-color: ${({ theme }) => theme.tableHeadBackgroundColor};   
    color: ${({ theme }) => theme.tableHeadTextColor};
    position: sticky;
    top: 0px;
    padding: 15px 0px;
    text-align: left;
    font-size: 12px;
    z-index: 2;
    &:first-child {
      width: 28px;
    }
  }
  table td {
    padding: 0px;
    height:50px;
    // border-bottom: ${({ theme }) => `1px solid ${theme.tableBorderBottomColor}`};
    text-align: left;
    font-size: 12px;
  }
  table tr{
  background-color: ${({ theme }) => theme.tableRowBackgroundColor}; 
  }
  table tr:hover {
    background-color:${({ theme }) => theme.tableRowBackgroundHoverColor};
  }
  }
  @media (max-width:${SIZE.tablet}) {
    table tbody tr:nth-child(even){
      background-color:${({ theme }) => theme.tableRowBackgroundHoverColor};
    }
    table tr:hover {
      background-color: ${({ theme }) => theme.tableRowBackgroundColor}; 
    }
    table thead {
      display: none;
    }
    table,
    table tbody,
    table tr,
    table td {
      display: block;
      width: 100%;
    }
    table tr {
      margin-bottom: 15px;
      padding: 0px 20px;
    }
    table td {
      text-align: right;
      padding-left: 50%;
      text-align: right;
      position: relative;
    }
    table td::before {
      content: attr(data-label);
      font-size: 12px;
      position: absolute;
      left: 0;
      width: 50%;
      padding-left: 15px;
      text-align: left;
      display: flex;
      flex-direction: row;
      align-items: center;
      border-bottom: ${({ theme }) => `1px solid ${theme.tableBorderBottomBeforeColor}`};
      height: 50px;
    }
  }
`;
