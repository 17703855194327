import * as authStatus from '../../../utils/auth';
import { isString } from 'lodash';

export const handleResponseError = (axiosInstance) => {
  let newAxiosInstance = axiosInstance;

  newAxiosInstance.interceptors.response.use(
    (response) => {

      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        authStatus.setLoggedOut();
      }

      if (error.response.status === 400) {
        if (error.response.data) {
          if (isString(error.response.data)) {
            error.message = error.response.data;
          } else {
            error.message = error.response.data[Object.keys(error.response.data)[0]];
          }
        }
      }

      return Promise.reject(error);
    },
  );

  return newAxiosInstance;
};