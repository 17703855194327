import axios from 'axios';

export class AxiosFactory {

  constructor() {
    this.instance = axios.create({ withCredentials: true });
  }

  getInstance() {
    return this.instance ?? axios.create({ withCredentials: true });
  }

  /**
   * @param {function} interceptor
   * @return {AxiosFactory}
   */
  addInterceptor(interceptor) {
    this.instance = interceptor(this.instance);

    return this;
  }
}