import { IndeterminateCheckbox } from '../../IndeterminateCheckbox';
import {
  EditIconStyled,
  TrashIconStyled,
  UserCircleStyled,
  ImageStyled,
  ArrowCounterclockwiseStyled,
} from '../../../../utils/iconsStyled';
import { Link } from 'react-router-dom';
import {
  PillStyled,
  PillMoreStyled,
  TooltipStyled,
  StatusLightBulbActive,
  StatusLightBulbInactive,
  StatusLightBulbDisabled,
} from '../../StyledComponents';
import { Styles, RowStyled, CellStyled } from './StyledComponents';

export const getColumnsForUserManagement = (
  handleShow,
  handleSelectUser,
  handleSelectAllUsers,
  handleRestoreUser,
  filterStatus,
  selectedUsers,
) => {
  return [
    // Let's make a column for selection
    {
      name: 'Checkbox',
      id: 'selection',
      // The header can use the table's getToggleAllRowsSelectedProps method
      // to render a checkbox
      Header: ({ getToggleAllRowsSelectedProps }) => {
        // const userId = getToggleAllRowsSelectedProps;
        return filterStatus.value !== 'disabled' ? (
          <IndeterminateCheckbox
            {...getToggleAllRowsSelectedProps()}
            onClick={() => handleSelectAllUsers()}
          />
        ) : (
          ''
        );
      },
      // The cell can use the individual row's getToggleRowSelectedProps method
      // to the render a checkbox

      Cell: ({ row }) => {
        const userId = row.original.id;
        return filterStatus.value !== 'disabled' ? (
          <Styles>
            <RowStyled>
              <CellStyled>
                <IndeterminateCheckbox
                  {...row.getToggleRowSelectedProps()}
                  onClick={() => handleSelectUser(userId)}
                />
              </CellStyled>
            </RowStyled>
          </Styles>
        ) : (
          ''
        );
      },
    },
    {
      Header: 'ID',
      name: 'Id',
      accessor: 'id',
      // disableSortBy: true,
      Cell: ({ cell }) => {
        const id = cell.row.original.id;
        return (
          <Styles>
            <RowStyled>
              <CellStyled>
                <span>{id}</span>
              </CellStyled>
            </RowStyled>
          </Styles>
        );
      },
    },
    {
      Header: 'User Name',
      name: 'User Name',
      accessor: 'userName',
      Cell: ({ cell }) => {
        const firstName = cell.row.original.first_name;
        const lastName = cell.row.original.last_name;
        const userLogo = cell.row.original.image_url;
        return (
          <Styles>
            <RowStyled>
              <CellStyled>
                {
                  userLogo
                    ? <img src={userLogo} alt="logo" />
                    : <UserCircleStyled size={'26px'} />
                }
                <h3>{firstName}</h3>
                <h4>{lastName}</h4>
              </CellStyled>
            </RowStyled>
          </Styles>
        );
      },
    },
    {
      Header: 'Status',
      name: 'Status',
      accessor: 'status',
      Cell: ({ cell }) => {
        const status = cell.row.original.enabled;
        const deleteItem = cell.row.original.deleted_at;

        return (
          <Styles>
            <RowStyled>
              <CellStyled>
                {deleteItem !== null && <StatusLightBulbDisabled />}
                {deleteItem !== null && <h4>Disabled</h4>}
                {status === 1 && deleteItem === null && <StatusLightBulbActive />}
                {status === 0 && deleteItem === null && <StatusLightBulbInactive />}
                {status === 1 && deleteItem === null && <h4>Active</h4>}
                {status === 0 && deleteItem === null && <h4>Inactive</h4>}
              </CellStyled>
            </RowStyled>
          </Styles>
        );
      },
    },
    {
      Header: 'Email',
      name: 'Email',
      accessor: 'email',
      Cell: ({ cell }) => {
        const email = cell.row.original.email;
        return (
          <Styles>
            <RowStyled>
              <CellStyled>
                <span>{email}</span>
              </CellStyled>
            </RowStyled>
          </Styles>
        );
      },
    },
    {
      Header: 'Phone',
      name: 'Phone',
      accessor: 'phone_number',
      Cell: ({ cell }) => {
        const phoneNumber = cell.row.original.phone_number;
        return (
          <Styles>
            <RowStyled>
              <CellStyled>
                <span>{phoneNumber}</span>
              </CellStyled>
            </RowStyled>
          </Styles>
        );
      },
    },
    {
      Header: 'Organizations',
      name: 'Organizations',
      accessor: 'organizations',
      Cell: ({ cell }) => {
        const entity = cell.row.original.entity;
        if (entity?.length > 1) {
          const shortList = entity.slice(0, 1);
          const hideViewItemsArr = entity.slice(1);
          return (
            <Styles>
              <RowStyled>
                <CellStyled>
                  <ImageStyled size={'26px'} />
                  {shortList.map((el, i) => (
                    <PillStyled right={'7px'} key={i}>
                      {el.name}
                    </PillStyled>
                  ))}
                  <TooltipStyled>
                    <div>
                      <span>
                        {hideViewItemsArr.map((item, i) => (
                          <PillStyled key={i}>{item.name}</PillStyled>
                        ))}
                      </span>
                      <PillMoreStyled right={'7px'}>{`+${shortList?.length}`}</PillMoreStyled>
                    </div>
                  </TooltipStyled>
                </CellStyled>
              </RowStyled>
            </Styles>
          );
        } else {
          return (
            <Styles>
              <RowStyled>
                <CellStyled>
                  <ImageStyled size={'26px'} />
                  {entity?.map((item, i) => (
                    <PillStyled right={'7px'} key={i}>
                      {item.name}
                    </PillStyled>
                  ))}
                </CellStyled>
              </RowStyled>
            </Styles>
          );
        }
      },
    },
    {
      Header: 'Role',
      name: 'Role',
      accessor: 'user_role',
      Cell: ({ cell }) => {
        const userRoles = cell.row.original.roles;
        if (userRoles?.length > 1) {
          const shortListRoles = userRoles.slice(0, 1);
          const hideViewItemsArr = userRoles.slice(1);
          return (
            <Styles>
              <RowStyled>
                <CellStyled>
                  {shortListRoles.map((role, i) => (
                    <PillStyled right={'7px'} key={i}>
                      {role.name}
                    </PillStyled>
                  ))}
                  <TooltipStyled>
                    <div>
                      <span>
                        {hideViewItemsArr.map((item, i) => (
                          <PillStyled key={i}>{item.name}</PillStyled>
                        ))}
                      </span>
                      <PillMoreStyled right={'7px'}>{`+${userRoles?.length - 1}`}</PillMoreStyled>
                    </div>
                  </TooltipStyled>
                </CellStyled>
              </RowStyled>
            </Styles>
          );
        } else {
          return (
            <Styles>
              <RowStyled>
                <CellStyled>
                  {userRoles?.map((role, i) => (
                    <PillStyled right={'7px'} key={i}>
                      {role.name}
                    </PillStyled>
                  ))}
                </CellStyled>
              </RowStyled>
            </Styles>
          );
        }
      },
    },
    {
      Header: 'Apps',
      name: 'Apps',
      accessor: 'Apps',
      Cell: ({ cell }) => {
        const apps = cell.row.original.applications;
        if (apps?.length > 1) {
          const shortListApps = apps.slice(0, 1);
          const hideViewItemsArr = apps.slice(1);
          return (
            <Styles>
              <RowStyled>
                <CellStyled>
                  {shortListApps.map((shortListApps, i) => (
                    <PillStyled right={'7px'} key={i}>
                      {shortListApps.name}
                    </PillStyled>
                  ))}
                  <TooltipStyled>
                    <div>
                      <span>
                        {hideViewItemsArr.map((item, i) => (
                          <PillStyled key={i}>{item.name}</PillStyled>
                        ))}
                      </span>
                      <PillMoreStyled right={'7px'}>{`+${apps?.length - 1}`}</PillMoreStyled>
                    </div>
                  </TooltipStyled>
                </CellStyled>
              </RowStyled>
            </Styles>
          );
        } else {
          return (
            <Styles>
              <RowStyled>
                <CellStyled>
                  {apps?.map((apps, i) => (
                    <PillStyled right={'7px'} key={i}>
                      {apps.name}
                    </PillStyled>
                  ))}
                </CellStyled>
              </RowStyled>
            </Styles>
          );
        }
      },
    },
    {
      Header: '',
      name: '',
      id: 'button',
      Cell: ({ cell }) => {
        const userId = cell.row.original.id;
        return (
          <RowStyled>
            <CellStyled justify={'flex-end'}>
              {filterStatus.value !== 'disabled' ? (
                <div>
                  <Link to={`/usermanagement/editUser${userId}`}>
                    <EditIconStyled data-id={userId} size={20} right={'20px'} />
                  </Link>
                  {selectedUsers.length === 0 && (
                    <TrashIconStyled
                      data-id={userId}
                      size={20}
                      onClick={() => handleShow(userId, 'disable')}
                    />
                  )}
                </div>
              ) : (
                <ArrowCounterclockwiseStyled
                  data-id={userId}
                  size={22}
                  onClick={() => handleShow(userId, 'restore')}
                />
              )}
            </CellStyled>
          </RowStyled>
        );
      },
    },
  ];
};
