import { TableNav, TableNavLeftPart, TableNavRightPart } from '../StyledComponents';
import PropTypes from 'prop-types';
import { InputContainer, Wrapper } from '../../StyledComponents';
import { Link } from 'react-router-dom';
import { ButtonPrimary } from '../../../ButtonPrimary/ButtonPrimary';
import { ButtonSecondary } from '../../../ButtonSecondary/ButtonSecondary';
import { ButtonDisabled } from '../../../ButtonDisabled/ButtonDisabled';
import { InputStyled } from '../../../InputStyled/InputStyled';
import { SearchStyled } from '../../../../utils/iconsStyled';
import { SelectStyled } from '../../../SelectStyled/SelectStyled';
import _ from 'lodash';
import { ButtonPrimaryFileUpload } from '../../../ButtonPrimaryFileUpload/ButtonPrimaryFileUpload'

export const UserManagementHeader = (props) => {
  const {
    onHandleChangeNameSearch,
    selectedUsers,
    onHandleShow,
    theme,
    filterStatus,
    onHandleSelectedFilterStatus,
    onHandleCsvImport,
    onHandleCsvExample
  } = props;

  const delayedQuery = _.debounce((q) => onHandleChangeNameSearch(q), 1000);
  const onSearchQueryChange = (e) =>
    e.target.value.length > 3 ? delayedQuery(e.target.value) : undefined;
  const onKeyDownSearchQuery = (e) =>
    e.target.value.length < 4 && e.key === 'Enter'
      ? onHandleChangeNameSearch(e.target.value)
      : undefined;
  const dataUserStatus = [
    { value: 'active', label: 'Active' },
    { value: 'inactive', label: 'Inactive' },
    { value: 'disabled', label: 'Disabled' },
  ];

  return (
    <TableNav>
      <TableNavLeftPart>
        <Link to={'/usermanagement/addUser'}>
          <ButtonPrimary buttonName='Add User' />
        </Link>
        {selectedUsers.length !== 0 && filterStatus.value === 'active' ? (
          <ButtonSecondary
            buttonName='Disable Users'
            onClick={() => onHandleShow(null, 'disableAll')}
          />
        ) : (
          <ButtonDisabled buttonName='Disable Users' />
        )}
        {selectedUsers.length !== 0 && filterStatus.value === 'inactive' ? (
          <ButtonSecondary
            buttonName='Activate Users'
            onClick={() => onHandleShow(null, 'activateUsers')}
          />
        ) : (
          <ButtonDisabled buttonName='Activate Users' />
        )}
      </TableNavLeftPart>
      <TableNavRightPart>
        <ButtonSecondary
          buttonName="Example CSV"
          onClick={onHandleCsvExample}
        />
        <ButtonPrimaryFileUpload
          buttonName="Import CSV"
          right="10px"
          handleFile={onHandleCsvImport}
        />
        <Wrapper>
          <SelectStyled
            id={'userStatus'}
            name={'userStatus'}
            maxMenuHeight={150}
            options={dataUserStatus}
            width={'130px'}
            theme={theme}
            value={filterStatus}
            onChange={onHandleSelectedFilterStatus}
            isDot={false}
          />
        </Wrapper>
        <InputContainer>
          <InputStyled
            paddingLeft={'28px'}
            width={'160px'}
            placeholder={'Search...'}
            onChange={onSearchQueryChange}
            onKeyDown={onKeyDownSearchQuery}
          />
          <SearchStyled size={20} position={'absolute'} left={'6px'} top={'5px'} />
        </InputContainer>
      </TableNavRightPart>
    </TableNav>
  );
};

UserManagementHeader.propTypes = {
  onHandleChangeNameSearch: PropTypes.func,
  selectedUsers: PropTypes.array,
  onHandleShow: PropTypes.func,
  theme: PropTypes.object,
  filterStatus: PropTypes.object,
  onHandleSelectedFilterStatus: PropTypes.func,
  onHandleCsvImport: PropTypes.func,
  onHandleCsvExample: PropTypes.func,
};
