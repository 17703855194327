import * as type from '../types';

export const getRolesData = (data) => {
  return {
    type: type.GET_ROLES_DATA,
    payload: data,
  };
};

export const getRolesList = (data) => {
  return {
    type: type.GET_ROLES_LIST,
    payload: data,
  };
};

export const setFilterRoleStatus = (value) => {
  return {
    type: type.SET_FILTER_ROLE_STATUS,
    payload: value,
  };
};
