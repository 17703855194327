import styled from 'styled-components';
import { SIZE } from '../../../../constants/size';

export const Styles = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  img {
    height: 24px;
    border-radius: 50%;
  }
  h3 {
    font-weight: 600;
    font-size: 12px;
    margin: 0 0 0 10px;
  }
  h4 {
    font-weight: 400;
    font-size: 12px;
    margin: 0 0 0 10px;
  }
  @media (max-width: ${SIZE.tablet}) {
    justify-content: flex-end;
  }
`;

export const RowStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

export const CellStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) => props.justify || 'flex-start'};
  border-bottom: ${({ theme }) => `1px solid ${theme.tableBorderBottomColor}`};
  height: 50px;
  @media (max-width: ${SIZE.tablet}) {
    justify-content: flex-end;
  }
`;
