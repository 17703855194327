import { TableNav, TableNavLeftPart, TableNavRightPart } from '../StyledComponents';
import PropTypes from 'prop-types';
import { InputContainer } from '../../StyledComponents';
import { ButtonPrimary } from '../../../ButtonPrimary/ButtonPrimary';
import { InputStyled } from '../../../InputStyled/InputStyled';
import { SearchStyled } from '../../../../utils/iconsStyled';
import _ from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../../constants/routes';

export const OrganizationsHeader = (props) => {
  const { onHandleChangeNameSearch } = props;

  const delayedQuery = _.debounce((q) => onHandleChangeNameSearch(q), 1000);
  const onSearchQueryChange = (e) =>
    e.target.value.length > 3 ? delayedQuery(e.target.value) : undefined;
  const onKeyDownSearchQuery = (e) =>
    e.target.value.length < 4 && e.key === 'Enter'
      ? onHandleChangeNameSearch(e.target.value)
      : undefined;

  return (
    <TableNav>
      <TableNavLeftPart>
        <Link to={ROUTES.addOrganization}>
          <ButtonPrimary buttonName='Add Organization' />
        </Link>
      </TableNavLeftPart>
      <TableNavRightPart>
        <InputContainer>
          <InputStyled
            paddingLeft={'28px'}
            width={'160px'}
            placeholder={'Search...'}
            onChange={onSearchQueryChange}
            onKeyDown={onKeyDownSearchQuery}
          />
          <SearchStyled size={20} position={'absolute'} left={'6px'} top={'5px'} />
        </InputContainer>
      </TableNavRightPart>
    </TableNav>
  );
};

OrganizationsHeader.propTypes = {
  onHandleChangeNameSearch: PropTypes.func,
};
