import React from 'react';
import styled from 'styled-components';
import { Form } from 'react-bootstrap';

const Template = styled(Form.Check.Input)`
  width: ${(props) => props.width || '13px'};
  height: ${(props) => props.height || '13px'};
  margin-top: ${(props) => props.top || '4px'};
  margin-bottom: ${(props) => props.bottom || '4px'};
  margin-left: ${(props) => props.left || '4px'};
  margin-right: ${(props) => props.right || '4px'};
  border-color: ${({ theme }) => theme.checkBoxBorderColor};
  background: ${({ theme }) => theme.checkBoxBackgroundColor};
  :checked {
    border-color: ${({ theme }) => theme.checkBoxBorderCheckedColor};
    background: ${({ theme }) => theme.checkBoxBackgroundCheckedColor};
  }
  &:focus {
    border-color: ${({ theme }) => theme.checkBoxBorderFocusColor};
  }
  &:indeterminate {
    background-color: ${({ theme }) => `${theme.checkBoxIndeterminateBackgroundColor} !important`};
    border-color: ${({ theme }) => `${theme.checkBoxIndeterminateBorderColor} !important`};
  }
  cursor: pointer;
`;

export const CheckboxStyled = (props) => {
  const { top, bottom, left, right, type, name, value, onChange, isInvalid, ref, ...rest } = props;
  return (
    <Template
      top={top}
      bottom={bottom}
      left={left}
      right={right}
      type={type}
      name={name}
      value={value}
      onChange={onChange}
      isInvalid={isInvalid}
      ref={ref}
      {...rest}
    />
  );
};
