import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  ModalStyled,
  ModalBodyStyled,
  ModalHeaderStyled,
  ModalFooterStyled,
  ModalTitleStyled,
  ModalDialogStyled,
  CropperSlider,
  CropperContainer,
} from './StyledComponents';
import { ButtonPrimary } from '../ButtonPrimary/ButtonPrimary';
import { ButtonSecondary } from '../ButtonSecondary/ButtonSecondary';
import Cropper from 'react-cropper';
import { FlexStyled } from '../FlexStyled/FlexStyled';
import { MinusStyled, PlusStyled } from '../../utils/iconsStyled';
import 'cropperjs/dist/cropper.css';


export const UploadModal = (props) => {
  const [minZoom, maxZoom, zoomStep] = [props.minZoom, props.maxZoom, props.zoomStep];
  const [zoom, setZoom] = useState(1);
  const [cropper, setCropper] = useState(null);

  const handleSuccess = () => {
    getCropImageAsBlob(props.onHandleSuccess);
  };

  const handleClose = () => {
    props.onHandleClose();
  };

  const handleZoomOut = () => {
    setZoom((prev) => prev - zoomStep >= minZoom ? prev - zoomStep : minZoom);
  };

  const handleZoomIn = () => {
    setZoom((prev) => prev + zoomStep <= maxZoom ? prev + zoomStep : maxZoom);
  };

  const cropperInitialized = (cropperInstance) => {
    setCropper(cropperInstance);
  };

  const cropperZoomed = (event) => {
    if (event.detail.ratio < minZoom) {
      event.preventDefault();
      setZoom(minZoom);
    }

    if (event.detail.ratio > maxZoom) {
      event.preventDefault();
      setZoom(maxZoom);
    }

    setZoom(event.detail.ratio);
  };

  const handleSliderZoom = (event) => {
    setZoom(event.target.valueAsNumber);
  };

  const getCropImageAsUrl = () => {
    if (cropper) {
      return cropper.getCroppedCanvas().toDataURL();
    }
  };

  const getCropImageAsBlob = (callback) => {
    if (cropper) {
      return cropper.getCroppedCanvas().toBlob((blob) => {
        return callback(getCropImageAsUrl(), blob);
      });
    }
  };

  return (
    <>
      <ModalStyled
        show={props.show}
        dialogAs={ModalDialogStyled}
        animation={false}
      >
        <ModalHeaderStyled>
          <ModalTitleStyled>
            {props.titleText}
          </ModalTitleStyled>
        </ModalHeaderStyled>
        <ModalBodyStyled>
          <CropperContainer>
            <Cropper
              style={{ height: 440 }}
              zoomTo={zoom}
              initialAspectRatio={1}
              src={props.image}
              viewMode={1}
              minCropBoxHeight={280}
              minCropBoxWidth={280}
              background={false}
              responsive={true}
              autoCropArea={0.01}
              checkOrientation={false}
              onInitialized={cropperInitialized}
              zoom={cropperZoomed}
              guides={false}
              rotatable={false}
              scalable={false}
              cropBoxResizable={false}
              minContainerHeight={400}
              center={false}
              dragMode={'move'}
              cropBoxMovable={false}
              wheelZoomRatio={zoomStep}
            />
          </CropperContainer>
          <FlexStyled direction={'row'} top={'20px'}>
            <MinusStyled size={28} onClick={handleZoomOut} />
            <CropperSlider
              type={'range'}
              min={minZoom}
              max={maxZoom}
              step={zoomStep}
              value={zoom}
              onChange={handleSliderZoom}
            />
            <PlusStyled size={28} onClick={handleZoomIn} />
          </FlexStyled>
        </ModalBodyStyled>
        <ModalFooterStyled>
          <ButtonPrimary buttonName={props.primaryButtonText} onClick={handleSuccess} />
          <ButtonSecondary buttonName={props.secondaryButtonText} onClick={handleClose} />
        </ModalFooterStyled>
      </ModalStyled>
    </>
  );
};

UploadModal.propTypes = {
  titleText: PropTypes.string,
  primaryButtonText: PropTypes.string,
  secondaryButtonText: PropTypes.string,
  show: PropTypes.bool,
  onHandleClose: PropTypes.func,
  onHandleSuccess: PropTypes.func,
  image: PropTypes.string,
  minZoom: PropTypes.number,
  maxZoom: PropTypes.number,
  zoomStep: PropTypes.number,
};
