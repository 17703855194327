import React, { useMemo, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import { Link } from 'react-router-dom';
import { Header } from '../../../../components/Header/Header';
import { BackgroundStyled } from '../../../../components/BackgroundStyled/BackgroundStyled';
import { LabelStyled } from '../../../../components/LabelStyled/LabelStyled';
import { permissionValidation } from '../../../../utils/validationSchema';
import { InputValidation } from '../../../../components/InputValidation/InputValidation';
import { GroupStyled } from '../../../../components/GroupStyled/GroupStyled';
import { ButtonPrimary } from '../../../../components/ButtonPrimary/ButtonPrimary';
import { ButtonSecondary } from '../../../../components/ButtonSecondary/ButtonSecondary';
import { SelectValidation } from '../../../../components/SelectValidation/SelectValidation';
import {
  CardStyled,
  Container,
  PageTitleStyled,
  CardTitleStyled,
  FlexStyled,
} from '../../../../components/StyledComponents/StyledComponents';
import { ContainerStyled } from '../../StyledComponents';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../../../constants/routes';
import { hideLoader, showLoader, showToastWithTimeout } from '../../../../utils/common';
import { SUCCESSFUL_TOAST_MESSAGES } from '../../../../constants/messages';
import { ApiApplications } from '../../../../api/ApiApplications';
import { ApiPermissions } from '../../../../api/ApiPermissions';

export const AddPermission = () => {
  const theme = useSelector((state) => state.theme.theme);
  const dispatch = useDispatch();
  const apiApplications = useMemo(() => new ApiApplications(), []);
  const apiPermissions = useMemo(() => new ApiPermissions(), []);
  let history = useHistory();

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {
      (async () => {
        showLoader();
        const [applications] = await Promise.all([
          apiApplications.getApplications(),
        ]);

        if (applications) {
          setOptionsApplication(createAppsOptions(applications.data));
          showToastWithTimeout(SUCCESSFUL_TOAST_MESSAGES);
        }
        hideLoader();
      })();
    }

    return () => {
      unmounted = true;
    };
  }, [apiApplications, dispatch]);

  const [optionsApplication, setOptionsApplication] = useState([]);

  const routerPush = () => {
    history.push(ROUTES.permissions);
  };

  const optionsAction = [
    { value: 'View', label: 'View' },
    { value: 'Edit', label: 'Edit' },
    { value: 'Create', label: 'Create' },
    { value: 'Delete', label: 'Disable' },
  ];

  const createAppsOptions = (arr) => {
    let apps = [];
    arr.forEach((el) => apps.push({ value: el.id, label: el.name }));
    return apps;
  };

  return (
    <BackgroundStyled display={'block'}>
      <Header />
      <Container>
        <PageTitleStyled>Add Permission</PageTitleStyled>
        <Formik
          validationSchema={permissionValidation}
          initialValues={{
            name: '',
            description: '',
            guard_name: 'web',
            action: '',
            app_id: '',
          }}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={(values, { resetForm }) => {
            const permission = {
              name: values.name,
              description: values.description,
              guard_name: values.guard_name,
              action: values.action.value,
              app_id: values.app_id.value,
            };

            (async () => {
              showLoader();
              const [createPermission] = await Promise.all([
                apiPermissions.createPermission(permission),
              ]);

              if (createPermission) {
                resetForm();
                routerPush();
              } else {
                hideLoader();
              }
            })();
          }}
        >
          {({ handleSubmit, handleChange, setFieldValue, values }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <ContainerStyled>
                <CardStyled>
                  <CardTitleStyled>PERMISSION INFORMATION</CardTitleStyled>
                  <FlexStyled
                    paddingLeft={'20px'}
                    paddingRight={'20px'}
                    bottom={'20px'}
                    direction={'column'}
                    justify={'start'}
                    wrap={'wrap'}
                    align={'start'}
                  >
                    <GroupStyled top={'13px'} bottom={'10px'} width={'258px'}>
                      <LabelStyled bottom={'8px'}>Application</LabelStyled>
                      <Field
                        id={'app_id'}
                        component={SelectValidation}
                        name={'app_id'}
                        theme={theme}
                        maxMenuHeight={150}
                        value={values.app_id}
                        onChange={setFieldValue}
                        options={optionsApplication}
                        width={'256px'}
                        isDot={false}
                      />
                    </GroupStyled>
                    <GroupStyled top={'13px'} bottom={'10px'} width={'258px'}>
                      <LabelStyled bottom={'8px'}>Action</LabelStyled>
                      <Field
                        id={'action'}
                        component={SelectValidation}
                        name={'action'}
                        theme={theme}
                        maxMenuHeight={150}
                        value={values.action}
                        onChange={setFieldValue}
                        options={optionsAction}
                        width={'256px'}
                        isDot={false}
                      />
                    </GroupStyled>
                    <GroupStyled top={'13px'} bottom={'10px'}>
                      <LabelStyled bottom={'8px'}>Name</LabelStyled>
                      <Field
                        id={'name'}
                        component={InputValidation}
                        type='text'
                        width={'256px'}
                        name={'name'}
                        placeholder={'Name'}
                        onChange={handleChange}
                      />
                    </GroupStyled>
                    <GroupStyled top={'13px'} bottom={'25px'}>
                      <LabelStyled bottom={'8px'}>Permission Description</LabelStyled>
                      <Field
                        id={'description'}
                        component={InputValidation}
                        type='text'
                        width={'256px'}
                        name={'description'}
                        placeholder={'Permission Description'}
                        onChange={handleChange}
                      />
                    </GroupStyled>
                  </FlexStyled>
                </CardStyled>
                <FlexStyled direction={'row'} top={'10px'} justify={'flex-end'}>
                  <ButtonPrimary type='submit' buttonName='Create' />
                  <Link to={ROUTES.permissions}>
                    <ButtonSecondary buttonName='Cancel' />
                  </Link>
                </FlexStyled>
              </ContainerStyled>
            </Form>
          )}
        </Formik>
      </Container>
    </BackgroundStyled>
  );
};
