import {
  EditIconStyled,
  OrganizationStyled,
} from '../../../../utils/iconsStyled';
import { Styles, RowStyled, CellStyled } from './StyledComponents';
import moment from 'moment';
import { StatusLightBulbActive } from '../../StyledComponents';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../../constants/routes';

export const getColumnsForOrganizations = (handleEditOrganization) => {
  return [
    {
      name: 'Checkbox',
      id: 'selection',
      Header: () => {
        return '';
      },
      Cell: () => {
        return (
          <Styles>
            <RowStyled>
              <CellStyled></CellStyled>
            </RowStyled>
          </Styles>
        );
      },
    },
    {
      Header: 'ID',
      name: 'Id',
      accessor: 'id',
      Cell: ({ cell }) => {
        const id = cell.row.original.id;
        return (
          <Styles>
            <RowStyled>
              <CellStyled>
                <span>{id}</span>
              </CellStyled>
            </RowStyled>
          </Styles>
        );
      },
    },
    {
      Header: 'Organizations',
      name: 'Organizations',
      accessor: 'organizations',
      Cell: ({ cell }) => {
        const name = cell.row.original.name;
        return (
          <Styles>
            <RowStyled>
              <CellStyled>
                <OrganizationStyled size={'20px'} />
                <h3>{name}</h3>
              </CellStyled>
            </RowStyled>
          </Styles>
        );
      },
    },
    {
      Header: 'Status',
      name: 'Status',
      accessor: 'status',
      Cell: ({ cell }) => {
        return (
          <Styles>
            <RowStyled>
              <CellStyled>
                <StatusLightBulbActive />
                <h4>Active</h4>
              </CellStyled>
            </RowStyled>
          </Styles>
        );
      },
    },
    {
      Header: 'Type',
      name: 'Type',
      accessor: 'type',
      Cell: ({ cell }) => {
        const name = cell.row.original.type.name;
        return (
          <Styles>
            <RowStyled>
              <CellStyled>
                <span>{name}</span>
              </CellStyled>
            </RowStyled>
          </Styles>
        );
      },
    },
    {
      Header: 'Created',
      name: 'Created',
      accessor: 'created',
      Cell: ({ cell }) => {
        const created = cell.row.original.created_at;
        const date = moment(created).format('MMM DD, YYYY HH:mm');
        return (
          <Styles>
            <RowStyled>
              <CellStyled>
                <span>{date}</span>
              </CellStyled>
            </RowStyled>
          </Styles>
        );
      },
    },
    {
      Header: 'Updated',
      name: 'Updated',
      accessor: 'updated',
      Cell: ({ cell }) => {
        const updated = cell.row.original.updated_at;
        const date = moment(updated).format('MMM DD, YYYY HH:mm');
        return (
          <Styles>
            <RowStyled>
              <CellStyled>
                <span>{date}</span>
              </CellStyled>
            </RowStyled>
          </Styles>
        );
      },
    },
    {
      Header: '',
      name: '',
      id: 'button',
      Cell: ({ cell }) => {
        const id = cell.row.original.id;
        const entityType = cell.row.original.type.name.toLowerCase();
        const entityName = cell.row.original.name;
        return (
          <RowStyled>
            <CellStyled justify={'flex-end'}>
              <div>
                <Link to={`${ROUTES.editOrganization}${id}`}>
                  {/*<EditIconStyled data-id={id} size={20} right={'20px'} />*/}
                  <EditIconStyled
                    onClick={() => handleEditOrganization(entityType, entityName)}
                    data-id={id}
                    size={20}
                    right={'20px'} />
                </Link>
              </div>
            </CellStyled>
          </RowStyled>
        );
      },
    },
  ];
};
