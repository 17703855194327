import styled from 'styled-components';
import { SIZE } from '../../constants/size';

export const PillStyled = styled.div`
  color: ${({ theme }) => theme.pillTextColor};
  background-color: ${({ theme }) => theme.pillBackgroundColor};
  padding: 4px 6px;
  border-radius: 40px;
  font-weight: 400;
  font-size: 12px;
  margin-top: ${(props) => props.top || '1px'};
  margin-bottom: ${(props) => props.bottom || '1px'};
  margin-left: ${(props) => props.left || '1px'};
  margin-right: ${(props) => props.right || '1px'};
`;

export const PillMoreStyled = styled.div`
  color: ${({ theme }) => theme.pillMoreTextColor};
  background-color: ${({ theme }) => theme.pillMoreBackgroundColor};
  padding: 4px 6px;
  border-radius: 40px;
  font-weight: 400;
  font-size: 12px;
  margin-top: ${(props) => props.top || '0px'};
  margin-bottom: ${(props) => props.bottom || '0px'};
  margin-left: ${(props) => props.left || '0px'};
  margin-right: ${(props) => props.right || '0px'};
  cursor: pointer;
`;

export const StatusLightBulbActive = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.statusLightBulbActiveColor};
`;

export const StatusLightBulbInactive = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.statusLightBulbInactiveColor};
`;

export const StatusLightBulbDisabled = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.statusLightBulbDisabledColor};
`;

export const InputContainer = styled.div`
  position: relative;
`;

export const Wrapper = styled.div`
  margin: ${(props) => props.margin || '0px 8px 0px 0px'};
`;

export const TooltipStyled = styled.div`
  div {
    position: relative;
    display: inline-block;
  }

  div span {
    display: none;
    width: 120px;
    background-color: ${({ theme }) => theme.tooltipBackgroundColor};
    border: ${({ theme }) => `1px solid ${theme.tooltipBorderColor}`};
    color: ${({ theme }) => theme.tooltipTextColor};
    text-align: center;
    border-radius: 5px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;

    &:after {
      content: '';
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-left: -8px;
      border-width: 5px;
      border-style: solid;
      border-color: ${({ theme }) =>
              `transparent transparent ${theme.tooltipCornerColor} transparent`};
    }
  }

  div:hover span {
    display: block;
    opacity: 1;
  }
`;
export const TableFooterContainer = styled.div`
  background-color: ${({ theme }) => theme.tableRowBackgroundColor};
  padding: 20px 130px 20px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: ${({ theme }) => theme.tableFooterColor};
  align-items: center;
  @media (max-width: ${SIZE.tablet}) {
    flex-direction: column;
  }
`;
