import {
  EditIconStyled,
  TrashIconStyled,
  ArrowCounterclockwiseStyled,
} from '../../../../utils/iconsStyled';
import { Link } from 'react-router-dom';
import { Styles, RowStyled, CellStyled } from './StyledComponents';

export const getColumnsForPermissions = (handleShow, filterStatus, applicationsData) => {
  return [
    {
      name: 'Checkbox',
      id: 'selection',
      Header: () => {
        return '';
      },
      Cell: () => {
        return (
          <Styles>
            <RowStyled>
              <CellStyled></CellStyled>
            </RowStyled>
          </Styles>
        );
      },
    },
    {
      Header: 'ID',
      name: 'Id',
      accessor: 'id',
      Cell: ({ cell }) => {
        const id = cell.row.original.id;
        return (
          <Styles>
            <RowStyled>
              <CellStyled>
                <span>{id}</span>
              </CellStyled>
            </RowStyled>
          </Styles>
        );
      },
    },
    {
      Header: 'Permissions',
      name: 'Permissions',
      accessor: 'permissions',
      Cell: ({ cell }) => {
        const name = cell.row.original.name;
        return (
          <Styles>
            <RowStyled>
              <CellStyled>
                <span>{name}</span>
              </CellStyled>
            </RowStyled>
          </Styles>
        );
      },
    },
    {
      Header: 'Application',
      name: 'Application',
      accessor: 'application',
      Cell: ({ cell }) => {
        const id = cell.row.original.app_id;
        const application = applicationsData.filter((el) => el.id === id);
        const appName = application.length !== 0 ? application[0].name : '';
        return (
          <Styles>
            <RowStyled>
              <CellStyled>
                <span>{appName}</span>
              </CellStyled>
            </RowStyled>
          </Styles>
        );
      },
    },
    {
      Header: 'Action',
      name: 'Action',
      accessor: 'action',
      Cell: ({ cell }) => {
        const action = cell.row.original.action === 'Delete' ? 'Disable' : cell.row.original.action;
        return (
          <Styles>
            <RowStyled>
              <CellStyled>
                <span>{action}</span>
              </CellStyled>
            </RowStyled>
          </Styles>
        );
      },
    },
    {
      Header: 'Description',
      name: 'Description',
      accessor: 'description',
      Cell: ({ cell }) => {
        const description = cell.row.original.description;
        return (
          <Styles>
            <RowStyled>
              <CellStyled>
                <span>{description}</span>
              </CellStyled>
            </RowStyled>
          </Styles>
        );
      },
    },
    {
      Header: '',
      name: '',
      id: 'button',
      Cell: ({ cell }) => {
        const id = cell.row.original.id;
        const rolesLength = cell.row.original.roles.length;
        return (
          <RowStyled>
            <CellStyled justify={'flex-end'}>
              {filterStatus.value !== 'disabled' ? (
                <div>
                  <Link to={`/permissions/editPermission${id}`}>
                    <EditIconStyled data-id={id} size={20} right={'20px'} />
                  </Link>
                  <TrashIconStyled
                    data-id={id}
                    size={20}
                    onClick={() => handleShow(id, 'disable', rolesLength)}
                  />
                </div>
              ) : (
                <ArrowCounterclockwiseStyled
                  data-id={id}
                  size={22}
                  onClick={() => handleShow(id, 'restore')}
                />
              )}
            </CellStyled>
          </RowStyled>
        );
      },
    },
  ];
};
