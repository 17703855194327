import styled from 'styled-components';
import { SIZE } from '../../../constants/size';

export const TableContainer = styled.div`
  max-width: 1300px;
  margin: 0 auto;
  padding-top: 109px;
  @media (max-width: ${SIZE.tablet}) {
    padding-top: 85px;
  }
`;

export const TableNav = styled.div`
  padding: 0 20px;
  background-color: ${({ theme }) => theme.tableNavBackgroundColor};
  height: 63px;
  width: 100%;
  z-index: 100;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const TableNavLeftPart = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TableNavRightPart = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Styles = styled.div`
  padding: 0 20px;
  overflow-y: scroll;
  overflow-x: scroll;
  background-color: ${({ theme }) => theme.tableBackgroundColor};
  height: 65vh;

  table {
    color: ${({ theme }) => theme.tableTextColor};
    width: 100%;
    border-collapse: collapse;
    margin-top: 0;
  }

  table th {
    border-bottom: ${({ theme }) => `1px solid ${theme.tableBorderBottomColor}`};
    background-color: ${({ theme }) => theme.tableHeadBackgroundColor};
    color: ${({ theme }) => theme.tableHeadTextColor};
    position: sticky;
    top: 0;
    padding: 15px 0;
    text-align: left;
    font-size: 12px;
    z-index: 2;
  }

  table td {
    padding-bottom: 1px;
    border-bottom: ${({ theme }) => `1px solid ${theme.tableBorderBottomColor}`};
    text-align: left;
    font-size: 12px;
  }

  table tr {
    background-color: ${({ theme }) => theme.tableRowBackgroundColor};
  }

  table tr:hover {
    background-color: ${({ theme }) => theme.tableRowBackgroundHoverColor};
  }

  table tr th:first-child {
    display: none;
  }

  table tr td:first-child {
    display: none;
    @media (max-width: 1066px) {
      display: block;
    }
  }

  @media (max-width: 1066px) {
    table tbody tr:nth-child(even) {
      background-color: ${({ theme }) => theme.tableRowBackgroundHoverColor};
    }

    table tr:hover {
      background-color: ${({ theme }) => theme.tableRowBackgroundColor};
    }

    table thead {
      display: none;
    }

    table,
    table tbody,
    table tr,
    table td {
      display: none;
      border-bottom: 0px;
    }

    table tr {
      margin-bottom: 15px;
      padding: 0px 30px;
    }

    table,
    table tbody,
    table tr,
    table td:first-child {
      display: block;
    }
  }
`;
export const StyledCell = styled.div`
  border-bottom: ${({ theme }) => `1px solid ${theme.tableBorderBottomColor}`};
`;
