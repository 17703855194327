import styled from 'styled-components';
import React, { useEffect, forwardRef, useRef } from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

const Checkbox = styled(Form.Check.Input)`
  width: ${(props) => props.width || '13px'};
  height: ${(props) => props.height || '13px'};
  margin-top: ${(props) => props.top || '4px'};
  margin-bottom: ${(props) => props.bottom || '4px'};
  margin-left: ${(props) => props.left || '4px'};
  margin-right: ${(props) => props.right || '4px'};
  border-color: ${({ theme }) => theme.checkBoxBorderColor};
  background: ${({ theme }) => theme.checkBoxBackgroundColor};
  :checked {
    border-color: ${({ theme }) => theme.checkBoxBorderCheckedColor};
    background: ${({ theme }) => theme.checkBoxBackgroundCheckedColor};
  }
  &:focus {
    border-color: ${({ theme }) => theme.checkBoxBorderFocusColor};
  }
  &:indeterminate {
    background-color: ${({ theme }) => `${theme.checkBoxIndeterminateBackgroundColor} !important`};
    border-color: ${({ theme }) => `${theme.checkBoxIndeterminateBorderColor} !important`};
  }
  cursor: pointer;
`;

export const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return <Checkbox type="checkbox" ref={resolvedRef} {...rest} />;
});

IndeterminateCheckbox.propTypes = {
  ref: PropTypes.func,
};
