import React from 'react';
import { CheckboxStyled } from '../CheckboxStyled/CheckboxStyled';
import {
  CheckboxName,
  CheckboxListHeader,
  Container,
  CheckboxItemName,
  CheckboxItemWrapper,
} from './StyledComponents';

export const CheckBoxListWithHeader = (props) => {
  const { data, onCheckboxChange } = props;

  const renderList = () => {
    return data.list.map((item) => (
      <CheckboxItemWrapper key={item.id}>
        <CheckboxStyled
          key={item.id}
          type="checkbox"
          name={item.name}
          value={item.name}
          checked={item.isChecked}
          onChange={(e) => onCheckboxChange(e, item.name)}
        />
        <CheckboxItemName>{item.name}</CheckboxItemName>
      </CheckboxItemWrapper>
    ));
  };

  return (
    <Container>
      <CheckboxListHeader>
        <CheckboxStyled
          type="checkbox"
          name="checkAll"
          checked={data.allChecked}
          onChange={(e) => onCheckboxChange(e, `checkall-${data.listNameId}`)}
        />
        <CheckboxName>{data.listName === 'Delete' ? 'Disable' : data.listName}</CheckboxName>
      </CheckboxListHeader>
      {renderList()}
    </Container>
  );
};
