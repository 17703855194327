import store from '../redux/store';
import { clearStore } from '../redux/actions/login';

export const setLoggedOut = () => {
  localStorage.setItem('login', 'false');
  store.dispatch(clearStore());
};

export const setLoggedIn = () => {
  localStorage.setItem('login', 'true');
};

/**
 * @return {boolean}
 */
export const isLoggedIn = () => {
  return localStorage.getItem('login') === 'true';
};

/**
 * @return {boolean}
 */
export const isLoggedOut = () => {
  return localStorage.getItem('login') === 'false';
};

// User permissions
export const setUserPermissionsToLocalStorage = (permission) => {
  localStorage.setItem('userPermissions', JSON.stringify(permission));
};

export const getUserPermissionsFromLocalStorage = () =>
  JSON.parse(localStorage.getItem('userPermissions'));


// User Data
export const setUserDataToLocalStorage = (data) => {
  localStorage.setItem('userData', JSON.stringify(data));
};

export const getUserDataFromLocalStorage = () =>
  JSON.parse(localStorage.getItem('userData'));

//welcomePage
export const setWelcomePage = (data) => {
  localStorage.setItem('welcomePage', data);
};

export const isWelcomePage = () => {
  return localStorage.getItem('welcomePage') === 'true';
};

