import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik, Field, Form } from 'formik';
import { Link } from 'react-router-dom';
import { Header } from '../../../../components/Header/Header';
import { BackgroundStyled } from '../../../../components/BackgroundStyled/BackgroundStyled';
import { LabelStyled } from '../../../../components/LabelStyled/LabelStyled';
import { roleValidation } from '../../../../utils/validationSchema';
import { InputValidation } from '../../../../components/InputValidation/InputValidation';
import { GroupStyled } from '../../../../components/GroupStyled/GroupStyled';
import { ButtonPrimary } from '../../../../components/ButtonPrimary/ButtonPrimary';
import { ButtonSecondary } from '../../../../components/ButtonSecondary/ButtonSecondary';
import {
  CardStyled,
  Container,
  PageTitleStyled,
  CardTitleStyled,
  FlexStyled,
  TabsStyled, WrapperStyled, MenuNavigationStyled, BtnWrapperStyled,
} from '../../../../components/StyledComponents/StyledComponents';
import { TabContent } from '../../../../components/Tabs/TabContent/TabContent';
import Tab from '../../../../components/Tabs/Tab/Tab';
import { CheckBoxListWithHeader } from '../../../../components/CheckBoxListWithHeader/CheckBoxListWithHeader';
import { Api } from '../../../../api/Api';
import { CheckboxStyled } from '../../../../components/CheckboxStyled/CheckboxStyled';
import { CheckboxItemName, CheckboxItemWrapper } from '../../StyledComponents';
import { ChipStyled } from '../../../../components/ChipStyled/ChipStyled';
import _ from 'lodash';
import { ROUTES } from '../../../../constants/routes';
import { ScrollStyled } from '../../../../components/ScrollStyled/ScrollStyled';
import { useSelector } from 'react-redux';

const SIDE_MENU = [
  {
    label: 'General Information',
    activeClass: 'active',
    to: 'generalInformation',
    spy: true,
    smooth: false,
    offset: -100,
    duration: 500,
  },
  {
    label: 'Settings',
    activeClass: 'active',
    to: 'settings',
    spy: true,
    smooth: false,
    offset: -100,
    duration: 500,
  },
];

export const AddRole = () => {
  const api = useMemo(() => new Api(), []);
  const history = useHistory();
  const FIRST_PAGE = 1;

  const adminType = useSelector((state) => state.login.userData.is_admin);
  const userOrganization = useSelector((state) => state.login.userData.admin_of_entity_id);

  const getFormattedRolesData = (data) => {
    let formattedData = [];

    data.forEach((el) => {
      if (!formattedData.find((item) => item.tabLabel === el.app_id)) {
        let settings = [];

        settings.push(
          {
            listName: 'View',
            allChecked: false,
            listNameId: `View+${el.app_id}`,
            list: el.action === 'View' ? [{ id: el.id, name: el.name, isChecked: false }] : [],
          },
          {
            listName: 'Create',
            allChecked: false,
            listNameId: `Create+${el.app_id}`,
            list: el.action === 'Create' ? [{ id: el.id, name: el.name, isChecked: false }] : [],
          },
          {
            listName: 'Edit',
            allChecked: false,
            listNameId: `Edit+${el.app_id}`,
            list: el.action === 'Edit' ? [{ id: el.id, name: el.name, isChecked: false }] : [],
          },
          {
            listName: 'Delete',
            allChecked: false,
            listNameId: `Delete+${el.app_id}`,
            list: el.action === 'Delete' ? [{ id: el.id, name: el.name, isChecked: false }] : [],
          },
        );

        formattedData.push({
          tabLabel: el.app_id,
          allChecked: false,
          settings: settings,
        });
      } else {
        formattedData = formattedData.map((insideEl, i) => {
          let newSettings = insideEl.settings.map((item) => {
            let newList = [...item.list];
            if (item.listName === el.action && el.app_id === insideEl.tabLabel) {
              newList.push({
                id: el.id,
                isChecked: false,
                name: el.name,
              });
            }
            return {
              ...item,
              list: newList,
            };
          });
          return {
            ...insideEl,
            settings: newSettings,
          };
        });
      }
    });

    return formattedData;
  };

  useEffect(() => {
    let unmounted = false;
    const permission = api.getPermissionsActive();
    const applications = api.getApplications();
    applications.then((response) => {
      if (!unmounted && response) {
        setAplicationsData(response.data);
        permission.then((response) => {
          if (!unmounted && response) {
            setCheckBoxData(getFormattedRolesData(response.data));
          }
        });
      }
    });
    return () => {
      unmounted = true;
    };
  }, [api]);

  const [aplicationsData, setAplicationsData] = useState([]);
  const [checkBoxData, setCheckBoxData] = useState([]);
  const [permissionsId, setPermissionsId] = useState([]);
  const [roleName, setRoleName] = useState('');

  const getSelectedId = (data) => {
    const permissionsIdArr = [];
    data.forEach((el) => {
      return el.settings.forEach((settings) => {
        return settings.list.forEach((permission) => {
          if (permission.isChecked === true) {
            permissionsIdArr.push(permission.id);
          }
        });
      });
    });
    return permissionsIdArr;
  };

  const handleCheckboxChange = (e, name) => {
    let checkBoxDataCopy = [...checkBoxData];

    if (name.slice(0, 8) === 'checkall') {
      checkBoxDataCopy.forEach((tab) => {
        return tab.settings.forEach((setting) => {
          if (setting.listNameId === name.slice(9)) {
            setting.allChecked = !setting.allChecked;
            setting.list.forEach((item) => {
              item.isChecked = setting.allChecked;
            });
          }
          tab.allChecked = tab.settings.filter(el => el.list.length).every((el) => el.allChecked);
        });
      });
    } else {
      checkBoxDataCopy.forEach((tab) => {
        tab.settings.forEach((setting) => {
          setting.list.forEach((item) => {
            if (item.name === name) {
              item.isChecked = !item.isChecked;
              setting.allChecked = setting.list.every((el) => el.isChecked === true);
              tab.allChecked = setting.list.every((el) => el.isChecked === true);
            }
          });
        });
      });
    }

    setCheckBoxData(checkBoxDataCopy);
    setPermissionsId(getSelectedId(checkBoxDataCopy));
  };

  const handleCheckAllAppPermissions = (id) => {
    let checkBoxDataCopy = [...checkBoxData];
    checkBoxDataCopy.forEach((item) => {
      if (item.tabLabel === id) {
        const isChecked = !item.allChecked;
        item.allChecked = isChecked;
        item.settings.forEach((item) => {
          item.allChecked = isChecked;
          item.list.forEach((item) => {
            item.isChecked = isChecked;
          });
        });
      }
    });
    setCheckBoxData(checkBoxDataCopy);
    setPermissionsId(getSelectedId(checkBoxDataCopy));
  };

  const routerPush = () => {
    history.push(ROUTES.roleManagement);
  };

  const handleChangeName = (event) => {
    setRoleName(event.target.value);
  };

  //Select organization

  const [suggestions, setSuggestions] = useState([]);
  const [organizations, setOrganizations] = useState([]);

  const handleDeleteOrganization = (i) => {
    const organizationsList = organizations.slice(0);
    organizationsList.splice(i, 1);
    setOrganizations(organizationsList);
  };

  const handleAddOrganization = (el) => {
    const organizationsList = [].concat(organizations, el);
    setOrganizations(organizationsList);
  };

  const handleFormattedSuggestions = (data) => {
    const suggestions = [];
    data.forEach(el => {
      suggestions.push({ id: el.id, name: el.name });
    });
    return suggestions;
  };

  const handleSearchOrganization = async (el) => {
    const organizationsData = await api.getEntitiesPaginated(FIRST_PAGE, el);
    if (organizationsData) {
      setSuggestions(handleFormattedSuggestions(organizationsData.data.data));
    }
  };

  const selectedOrganizationIds = (data) => {
    const organizationIds = [];
    data.forEach(el => {
      organizationIds.push(el.id);
    });
    return organizationIds;
  };

  const delayedQuery = _.debounce((q) => handleSearchOrganization(q), 1000);

  return (
    <BackgroundStyled display={'block'}>
      <Header />
      <Container>
        <FlexStyled
          direction={'row'}
          align={'flex-start'}
          justify={'center'}
          wrap={'wrap'}
        >
          <MenuNavigationStyled>
            <PageTitleStyled padding={'20px 20px 0'}>Add Role</PageTitleStyled>
            <ul>
              {SIDE_MENU.map(item =>
                <li key={item.label}>
                  <ScrollStyled
                    activeClass={item.activeClass}
                    to={item.to}
                    spy={item.spy}
                    smooth={item.smooth}
                    offset={item.offset}
                    duration={item.duration}
                  >{item.label}
                  </ScrollStyled>
                </li>)}
            </ul>
          </MenuNavigationStyled>
          <WrapperStyled width={'580px'}>
            <Formik
              validationSchema={roleValidation}
              validateOnChange={false}
              validateOnBlur={false}
              enableReinitialize={true}
              initialValues={{
                name: roleName,
                permissions_id: permissionsId,
                entities_id: adminType ? selectedOrganizationIds(organizations) : [userOrganization],
              }}
              onSubmit={async (values, { resetForm }) => {
                const role = {
                  name: values.name,
                  permissions_id: values.permissions_id,
                  entities_id: values.entities_id,
                };
                await api.createRole(role, routerPush, resetForm);
              }}
            >
              {({ handleSubmit, handleChange, setFieldValue, values }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <CardStyled>
                    <CardTitleStyled id='generalInformation' padding={'24px 24px'}>GENERAL INFORMATION</CardTitleStyled>
                    <FlexStyled
                      paddingLeft={'20px'}
                      paddingRight={'20px'}
                      bottom={'20px'}
                      direction={'column'}
                      justify={'start'}
                      wrap={'wrap'}
                    >
                      <GroupStyled top={'13px'} bottom={'13px'}>
                        <LabelStyled bottom={'8px'}>Role Title</LabelStyled>
                        <Field
                          id={'name'}
                          component={InputValidation}
                          type='text'
                          width={'256px'}
                          name={'name'}
                          placeholder={'Role Title'}
                          value={roleName}
                          onChange={handleChangeName}
                        />
                      </GroupStyled>
                      {adminType && (
                        <GroupStyled top={'13px'} bottom={'35px'}>
                          <LabelStyled bottom={'8px'}>Add Organization</LabelStyled>
                          <ChipStyled
                            tags={organizations}
                            suggestions={suggestions}
                            onDelete={handleDeleteOrganization}
                            onAddition={handleAddOrganization}
                            onInput={delayedQuery}
                            placeholderText={'search...'}
                            display={organizations.length > 0 ? 'none' : 'block'}
                            width={'256px'}
                          />
                        </GroupStyled>
                      )}
                    </FlexStyled>
                  </CardStyled>
                  <CardStyled>
                    <CardTitleStyled id='settings' padding={'24px 24px'}>SETTINGS</CardTitleStyled>
                    <FlexStyled
                      paddingLeft={'20px'}
                      paddingRight={'20px'}
                      direction={'column'}
                      justify={'start'}
                      wrap={'wrap'}
                    >
                      <GroupStyled top={'13px'} bottom={'13px'}>
                        {checkBoxData.length !== 0 ? (
                          <TabsStyled>
                            <Tab>
                              {checkBoxData.map((item, i) => {
                                const id = item.tabLabel;
                                const aplication = aplicationsData.filter((el) => el.id === id);
                                const appName = aplication.length !== 0 ? aplication[0].name : '';
                                return (
                                  <TabContent label={appName} key={i}>
                                    <FlexStyled
                                      direction={'column'}
                                      justify={'space-between'}
                                      wrap={'wrap'}
                                      align={'flex-start;'}
                                    >
                                      <CheckboxItemWrapper>
                                        <CheckboxStyled
                                          value={item.allChecked}
                                          checked={item.allChecked}
                                          name={item.label}
                                          onChange={() => handleCheckAllAppPermissions(id)}
                                        />
                                        <CheckboxItemName>Check all {appName} permissions</CheckboxItemName>
                                      </CheckboxItemWrapper>
                                      {item.settings.map(
                                        (item, i) =>
                                          item.list.length !== 0 && (
                                            <CheckBoxListWithHeader
                                              data={item}
                                              key={i}
                                              onCheckboxChange={handleCheckboxChange}
                                              aplicationsData={aplicationsData}
                                            />
                                          ),
                                      )}
                                    </FlexStyled>
                                  </TabContent>
                                );
                              })}
                            </Tab>
                          </TabsStyled>
                        ) : (
                          ''
                        )}
                      </GroupStyled>
                    </FlexStyled>
                  </CardStyled>
                  <BtnWrapperStyled>
                    <FlexStyled direction={'row'} justify={'flex-end'} top={'10px'}>
                      <ButtonPrimary type='submit' buttonName='Create' />
                      <Link to={ROUTES.roleManagement}>
                        <ButtonSecondary buttonName='Cancel' />
                      </Link>
                    </FlexStyled>
                  </BtnWrapperStyled>
                </Form>
              )}
            </Formik>
          </WrapperStyled>
        </FlexStyled>
      </Container>
    </BackgroundStyled>
  );
};
