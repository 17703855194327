import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import { Link } from 'react-router-dom';
import { Header } from '../../../../components/Header/Header';
import { BackgroundStyled } from '../../../../components/BackgroundStyled/BackgroundStyled';
import { LabelStyled } from '../../../../components/LabelStyled/LabelStyled';
import { roleValidation } from '../../../../utils/validationSchema';
import { InputValidation } from '../../../../components/InputValidation/InputValidation';
import { GroupStyled } from '../../../../components/GroupStyled/GroupStyled';
import { ButtonPrimary } from '../../../../components/ButtonPrimary/ButtonPrimary';
import { ButtonSecondary } from '../../../../components/ButtonSecondary/ButtonSecondary';
import {
  CardStyled,
  Container,
  PageTitleStyled,
  CardTitleStyled,
  FlexStyled,
  TabsStyled, MenuNavigationStyled, WrapperStyled, BtnWrapperStyled,
} from '../../../../components/StyledComponents/StyledComponents';
import { TabContent } from '../../../../components/Tabs/TabContent/TabContent';
import Tab from '../../../../components/Tabs/Tab/Tab';
import { CheckBoxListWithHeader } from '../../../../components/CheckBoxListWithHeader/CheckBoxListWithHeader';
import { Api } from '../../../../api/Api';
import { useHistory, useLocation } from 'react-router-dom';
import _ from 'lodash';
import { ChipStyled } from '../../../../components/ChipStyled/ChipStyled';
import { ROUTES } from '../../../../constants/routes';
import { ScrollStyled } from '../../../../components/ScrollStyled/ScrollStyled';

const SIDE_MENU = [
  {
    label: 'General Information',
    activeClass: 'active',
    to: 'generalInformation',
    spy: true,
    smooth: false,
    offset: -100,
    duration: 500,
  },
  {
    label: 'Settings',
    activeClass: 'active',
    to: 'settings',
    spy: true,
    smooth: false,
    offset: -100,
    duration: 500,
  },
];

export const EditRole = () => {
  const location = useLocation();
  const regex = /\d+/g;
  const id = location.pathname.match(regex)[0];
  const dispatch = useDispatch();
  const api = useMemo(() => new Api(), []);
  const history = useHistory();
  const FIRST_PAGE = 1;

  const adminType = useSelector((state) => state.login.userData.is_admin);
  const userOrganization = useSelector((state) => state.login.userData.admin_of_entity_id);

  useEffect(() => {
    let unmounted = false;
    const applications = api.getApplications();
    const permission = api.getPermissionsActive();

    applications.then((response) => {
      if (!unmounted && response) {
        setApplicationsData(response.data);
        const role = api.getRole(id);
        role.then((response) => {
          if (!unmounted && response) {
            setOrganizations(handleFormattedChipData(response.data.entities));
            const checkedIdData = getCheckedId(response.data.permissions);
            const nameData = response.data.name;
            permission.then((response) => {
              if (!unmounted && response) {
                setCheckBoxData(getFormattedRolesData(response.data, checkedIdData));
                setRoleName(nameData);
                setPermissionsId(formattedIdsResponse(checkedIdData));
              }
            });
          }
        });
      }
    });
    return () => {
      unmounted = true;
    };
  }, [api, dispatch, id]);

  const [applicationsData, setApplicationsData] = useState([]);
  const [checkBoxData, setCheckBoxData] = useState([]);
  const [permissionsId, setPermissionsId] = useState([]);
  const [roleName, setRoleName] = useState('');

  const getFormattedRolesData = (data, idData) => {
    let formattedData = [];

    let checkedIdDataIds = [];
    idData.forEach((el) => checkedIdDataIds.push(el.id));

    data.forEach((el) => {
      if (!formattedData.find((item) => item.tabLabel === el.app_id)) {
        let settings = [];
        ///View
        const allViewPermisions = data.filter(
          (viewEl) => viewEl.action === 'View' && viewEl.app_id === el.app_id,
        );
        const allViewPermisionsIds = [];

        allViewPermisions.forEach((el) => allViewPermisionsIds.push(el.id));

        const checkedIdsView = [];

        idData.forEach((el) => {
          if (el.action === 'View') {
            checkedIdsView.push(el.id);
          }
        });

        ///Create
        const allCreatePermisions = data.filter(
          (createEl) => createEl.action === 'Create' && createEl.app_id === el.app_id,
        );
        const allCreatePermisionsIds = [];

        allCreatePermisions.forEach((el) => allCreatePermisionsIds.push(el.id));

        const checkedIdsCreate = [];

        idData.forEach((el) => {
          if (el.action === 'Create') {
            checkedIdsCreate.push(el.id);
          }
        });

        ///Edit
        const allEditPermisions = data.filter(
          (editEl) => editEl.action === 'Edit' && editEl.app_id === el.app_id,
        );
        const allEditPermisionsIds = [];

        allEditPermisions.forEach((el) => allEditPermisionsIds.push(el.id));

        const checkedIdsEdit = [];

        idData.forEach((el) => {
          if (el.action === 'Edit') {
            checkedIdsEdit.push(el.id);
          }
        });

        ///Delete
        const allDeletePermisions = data.filter(
          (editEl) => editEl.action === 'Delete' && editEl.app_id === el.app_id,
        );
        const allDeletePermisionsIds = [];

        allDeletePermisions.forEach((el) => allDeletePermisionsIds.push(el.id));

        const checkedIdsDelete = [];

        idData.forEach((el) => {
          if (el.action === 'Delete') {
            checkedIdsDelete.push(el.id);
          }
        });

        settings.push(
          {
            listName: 'View',
            allChecked: allViewPermisionsIds.every((i) => checkedIdsView.includes(i)),
            listNameId: `View+${el.app_id}`,
            list:
              el.action === 'View'
                ? [
                  {
                    id: el.id,
                    name: el.name,
                    isChecked: checkedIdDataIds.includes(el.id) ? true : false,
                  },
                ]
                : [],
          },
          {
            listName: 'Create',
            allChecked: allCreatePermisionsIds.every((i) => checkedIdsCreate.includes(i)),
            listNameId: `Create+${el.app_id}`,
            list:
              el.action === 'Create'
                ? [
                  {
                    id: el.id,
                    name: el.name,
                    isChecked: checkedIdDataIds.includes(el.id) ? true : false,
                  },
                ]
                : [],
          },
          {
            listName: 'Edit',
            allChecked: allEditPermisionsIds.every((i) => checkedIdsEdit.includes(i)),
            listNameId: `Edit+${el.app_id}`,
            list:
              el.action === 'Edit'
                ? [
                  {
                    id: el.id,
                    name: el.name,
                    isChecked: checkedIdDataIds.includes(el.id) ? true : false,
                  },
                ]
                : [],
          },
          {
            listName: 'Delete',
            allChecked: allDeletePermisionsIds.every((i) => checkedIdsDelete.includes(i)),
            listNameId: `Delete+${el.app_id}`,
            list:
              el.action === 'Delete'
                ? [
                  {
                    id: el.id,
                    name: el.name,
                    isChecked: checkedIdDataIds.includes(el.id) ? true : false,
                  },
                ]
                : [],
          },
        );

        formattedData.push({
          tabLabel: el.app_id,
          allChecked: false,
          settings: settings,
        });
      } else {
        formattedData = formattedData.map((insideEl, i) => {
          let newSettings = insideEl.settings.map((item) => {
            let newList = [...item.list];
            if (item.listName === el.action && el.app_id === insideEl.tabLabel) {
              newList.push({
                id: el.id,
                name: el.name,
                isChecked: checkedIdDataIds.includes(el.id) ? true : false,
              });
            }
            return {
              ...item,
              list: newList,
            };
          });
          return {
            ...insideEl,
            settings: newSettings,
          };
        });
      }
    });

    return formattedData;
  };

  const getSelectedId = (data) => {
    if (data) {
      const permissionsIdArr = [];
      data.forEach((el) => {
        return el.settings.forEach((settings) => {
          return settings.list.forEach((permission) => {
            if (permission.isChecked === true) {
              permissionsIdArr.push(permission.id);
            }
          });
        });
      });
      return permissionsIdArr;
    }
  };

  const getCheckedId = (data) => {
    if (data) {
      const permissionsIdArr = [];
      data.forEach((el) => permissionsIdArr.push({ id: el.id, action: el.action }));
      return permissionsIdArr;
    }
  };

  const formattedIdsResponse = (arr) => {
    const ids = [];
    arr.forEach((el) => ids.push(el.id));
    return ids;
  };

  const handleCheckboxChange = (e, name) => {
    let checkBoxDataCopy = [...checkBoxData];

    if (name.slice(0, 8) === 'checkall') {
      checkBoxDataCopy.forEach((item) => {
        return item.settings.forEach((setting) => {
          if (setting.listNameId === name.slice(9)) {
            setting.allChecked = !setting.allChecked;
            setting.list.forEach((item) => {
              item.isChecked = setting.allChecked;
            });
          }
        });
      });
    } else {
      checkBoxDataCopy.forEach((item) => {
        return item.settings.forEach((setting) => {
          return setting.list.forEach((item) => {
            if (item.name === name) {
              item.isChecked = !item.isChecked;
              setting.allChecked = setting.list.every((el) => el.isChecked === true);
            }
          });
        });
      });
    }
    setCheckBoxData(checkBoxDataCopy);
    setPermissionsId(getSelectedId(checkBoxDataCopy));
  };

  const routerPush = () => {
    history.push(ROUTES.roleManagement);
  };

  const handleChangeName = (event) => {
    setRoleName(event.target.value);
  };

  //Select organization

  const [suggestions, setSuggestions] = useState([]);
  const [organizations, setOrganizations] = useState([]);

  const handleDeleteOrganization = (i) => {
    const organizationsList = organizations.slice(0);
    organizationsList.splice(i, 1);
    setOrganizations(organizationsList);
  };

  const handleAddOrganization = (el) => {
    const organizationsList = [].concat(organizations, el);
    setOrganizations(organizationsList);
  };

  const handleFormattedChipData = (data) => {
    const chipData = [];
    data.forEach(el => {
      chipData.push({ id: el.id, name: el.name });
    });
    return chipData;
  };

  const handleSearchOrganization = async (el) => {
    const organizationsData = await api.getEntitiesPaginated(FIRST_PAGE, el);
    if (organizationsData) {
      setSuggestions(handleFormattedChipData(organizationsData.data.data));
    }
  };

  const selectedOrganizationIds = (data) => {
    const organizationIds = [];
    data.forEach(el => {
      organizationIds.push(el.id);
    });
    return organizationIds;
  };

  const delayedQuery = _.debounce((q) => handleSearchOrganization(q), 1000);

  return (
    <BackgroundStyled display={'block'}>
      <Header />
      <Container>
        <FlexStyled
          direction={'row'}
          align={'flex-start'}
          justify={'center'}
          wrap={'wrap'}
        >
          <MenuNavigationStyled>
            <PageTitleStyled padding={'20px 20px 0'}>Edit Role</PageTitleStyled>
            <ul>
              {SIDE_MENU.map(item =>
                <li key={item.label}>
                  <ScrollStyled
                    activeClass={item.activeClass}
                    to={item.to}
                    spy={item.spy}
                    smooth={item.smooth}
                    offset={item.offset}
                    duration={item.duration}
                  >{item.label}
                  </ScrollStyled>
                </li>)}
            </ul>
          </MenuNavigationStyled>
          <WrapperStyled width={'580px'}>
            <Formik
              validationSchema={roleValidation}
              validateOnChange={false}
              validateOnBlur={false}
              enableReinitialize={true}
              initialValues={{
                name: roleName ? roleName : '',
                permissions_id: permissionsId,
                entities_id: adminType ? selectedOrganizationIds(organizations) : [userOrganization],
              }}
              onSubmit={async (values, { resetForm }) => {
                const role = {
                  name: values.name,
                  permissions_id: values.permissions_id,
                  entities_id: values.entities_id,
                };
                await api.editRole(id, role, routerPush, resetForm);
              }}
            >
              {({ handleSubmit, handleChange, setFieldValue, values }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <CardStyled>
                    <CardTitleStyled id='generalInformation' padding={'24px 24px'}>GENERAL INFORMATION</CardTitleStyled>
                    <FlexStyled
                      paddingLeft={'20px'}
                      paddingRight={'20px'}
                      bottom={'20px'}
                      direction={'column'}
                      justify={'start'}
                      wrap={'wrap'}
                    >
                      <GroupStyled top={'13px'} bottom={'13px'}>
                        <LabelStyled bottom={'8px'}>Role Title</LabelStyled>
                        <Field
                          id={'name'}
                          component={InputValidation}
                          type='text'
                          width={'256px'}
                          name={'name'}
                          placeholder={'Role Title'}
                          value={roleName}
                          onChange={handleChangeName}
                        />
                      </GroupStyled>
                      {adminType && (
                        <GroupStyled top={'13px'} bottom={'50px'}>
                          <LabelStyled bottom={'8px'}>Organizations</LabelStyled>
                          <ChipStyled
                            tags={organizations}
                            suggestions={suggestions}
                            onDelete={handleDeleteOrganization}
                            onAddition={handleAddOrganization}
                            onInput={delayedQuery}
                            placeholderText={'search...'}
                            display={organizations.length > 0 ? 'none' : 'block'}
                            width={'256px'}
                          />
                        </GroupStyled>
                      )}
                    </FlexStyled>
                  </CardStyled>
                  <CardStyled>
                    <CardTitleStyled id='settings' padding={'24px 24px'}>SETTINGS</CardTitleStyled>
                    <FlexStyled
                      paddingLeft={'20px'}
                      paddingRight={'20px'}
                      direction={'column'}
                      justify={'start'}
                      wrap={'wrap'}
                    >
                      <GroupStyled top={'13px'} bottom={'13px'}>
                        {checkBoxData.length !== 0 ? (
                          <TabsStyled>
                            <Tab>
                              {checkBoxData.map((item, i) => {
                                const id = item.tabLabel;
                                const application = applicationsData.filter((el) => el.id === id);
                                const appName = application.length !== 0 ? application[0].name : '';
                                return (
                                  <TabContent label={appName} key={i}>
                                    <FlexStyled
                                      direction={'column'}
                                      justify={'space-between'}
                                      wrap={'wrap'}
                                      align={'flex-start;'}
                                    >
                                      {item.settings.map(
                                        (item, i) =>
                                          item.list.length !== 0 && (
                                            <CheckBoxListWithHeader
                                              data={item}
                                              key={i}
                                              onCheckboxChange={handleCheckboxChange}
                                              applicationsData={applicationsData}
                                            />
                                          ),
                                      )}
                                    </FlexStyled>
                                  </TabContent>
                                );
                              })}
                            </Tab>
                          </TabsStyled>
                        ) : (
                          ''
                        )}
                      </GroupStyled>
                    </FlexStyled>
                  </CardStyled>
                  <BtnWrapperStyled>
                    <FlexStyled direction={'row'} justify={'flex-end'} top={'10px'}>
                      <ButtonPrimary type='submit' buttonName='Update' />
                      <Link to={ROUTES.roleManagement}>
                        <ButtonSecondary buttonName='Cancel' />
                      </Link>
                    </FlexStyled>
                  </BtnWrapperStyled>
                </Form>
              )}
            </Formik>
          </WrapperStyled>
        </FlexStyled>
      </Container>
    </BackgroundStyled>
  );
};
