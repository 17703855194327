import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown';
import { ButtonPrimary } from '../ButtonPrimary/ButtonPrimary';
import { Api } from '../../api/Api';
import {
  HeaderWrapper,
  HeaderContainer,
  Logo,
  DropdownAppLogo,
  HeaderMenu,
  IconBellStyled,
  IconUserCircleFillStyled,
  HeaderAction,
  IconGrid3x3GapFillStyled,
  HeaderDropdownMenu,
  HeaderDropdownMenuItems,
  NavBarTab,
  IconChevronRight,
  NavBarContainer,
  NavBarNavigation,
  MenuIcon,
  HeaderDropdownTabs,
  MobileMenu,
  DesktopMenu,
  HeaderDropdownUserSettings,
  DropdownItems,
} from './StyledComponents';
import { PermissionsGate } from '../PermissionsGate/PermissionsGate';
import { PERMISSIONS } from '../../constants/permission';
import { ROUTES } from '../../constants/routes';
import AppLogo from '../../assets/logos/logo-now-access.png';
import LogoIbiza from '../../assets/logos/logo-dark-ibiza.png';
import LogoMetroon from '../../assets/logos/logo-dark-metroon.png';
import LogoMarkets from '../../assets/logos/logo-dark-markets.png';
import LogoNowCP from '../../assets/logos/logo-nowcp.png';
import { useLocation } from 'react-router-dom';

export const Header = () => {
  const theme = useSelector((state) => state.theme.theme);
  const api = useMemo(() => new Api(), []);

  const handleLogout = async () => {
    await api.logout();
  };

  const location = useLocation();
  const WELCOME_PAGE = ROUTES.welcome;

  const activeTab = {
    backgroundColor: `${theme.activeTabRouterBackgroundColor}`,
    color: `${theme.activeTabRouterTextColor}`,
  };

  const apps = {
    Ibiza: process.env.REACT_APP_URL_IBIZA,
    Metroon: process.env.REACT_APP_URL_METROON,
    NowCP: process.env.REACT_APP_URL_NOWCP,
    Markets: process.env.REACT_APP_URL_MARKETS,
  };

  return (
    <HeaderWrapper>
      <HeaderContainer>
        <Logo src={AppLogo} alt='logo' />
        <HeaderMenu>
          <IconBellStyled size='20px' />
          <Dropdown>
            <HeaderAction>
              <IconUserCircleFillStyled size='20px' />
            </HeaderAction>
            <HeaderDropdownUserSettings>
              <DropdownItems>
                <NavBarTab activeStyle={activeTab} to={ROUTES.userSettings}>
                  User Settings
                </NavBarTab>
                <ButtonPrimary onClick={() => handleLogout()} buttonName='Logout' />
              </DropdownItems>
            </HeaderDropdownUserSettings>
          </Dropdown>
          <Dropdown>
            <HeaderAction>
              <IconGrid3x3GapFillStyled size='20px' />
            </HeaderAction>
            <HeaderDropdownMenu>
              <HeaderDropdownMenuItems href={apps.Ibiza}>
                <DropdownAppLogo src={LogoIbiza} alt='Ibiza' />
                Ibiza
              </HeaderDropdownMenuItems>
              <HeaderDropdownMenuItems href={apps.Metroon}>
                <DropdownAppLogo src={LogoMetroon} alt='Metroon' />
                Metroon
              </HeaderDropdownMenuItems>
              <HeaderDropdownMenuItems href={apps.Markets}>
                <DropdownAppLogo src={LogoMarkets} alt='Markets' />
                Markets
              </HeaderDropdownMenuItems>
              <HeaderDropdownMenuItems href={apps.NowCP}>
                <DropdownAppLogo src={LogoNowCP} alt='NowCP' height={'13px'} />
                NowCP
              </HeaderDropdownMenuItems>
            </HeaderDropdownMenu>
          </Dropdown>
        </HeaderMenu>
      </HeaderContainer>
      {location.pathname !== WELCOME_PAGE && (
        <NavBarContainer>
          <NavBarNavigation>
            <MobileMenu>
              <Dropdown>
                <HeaderAction>
                  <MenuIcon size='28px' />
                </HeaderAction>
                <HeaderDropdownTabs>
                  <DropdownItems>
                    <PermissionsGate permissionGranted={{ name: PERMISSIONS.copa_access_to_organization }}>
                      <NavBarTab activeStyle={activeTab} to={ROUTES.organizations}>
                        ORGANIZATIONS
                      </NavBarTab>
                    </PermissionsGate>
                    <PermissionsGate permissionGranted={{ name: PERMISSIONS.copa_access_to_users }}>
                      <NavBarTab activeStyle={activeTab} to={ROUTES.userManagement}>
                        USER MANAGEMENT
                      </NavBarTab>
                    </PermissionsGate>
                    <PermissionsGate permissionGranted={{ name: PERMISSIONS.copa_access_to_roles }}>
                      <NavBarTab activeStyle={activeTab} to={ROUTES.roleManagement}>
                        ROLE MANAGEMENT
                      </NavBarTab>
                    </PermissionsGate>
                    <PermissionsGate permissionGranted={{ name: PERMISSIONS.copa_access_to_permissions }}>
                      <NavBarTab activeStyle={activeTab} to={ROUTES.permissions}>
                        PERMISSIONS
                      </NavBarTab>
                    </PermissionsGate>
                  </DropdownItems>
                </HeaderDropdownTabs>
              </Dropdown>
            </MobileMenu>
            <DesktopMenu>
              <PermissionsGate permissionGranted={{ name: PERMISSIONS.copa_access_to_organization }}>
                <NavBarTab activeStyle={activeTab} to={ROUTES.organizations}>
                  ORGANIZATIONS
                </NavBarTab>
                <IconChevronRight size='14px' />
              </PermissionsGate>
              <PermissionsGate permissionGranted={{ name: PERMISSIONS.copa_access_to_users }}>
                <NavBarTab activeStyle={activeTab} to={ROUTES.userManagement}>
                  USER MANAGEMENT
                </NavBarTab>
                <IconChevronRight size='14px' />
              </PermissionsGate>
              <PermissionsGate permissionGranted={{ name: PERMISSIONS.copa_access_to_roles }}>
                <NavBarTab activeStyle={activeTab} to={ROUTES.roleManagement}>
                  ROLE MANAGEMENT
                </NavBarTab>
                <IconChevronRight size='14px' />
              </PermissionsGate>
              <PermissionsGate permissionGranted={{ name: PERMISSIONS.copa_access_to_permissions }}>
                <NavBarTab activeStyle={activeTab} to={ROUTES.permissions}>
                  PERMISSIONS
                </NavBarTab>
              </PermissionsGate>
            </DesktopMenu>
          </NavBarNavigation>
        </NavBarContainer>
      )}
    </HeaderWrapper>
  );
};
