import styled, { keyframes } from 'styled-components';
import { Grid3x3GapFill } from '@styled-icons/bootstrap/Grid3x3GapFill';
import { UserCircle } from '@styled-icons/heroicons-outline/UserCircle';
import { Bell } from '@styled-icons/boxicons-solid/Bell';
import Dropdown from 'react-bootstrap/Dropdown';
import { ChevronRight } from '@styled-icons/bootstrap/ChevronRight';
import { Menu } from '@styled-icons/ionicons-sharp/Menu';
import { NavLink } from 'react-router-dom';
import { SIZE } from '../../constants/size';

export const HeaderWrapper = styled.div`
  background-color: ${({ theme }) => theme.headerBackgroundColor};
  position: fixed;
  top: 0px;
  height: 44px;
  width: 100%;
  z-index: 100;
`;

export const IconGrid3x3GapFillStyled = styled(Grid3x3GapFill)`
  transition: 0.2s ease-out;
  color: ${({ theme }) => theme.headerIconColor};
  margin: 9px;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.headerIconHoverColor};
  }
`;

export const IconBellStyled = styled(Bell)`
  transition: 0.2s ease-out;
  color: ${({ theme }) => theme.headerIconColor};
  margin: 12px;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.headerIconHoverColor};
  }
`;

export const IconUserCircleFillStyled = styled(UserCircle)`
  transition: 0.2s ease-out;
  color: ${({ theme }) => theme.headerIconColor};
  margin: 10px;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.headerIconHoverColor};
  }
`;

export const HeaderContainer = styled.div`
  max-width: 1192px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderMenu = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Logo = styled.img`
  margin: 0 10px;
  padding: 0;
  height: 27px;
  text-align: center;
`;
export const DropdownAppLogo = styled.img`
  margin: 0 20px 0 0;
  padding: 0;
  height: ${(props) => props.height || '24px'};
  text-align: center;
`;

export const HeaderAction = styled(Dropdown.Toggle)`
  background-color: ${({ theme }) => theme.headerActionBackgroundColor};
  border-color: ${({ theme }) => theme.headerActionBorderColor};
  padding: 0;

  &:after {
    display: none;
  }
`;

const animation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const HeaderDropdownMenu = styled(Dropdown.Menu)`
  background-color: ${({ theme }) => theme.headerDropdownMenuBackgroundColor};
  margin-top: 2px;
  padding: 0;
  inset: 42px auto auto -130px !important;
  animation: ${animation} 0.3s ease-in-out;
  transform: none !important;
`;

export const HeaderDropdownTabs = styled(Dropdown.Menu)`
  background-color: ${({ theme }) => theme.headerDropdownMenuBackgroundColor};
  margin-top: 2px;
  padding: 0;
  inset: 36px auto auto -4px !important;
  animation: ${animation} 0.3s ease-in-out;
  transform: none !important;
`;

export const HeaderDropdownUserSettings = styled(Dropdown.Menu)`
  background-color: ${({ theme }) => theme.headerDropdownUserSettingsBackgroundColor};
  margin-top: 2px;
  padding: 0;
  inset: 43px auto auto -120px !important;
  animation: ${animation} 0.3s ease-in-out;
  transform: none !important;
`;

export const HeaderDropdownMenuItems = styled(Dropdown.Item)`
  color: ${({ theme }) => theme.headerDropdownMenuTextColor};
  padding: 10px 20px;

  &:hover {
    background-color: ${({ theme }) => theme.headerDropdownMenuItemsBackgroundHoverColor};
    color: ${({ theme }) => theme.headerDropdownMenuTextHoverColor};
  }

  &:focus {
    background-color: ${({ theme }) => theme.headerDropdownMenuItemsBackgroundFocusColor};
    color: ${({ theme }) => theme.headerDropdownMenuTextFocusColor};
  }
`;

export const DropdownItems = styled.div`
  color: ${({ theme }) => theme.headerDropdownMenuTextColor};
  width: 180px;
  display: flex;
  flex-direction: column;
`;

export const IconChevronRight = styled(ChevronRight)`
  color: ${({ theme }) => theme.iconChevronRightColor};
`;

export const MenuIcon = styled(Menu)`
  color: ${({ theme }) => theme.menuIconColor};
`;

export const NavBarContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.navBarContainerBackgroundColor};
`;

export const NavBarNavigation = styled.div`
  max-width: 1192px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: ${({ theme }) => theme.navBarContainerTextColor};
  background-color: ${({ theme }) => theme.navBarContainerBackgroundColor};
`;

export const NavBarTab = styled(NavLink)`
  transition: 0.2s ease-out;
  text-decoration: none;
  color: ${({ theme }) => theme.navBarTabTextColor};
  margin: 5px 4px;
  padding: 4px 12px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.navBarTabBackgroundColor};
  font-weight: 400;
  font-size: 12px;

  &:focus {
    background-color: ${({ theme }) => theme.navBarTabBackgroundFocusColor};
    color: ${({ theme }) => theme.navBarTabTextFocusColor};
  }

  &:active {
    background-color: ${({ theme }) => theme.navBarTabBackgroundActiveColor};
    color: ${({ theme }) => theme.navBarTabTextActiveColor};
  }

  &:hover {
    background-color: ${({ theme }) => theme.navBarTabBackgroundHoverColor};
    color: ${({ theme }) => theme.navBarTabTextHoverColor};
  }

  text-align: center;
  cursor: pointer;
`;

export const MobileMenu = styled.div`
  display: none;
  @media (max-width: ${SIZE.tablet}) {
    display: block;
    margin: 7px 4px;
  }
`;

export const DesktopMenu = styled.div`
  display: block;
  margin: 23px 4px;
  @media (max-width: ${SIZE.tablet}) {
    display: none;
  }
`;
