import styled from 'styled-components';

export const SwitchButton = styled.div`
  position: relative;
`;

export const SwitchLabelStyled = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 42px;
  height: 22px;
  border-radius: 15px;
  background: ${({ theme }) => theme.switchLabelStyledBackgroundColor};
  cursor: pointer;

  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin: 2px;
    background: ${({ theme }) => theme.switchLabelStyledBackgroundAfterColor};
    transition: 0.2s;
  }
`;

export const SwitchStyled = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 42px;
  height: 19px;

  &:checked + ${SwitchLabelStyled} {
    background: ${({ theme }) => theme.switchColor};

    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      margin-left: 22px;
      transition: 0.2s;
    }
  }
`;

export const SwitchLabel = styled.span`
  color: ${({ theme }) => theme.switchLabelColor};
  font-size: 12px;
`;

export const SwitchWrapper = styled.div`
  width: 100%;
  padding: ${(props) => props.padding || '24px 24px'};
`;

export const SwitchContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;







