import React from 'react';
import PropTypes from 'prop-types';
import { useTable, useRowSelect, useSortBy, useExpanded } from 'react-table';
import { Styles, TableContainer } from './StyledComponents';
import { TableFooterContainer } from '../StyledComponents';
import { PaginationStyled } from '../../PaginationStyled/PaginationStyled';
import { InputStyled } from '../../InputStyled/InputStyled';
import {
  ArrowDownIconStyled,
  ArrowUpIconStyled,
  ArrowDownUpIconStyled,
} from '../../../utils/iconsStyled';

export const StandardTable = (props) => {
  const {
    tableColumns,
    tableData,
    currentPage,
    totalCount,
    itemsPerPage,
    lastPage,
    onHandlePageChange,
    onHandleChangeItemsPerPage,
    header,
    searchItem,
    defaultItemsPerPage,
    onSendItemsPerPage,
  } = props;
  const columns = tableColumns;
  const data = tableData;

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useSortBy,
    useExpanded,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [...columns]);
    },
  );

  const firstPageRows = rows.slice(0, defaultItemsPerPage);
  return (
    <TableContainer>
      {header}
      <Styles>
        <table {...getTableProps()}>
          <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                return (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    {...column.getHeaderProps()}
                  >
                    {column.render('Header')}
                    <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <ArrowUpIconStyled left={'8px'} right={'8px'} size={16} />
                          ) : (
                            <ArrowDownIconStyled left={'8px'} right={'8px'} size={16} />
                          )
                        ) : column.id === 'selection' || column.Header === '' ? (
                          ''
                        ) : (
                          <ArrowDownUpIconStyled left={'8px'} right={'8px'} size={16} />
                        )}
                      </span>
                  </th>
                );
              })}
            </tr>
          ))}
          </thead>
          <tbody {...getTableBodyProps()}>
          {firstPageRows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  const dataLabel = cell.column.name;
                  return (
                    <td data-label={dataLabel} {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
          </tbody>
        </table>
      </Styles>
      <TableFooterContainer>
        <div>
          <PaginationStyled
            onPageChange={onHandlePageChange}
            currentPage={currentPage}
            totalCount={totalCount}
            pageSize={+defaultItemsPerPage}
            lastPage={lastPage}
            searchItem={searchItem}
          />
        </div>
        <div>
          <span>Showing</span>
          <InputStyled
            width={'50px'}
            type='number'
            left={'10px'}
            right={'10px'}
            top={'4px'}
            bottom={'4px'}
            value={itemsPerPage}
            onInput={(e) => e.target.value === '' || e.target.value === '0' ? defaultItemsPerPage : e.target.value = e.target.value.slice(0, 4)}
            onChange={(e) => onHandleChangeItemsPerPage(e.target.value)}
            onKeyDown={(e) => onSendItemsPerPage(e)}
          />
          <span>items per page</span>
        </div>
      </TableFooterContainer>
    </TableContainer>
  );
};

StandardTable.propTypes = {
  tableColumns: PropTypes.array,
  tableData: PropTypes.array,
  currentPage: PropTypes.number,
  totalCount: PropTypes.number,
  itemsPerPage: PropTypes.string,
  lastPage: PropTypes.number,
  onHandlePageChange: PropTypes.func,
  header: PropTypes.object,
  onHandleChangeItemsPerPage: PropTypes.func,
  searchItem: PropTypes.string,
  defaultItemsPerPage: PropTypes.number,
  onSendItemsPerPage: PropTypes.func,
};
