import {
  EditIconStyled,
  TrashIconStyled,
  ArrowCounterclockwiseStyled, DuplicateIconStyled,
} from '../../../../utils/iconsStyled';
import { PillStyled, PillMoreStyled, TooltipStyled } from '../../StyledComponents';
import {
  Styles,
  RowStyled,
  CellStyled,
  TableMobile,
  TableDesktop,
  PaddingStyled,
} from './StyledComponents';
import { Link } from 'react-router-dom';
import { concatName } from '../../../../utils/common';
import { ROUTES } from '../../../../constants/routes';
import { FlexStyled } from '../../../FlexStyled/FlexStyled';

export const getColumnsForRoleManagement = (handleShow, filterStatus, applicationsData) => {
  return [
    ///*****Table Mobile********///
    {
      Header: 'Mobile',
      name: 'Mobile',
      accessor: 'mobile',
      // disableSortBy: true,
      Cell: ({ cell }) => {
        const id = cell.row.original.id;
        const roleTitle = cell.row.original.role_title;
        const applications = cell.row.original.applications;
        const entities = cell.row.original.entities;
        const name = concatName(roleTitle, entities);
        return (
          <TableMobile>
            <RowStyled>
              <CellStyled>
                <span>Id: {id}</span>
              </CellStyled>
            </RowStyled>
            <RowStyled>
              <CellStyled>
                <span>
                  Role Title: <h5>{name}</h5>
                </span>
              </CellStyled>
            </RowStyled>
            <div>
              {applications.map((item, i) => {
                ///View data///
                const viewArr = item.view.slice();
                const viewLength = viewArr.length;
                const viewCountMore = viewLength - 2;
                const showViewItemsArr = viewArr.splice(0, 2);
                const hideViewItemsArr = item.view.slice(2);
                ///Create data///
                const createArr = item.create.slice();
                const createLength = createArr.length;
                const createCountMore = createLength - 2;
                const showCreateItemsArr = createArr.splice(0, 2);
                const hideCreateItemsArr = item.create.slice(2);
                ///Edit data///
                const editArr = item.edit.slice();
                const editLength = editArr.length;
                const editCountMore = editLength - 2;
                const showEditItemsArr = editArr.splice(0, 2);
                const hideEditItemsArr = item.edit.slice(2);
                ///Disable data///
                const disableArr = item.disable.slice();
                const disableLength = disableArr.length;
                const disableCountMore = disableLength - 2;
                const showDisableItemsArr = disableArr.splice(0, 2);
                const hideDisableItemsArr = item.disable.slice(2);

                const id = item.application;
                const application = applicationsData.filter((el) => el.id === id);
                const appName = application.length !== 0 ? application[0].name : '';
                return (
                  <div key={i}>
                    <RowStyled>
                      <span>Application: </span>
                      <CellStyled>
                        <PillStyled right={'7px'} key={i}>
                          {appName}
                        </PillStyled>
                      </CellStyled>
                    </RowStyled>
                    <div>
                      <RowStyled>
                        <span>View: </span>
                        {viewLength <= 2 ? (
                          <CellStyled>
                            {showViewItemsArr.map((item, i) => {
                              return (
                                <CellStyled key={i}>
                                  <PillStyled right={'7px'} key={i}>
                                    {item}
                                  </PillStyled>
                                </CellStyled>
                              );
                            })}
                          </CellStyled>
                        ) : (
                          <CellStyled>
                            {showViewItemsArr.map((item, i) => {
                              return (
                                <CellStyled key={i}>
                                  <PillStyled right={'7px'} key={i}>
                                    {item}
                                  </PillStyled>
                                </CellStyled>
                              );
                            })}
                            <TooltipStyled>
                              <div>
                                <span>
                                  {hideViewItemsArr.map((item, i) => (
                                    <PillStyled key={i}>{item}</PillStyled>
                                  ))}
                                </span>
                                <PillMoreStyled right={'7px'}>{`+${viewCountMore}`}</PillMoreStyled>
                              </div>
                            </TooltipStyled>
                          </CellStyled>
                        )}
                      </RowStyled>
                    </div>
                    <div>
                      <RowStyled>
                        <span>Create: </span>
                        {createLength <= 2 ? (
                          <CellStyled>
                            {showCreateItemsArr.map((item, i) => {
                              return (
                                <CellStyled key={i}>
                                  <PillStyled right={'7px'} key={i}>
                                    {item}
                                  </PillStyled>
                                </CellStyled>
                              );
                            })}
                          </CellStyled>
                        ) : (
                          <CellStyled>
                            {showCreateItemsArr.map((item, i) => {
                              return (
                                <CellStyled key={i}>
                                  <PillStyled right={'7px'} key={i}>
                                    {item}
                                  </PillStyled>
                                </CellStyled>
                              );
                            })}
                            <TooltipStyled>
                              <div>
                                <span>
                                  {hideCreateItemsArr.map((item, i) => (
                                    <PillStyled key={i}>{item}</PillStyled>
                                  ))}
                                </span>
                                <PillMoreStyled
                                  right={'7px'}
                                >{`+${createCountMore}`}</PillMoreStyled>
                              </div>
                            </TooltipStyled>
                          </CellStyled>
                        )}
                      </RowStyled>
                    </div>
                    <div>
                      <RowStyled>
                        <span>Edit: </span>
                        {editLength <= 2 ? (
                          <CellStyled>
                            {showEditItemsArr.map((item, i) => {
                              return (
                                <CellStyled key={i}>
                                  <PillStyled right={'7px'} key={i}>
                                    {item}
                                  </PillStyled>
                                </CellStyled>
                              );
                            })}
                          </CellStyled>
                        ) : (
                          <CellStyled>
                            {showEditItemsArr.map((item, i) => {
                              return (
                                <CellStyled key={i}>
                                  <PillStyled right={'7px'} key={i}>
                                    {item}
                                  </PillStyled>
                                </CellStyled>
                              );
                            })}
                            <TooltipStyled>
                              <div>
                                <span>
                                  {hideEditItemsArr.map((item, i) => (
                                    <PillStyled key={i}>{item}</PillStyled>
                                  ))}
                                </span>
                                <PillMoreStyled right={'7px'}>{`+${editCountMore}`}</PillMoreStyled>
                              </div>
                            </TooltipStyled>
                          </CellStyled>
                        )}
                      </RowStyled>
                    </div>
                    <div>
                      <RowStyled>
                        <span>Disable: </span>
                        {disableLength <= 2 ? (
                          <CellStyled>
                            {showDisableItemsArr.map((item, i) => {
                              return (
                                <CellStyled key={i}>
                                  <PillStyled right={'7px'} key={i}>
                                    {item}
                                  </PillStyled>
                                </CellStyled>
                              );
                            })}
                          </CellStyled>
                        ) : (
                          <CellStyled>
                            {showDisableItemsArr.map((item, i) => {
                              return (
                                <CellStyled key={i}>
                                  <PillStyled right={'7px'} key={i}>
                                    {item}
                                  </PillStyled>
                                </CellStyled>
                              );
                            })}
                            <TooltipStyled>
                              <div>
                                <span>
                                  {hideDisableItemsArr.map((item, i) => (
                                    <PillStyled key={i}>{item}</PillStyled>
                                  ))}
                                </span>
                                <PillMoreStyled
                                  right={'7px'}
                                >{`+${disableCountMore}`}</PillMoreStyled>
                              </div>
                            </TooltipStyled>
                          </CellStyled>
                        )}
                      </RowStyled>
                    </div>
                  </div>
                );
              })}
            </div>
            <RowStyled>
              <CellStyled justify={'flex-end'}>
                {filterStatus.value !== 'disabled' ? (
                  <div>
                    <Link to={`/roleManagement/editRole${id}`}>
                      <EditIconStyled data-id={id} size={20} right={'15px'} />
                    </Link>

                    <Link to={{ pathname: ROUTES.duplicateRole, state: id }}>
                      <DuplicateIconStyled
                        data-id={id}
                        size={20}
                        right={'15px'}
                      />
                    </Link>

                    <TrashIconStyled
                      data-id={id}
                      size={20}
                      onClick={() => handleShow(id, 'disable')}
                    />
                  </div>
                ) : (
                  <ArrowCounterclockwiseStyled
                    data-id={id}
                    size={22}
                    onClick={() => handleShow(id, 'restore')}
                  />
                )}
              </CellStyled>
            </RowStyled>
          </TableMobile>
        );
      },
    },

    ///*****TableDesktop********///
    {
      name: 'Checkbox',
      id: 'selection',
      Header: () => {
        return <PaddingStyled></PaddingStyled>;
      },
      Cell: () => {
        return (
          <Styles>
            <RowStyled>
              <CellStyled></CellStyled>
            </RowStyled>
          </Styles>
        );
      },
    },
    {
      Header: 'ID',
      name: 'Id',
      accessor: 'id',
      Cell: ({ cell }) => {
        const id = cell.row.original.id;
        return (
          <TableDesktop>
            <Styles>
              <RowStyled>
                <CellStyled>
                  <span>{id}</span>
                </CellStyled>
              </RowStyled>
            </Styles>
          </TableDesktop>
        );
      },
    },
    {
      Header: 'Role Title',
      name: 'Role Title',
      accessor: 'role_title',
      Cell: ({ cell }) => {
        const roleTitle = cell.row.original.role_title;
        const entities = cell.row.original.entities;
        const name = concatName(roleTitle, entities);
        return (
          <TableDesktop>
            <Styles>
              <RowStyled>
                <CellStyled>
                  <span>{name}</span>
                </CellStyled>
              </RowStyled>
            </Styles>
          </TableDesktop>
        );
      },
    },
    {
      Header: 'Application',
      name: 'Application',
      accessor: 'application',
      Cell: ({ cell }) => {
        const data = cell.row.original.applications;
        return (
          <TableDesktop>
            <Styles>
              <RowStyled>
                {data.length !== 0 ? (
                  data.map((item, i) => {
                    const id = item.application;
                    const application = applicationsData.filter((el) => el.id === id);
                    const appName = application.length !== 0 ? application[0].name : '';
                    return (
                      <CellStyled key={i}>
                        <span>{appName}</span>
                      </CellStyled>
                    );
                  })
                ) : (
                  <CellStyled></CellStyled>
                )}
              </RowStyled>
            </Styles>
          </TableDesktop>
        );
      },
    },
    {
      Header: 'View',
      name: 'View',
      accessor: 'view',
      Cell: ({ cell }) => {
        const data = cell.row.original.applications;
        return (
          <TableDesktop>
            <Styles>
              <RowStyled>
                {data.map((item, i) => {
                  const arrItems = item.view.slice();
                  const lengthArr = arrItems.length;
                  const countMore = lengthArr - 2;
                  const showItemsArr = arrItems.splice(0, 2);
                  const hideItemsArr = item.view.slice(2);
                  if (lengthArr > 2) {
                    return (
                      <CellStyled key={i}>
                        {showItemsArr.map((item, i) => {
                          return (
                            <PillStyled right={'7px'} key={i}>
                              {item}
                            </PillStyled>
                          );
                        })}
                        <TooltipStyled>
                          <div>
                            <span>
                              {hideItemsArr.map((item, i) => (
                                <PillStyled key={i}>{item}</PillStyled>
                              ))}
                            </span>
                            <PillMoreStyled right={'7px'}>{`+${countMore}`}</PillMoreStyled>
                          </div>
                        </TooltipStyled>
                      </CellStyled>
                    );
                  } else {
                    return (
                      <CellStyled key={i}>
                        {showItemsArr.map((item, i) => {
                          return (
                            <div key={i}>
                              <PillStyled right={'7px'} key={i}>
                                {item}
                              </PillStyled>
                            </div>
                          );
                        })}
                      </CellStyled>
                    );
                  }
                })}
              </RowStyled>
            </Styles>
          </TableDesktop>
        );
      },
    },
    {
      Header: 'Create',
      name: 'Create',
      accessor: 'create',
      Cell: ({ cell }) => {
        const data = cell.row.original.applications;
        return (
          <TableDesktop>
            <Styles>
              <RowStyled>
                {data.length !== 0
                  ? data.map((item, i) => {
                    const arrItems = item.create.slice();
                    const lengthArr = arrItems.length;
                    const countMore = lengthArr - 2;
                    const showItemsArr = arrItems.splice(0, 2);
                    const hideItemsArr = item.create.slice(2);
                    if (lengthArr > 2) {
                      return (
                        <CellStyled key={i}>
                          {showItemsArr.map((item, i) => {
                            return (
                              <PillStyled right={'7px'} key={i}>
                                {item}
                              </PillStyled>
                            );
                          })}

                          <TooltipStyled>
                            <div>
                                <span>
                                  {hideItemsArr.map((item, i) => (
                                    <PillStyled key={i}>{item}</PillStyled>
                                  ))}
                                </span>
                              <PillMoreStyled right={'7px'}>{`+${countMore}`}</PillMoreStyled>
                            </div>
                          </TooltipStyled>
                        </CellStyled>
                      );
                    } else {
                      return (
                        <CellStyled key={i}>
                          {showItemsArr.map((item, i) => {
                            return (
                              <PillStyled right={'7px'} key={i}>
                                {item}
                              </PillStyled>
                            );
                          })}
                        </CellStyled>
                      );
                    }
                  })
                  : ''}
              </RowStyled>
            </Styles>
          </TableDesktop>
        );
      },
    },
    {
      Header: 'Edit',
      name: 'Edit',
      accessor: 'edit',
      Cell: ({ cell }) => {
        const data = cell.row.original.applications;
        return (
          <TableDesktop>
            <Styles>
              <RowStyled>
                {data.length !== 0
                  ? data.map((item, i) => {
                    const arrItems = item.edit.slice();
                    const lengthArr = arrItems.length;
                    const countMore = lengthArr - 2;
                    const showItemsArr = arrItems.splice(0, 2);
                    const hideItemsArr = item.edit.slice(2);
                    if (lengthArr > 2) {
                      return (
                        <CellStyled key={i}>
                          {showItemsArr.map((item, i) => {
                            return (
                              <PillStyled right={'7px'} key={i}>
                                {item}
                              </PillStyled>
                            );
                          })}
                          <TooltipStyled>
                            <div>
                                <span>
                                  {hideItemsArr.map((item, i) => (
                                    <PillStyled key={i}>{item}</PillStyled>
                                  ))}
                                </span>
                              <PillMoreStyled right={'7px'}>{`+${countMore}`}</PillMoreStyled>
                            </div>
                          </TooltipStyled>
                        </CellStyled>
                      );
                    } else {
                      return (
                        <CellStyled key={i}>
                          {showItemsArr.map((item, i) => {
                            return (
                              <PillStyled right={'7px'} key={i}>
                                {item}
                              </PillStyled>
                            );
                          })}
                        </CellStyled>
                      );
                    }
                  })
                  : ''}
              </RowStyled>
            </Styles>
          </TableDesktop>
        );
      },
    },
    {
      Header: 'Disable',
      name: 'Disable',
      accessor: 'disable',
      Cell: ({ cell }) => {
        const data = cell.row.original.applications;
        return (
          <TableDesktop>
            <Styles>
              <RowStyled>
                {data.length !== 0
                  ? data.map((item, i) => {
                    const arrItems = item.disable.slice();
                    const lengthArr = arrItems.length;
                    const countMore = lengthArr - 2;
                    const showItemsArr = arrItems.splice(0, 2);
                    const hideItemsArr = item.disable.slice(2);
                    if (lengthArr > 2) {
                      return (
                        <CellStyled key={i}>
                          {showItemsArr.map((item, i) => {
                            return (
                              <PillStyled right={'7px'} key={i}>
                                {item}
                              </PillStyled>
                            );
                          })}
                          <TooltipStyled>
                            <div>
                                <span>
                                  {hideItemsArr.map((item, i) => (
                                    <PillStyled key={i}>{item}</PillStyled>
                                  ))}
                                </span>
                              <PillMoreStyled right={'7px'}>{`+${countMore}`}</PillMoreStyled>
                            </div>
                          </TooltipStyled>
                        </CellStyled>
                      );
                    } else {
                      return (
                        <CellStyled key={i}>
                          {showItemsArr.map((item, i) => {
                            return (
                              <PillStyled right={'7px'} key={i}>
                                {item}
                              </PillStyled>
                            );
                          })}
                        </CellStyled>
                      );
                    }
                  })
                  : ''}
              </RowStyled>
            </Styles>
          </TableDesktop>
        );
      },
    },
    {
      Header: '',
      name: '',
      id: 'button',
      Cell: ({ cell }) => {
        const id = cell.row.original.id;
        return (
          <RowStyled>
            <CellStyled justify={'flex-end'}>
              {filterStatus.value !== 'disabled' ? (
                <FlexStyled direction={'row'}>
                  <Link to={`/roleManagement/editRole${id}`}>
                    <EditIconStyled data-id={id} size={20} right={'15px'} />
                  </Link>
                  <Link to={{ pathname: ROUTES.duplicateRole, state: id }}>
                    <DuplicateIconStyled
                      data-id={id}
                      size={20}
                      right={'15px'}
                    />
                  </Link>
                  <TrashIconStyled
                    data-id={id}
                    size={20}
                    onClick={() => handleShow(id, 'disable')}
                  />
                </FlexStyled>
              ) : (
                <ArrowCounterclockwiseStyled
                  data-id={id}
                  size={22}
                  onClick={() => handleShow(id, 'restore')}
                />
              )}
            </CellStyled>
          </RowStyled>
        );
      },
    },
  ];
};
