import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Header } from '../../components/Header/Header';
import { BackgroundStyled } from '../../components/BackgroundStyled/BackgroundStyled';
import { StandardTable } from '../../components/Tables/StandardTable/StandardTable';
import {
  getUsersList,
  setFilterStatus,
  setSelectedUsers,
} from '../../redux/actions/userManagement';
import { setUserData } from '../../redux/actions/login';
import { Api, API_PATHS, ROOT_URL } from '../../api/Api';
import { getColumnsForUserManagement } from '../../components/Tables/StandardTable/columns/userManagementColumns';
import { UserManagementHeader } from '../../components/Tables/StandardTable/headers/UserManagementHeader';
import { Modals } from '../../components/Modals/Modals';
import { AxiosFactory } from '../../api/axios/AxiosFactory';
import { handleResponseError } from '../../api/axios/interceptors/responseError';
import store from '../../redux/store';
import { setIsLoading } from '../../redux/actions/spinner';
import { showToastWithTimeout } from '../../utils/common';
import { setUserDataToLocalStorage } from '../../utils/auth';

export const UserManagement = () => {
  const FIRST_PAGE = 1;

  const [show, setShow] = useState(false);
  const [currentUserId, setCurrentUserId] = useState('');
  const [modalName, setModalName] = useState('');
  const [countItemsPerPage, setCountItemsPerPage] = useState('');
  const [searchItem, setSearchItem] = useState('');

  const api = useMemo(() => new Api(), []);

  const theme = useSelector((state) => state.theme.theme);
  const usersList = useSelector((state) => state.userManagement.usersList);
  const currentPage = useSelector((state) => state.userManagement.currentPage);
  const lastPage = useSelector((state) => state.userManagement.lastPage);
  const firstPage = useSelector((state) => state.userManagement.firstPage);
  const nextPage = useSelector((state) => state.userManagement.nextPage);
  const prevPage = useSelector((state) => state.userManagement.prevPage);
  const totalCount = useSelector((state) => state.userManagement.totalCount);
  const filterStatus = useSelector((state) => state.userManagement.filterStatus);
  const selectedUsers = useSelector((state) => state.userManagement.selectedUsers);
  const defaultItemsPerPage = useSelector((state) => state.userManagement.defaultItemsPerPage);
  const userData = useSelector((state) => state.login.userData);
  const itemsPerPage = useSelector((state) => state.login.userData.user_settings.items_per_page);

  const axiosInstance = new AxiosFactory()
    .addInterceptor(handleResponseError)
    .getInstance();

  const dispatch = useDispatch();
  useEffect(() => {
    let unmounted = false;

    const users = api.getUsers(FIRST_PAGE, searchItem, filterStatus.value);
    users.then((response) => {
      if (!unmounted && response) {
        dispatch(getUsersList(response));
      }
    });

    return () => {
      unmounted = true;
    };
  }, [api, dispatch, searchItem, filterStatus, countItemsPerPage]);

  const handleChangeItemsPerPage = (value) => {
    const updatedUserData = { ...userData };
    updatedUserData.user_settings.items_per_page = value;
    setUserDataToLocalStorage(updatedUserData);
    dispatch(setUserData(updatedUserData));
  };

  const handleSendItemsPerPage = async (e) => {
    if (itemsPerPage !== '' && e.key === 'Enter') {
      await api.patchItemsPerPage({ 'items_per_page': +itemsPerPage });
      setCountItemsPerPage(itemsPerPage);
      await api.userInfo();
    }
  };

  const handleChangeNameSearch = (value) => {
    dispatch(setSelectedUsers([]));
    setSearchItem(value);
  };

  const handlePageChange = (page, searchItem) => {
    dispatch(setSelectedUsers([]));
    const users = api.getUsers(page, searchItem, filterStatus.value);
    users.then((response) => {
      if (response) {
        dispatch(getUsersList(response));
      }
    });
  };

  const handleDeleteUsers = (id) => {
    const users = { users: [id].flat() };
    const deleteUsers = api.deleteUsers(users);
    deleteUsers.then((response) => {
      if (response?.status === 200) {
        const users = api.getUsers(FIRST_PAGE, searchItem, filterStatus.value);
        users.then((response) => {
          if (response) {
            dispatch(setSelectedUsers([]));
            dispatch(getUsersList(response));
          }
        });
      }
    });
  };

  const handleActivateUsers = (idsArr) => {
    const formattedArr = [];
    idsArr.forEach(el => formattedArr.push({
      'id': el,
      'status': 1,
    }));
    const usersObject = {
      'users': formattedArr,
    };
    const activateUsers = api.patchCollectionUserStatus(usersObject);
    activateUsers.then((response) => {
      if (response?.status === 200) {
        const users = api.getUsers(FIRST_PAGE, searchItem, filterStatus.value);
        users.then((response) => {
          if (response) {
            dispatch(setSelectedUsers([]));
            dispatch(getUsersList(response));
          }
        });
      }
    });
  };

  const handleRestoreUser = (id) => {
    const restoreUser = api.restoreUser(id);
    restoreUser.then((response) => {
      if (response?.status === 200) {
        const users = api.getUsers(FIRST_PAGE, searchItem, filterStatus.value);
        users.then((response) => {
          if (response) {
            dispatch(getUsersList(response));
          }
        });
      }
    });
  };

  const handleClose = () => setShow(false);

  const handleShow = (userId, modalName) => {
    setModalName(modalName);
    setCurrentUserId(userId);
    setShow(true);
  };

  const handleSelectUser = (userId) => {
    if (selectedUsers.includes(userId) === true) {
      let checkedArray = [...selectedUsers];
      const index = checkedArray.indexOf(userId);
      checkedArray.splice(index, 1);
      dispatch(setSelectedUsers(checkedArray));
    } else {
      let checkedArray = [...selectedUsers];
      checkedArray.push(userId);
      dispatch(setSelectedUsers(checkedArray));
    }
  };

  const handleSelectAllUsers = () => {
    if (selectedUsers.length < usersList.length) {
      let userIdsList = [];
      usersList.forEach((user) => userIdsList.push(user.id));
      dispatch(setSelectedUsers(userIdsList));
    } else {
      dispatch(setSelectedUsers([]));
    }
  };

  const handleSelectedFilterStatus = (name, value) => {
    dispatch(setSelectedUsers([]));
    dispatch(setFilterStatus(value));
  };

  const handleCsvExample = () => {
    window.open(ROOT_URL + API_PATHS.importCsvPaths.example, '_blank');
  };

  /**
   * @param {File} fileUploaded
   */
  const handleCsvImport = (fileUploaded) => {
    const formData = new FormData();
    formData.append('file', fileUploaded, fileUploaded.name);

    const requestConfig = {
      method: 'POST',
      url: ROOT_URL + API_PATHS.importCsvPaths.import,
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    };

    store.dispatch(setIsLoading(true));

    axiosInstance(requestConfig)
      .then((response) => {
        const users = api.getUsers(FIRST_PAGE, searchItem, filterStatus.value);

        users.then((response) => {
          if (response) {
            dispatch(getUsersList(response));
          }
        });

        showToastWithTimeout({
          success: true,
          text: 'Created: ' + response.data?.count_created + ' Skipped: ' + response.data?.count_skipped,
          isShown: 'visible',
          timeout: 5000,
        });
      })
      .catch((error) => {
        showToastWithTimeout({
          success: false,
          text: error.message,
          isShown: 'visible',
          timeout: 5000,
        });
      })
      .then(() => {
        store.dispatch(setIsLoading(false));
      });
  };

  return (
    <BackgroundStyled display={'block'}>
      <Header />
      {modalName === 'disableAll' && (
        <Modals
          onHandleClose={handleClose}
          show={show}
          onHandleSuccess={() => handleDeleteUsers(selectedUsers)}
          primaryButtonName={'Disable'}
          secondaryButtonName={'Close'}
          message={'Are you sure you want to disable selected users?'}
        />
      )}
      {modalName === 'disable' && (
        <Modals
          onHandleClose={handleClose}
          show={show}
          onHandleSuccess={() => handleDeleteUsers([currentUserId])}
          primaryButtonName={'Disable'}
          secondaryButtonName={'Close'}
          message={'Are you sure you want to disable this user?'}
        />
      )}
      {modalName === 'restore' && (
        <Modals
          onHandleClose={handleClose}
          show={show}
          onHandleSuccess={() => handleRestoreUser(currentUserId)}
          primaryButtonName={'Restore'}
          secondaryButtonName={'Close'}
          message={'Are you sure you want to restore this user?'}
        />
      )}
      {modalName === 'activateUsers' && (
        <Modals
          onHandleClose={handleClose}
          show={show}
          onHandleSuccess={() => handleActivateUsers(selectedUsers)}
          primaryButtonName={'Activate Users'}
          secondaryButtonName={'Close'}
          message={'Are you sure you want to activate these users?'}
        />
      )}
      <StandardTable
        tableColumns={getColumnsForUserManagement(
          handleShow,
          handleSelectUser,
          handleSelectAllUsers,
          handleRestoreUser,
          filterStatus,
          selectedUsers,
        )}
        tableData={usersList}
        currentPage={currentPage}
        lastPage={lastPage}
        firstPage={firstPage}
        nextPage={nextPage}
        prevPage={prevPage}
        totalCount={totalCount}
        itemsPerPage={`${itemsPerPage}`}
        defaultItemsPerPage={defaultItemsPerPage}
        onHandlePageChange={handlePageChange}
        onSendItemsPerPage={handleSendItemsPerPage}
        header={
          <UserManagementHeader
            onHandleChangeNameSearch={handleChangeNameSearch}
            selectedUsers={selectedUsers}
            onHandleShow={handleShow}
            theme={theme}
            filterStatus={filterStatus}
            onHandleSelectedFilterStatus={handleSelectedFilterStatus}
            onHandleCsvImport={handleCsvImport}
            onHandleCsvExample={handleCsvExample}
          />
        }
        onHandleChangeItemsPerPage={handleChangeItemsPerPage}
        searchItem={searchItem}
      />
    </BackgroundStyled>
  );
};
