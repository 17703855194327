import PropTypes from 'prop-types';
import { IconErrorStyled } from '../../utils/iconsStyled';
import { Container, ErrorMessage } from './StyledComponents';
import { InputStyled } from '../InputStyled/InputStyled';

export const InputValidation = ({
                                  field,
                                  form: { errors, touched },
                                  type,
                                  width,
                                  placeholder,
                                  onChange,
                                  name,
                                  id,
                                  value,
                                }) => {
  return (
    <Container width={width}>
      {value === undefined ? (
        <InputStyled
          {...field}
          width={width}
          placeholder={placeholder}
          type={type}
          onChange={onChange}
          value={field.value === undefined ? '' : field.value}
          name={name}
          id={id}
          autoComplete='off'
        />
      ) : (
        <InputStyled
          {...field}
          width={width}
          placeholder={placeholder}
          type={type}
          onChange={onChange}
          value={value}
          name={name}
          id={id}
          autoComplete='off'
        />
      )}

      {errors[field.name] && touched[field.name] ? (
        <div>
          <ErrorMessage width={width}>{errors[field.name]}</ErrorMessage>
          <div>
            <IconErrorStyled size={18} position={'absolute'} right={'5px'} top={'5px'} />
          </div>
        </div>
      ) : null}
    </Container>
  );
};

InputValidation.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  type: PropTypes.string,
  width: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  id: PropTypes.string,
};
