import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Header } from '../../components/Header/Header';
import { BackgroundStyled } from '../../components/BackgroundStyled/BackgroundStyled';
import { StandardTable } from '../../components/Tables/StandardTable/StandardTable';
import {
  getOrganizationsList,
} from '../../redux/actions/organizations';
import { Api } from '../../api/Api';
import { getColumnsForOrganizations } from '../../components/Tables/StandardTable/columns/organizationsColumns';
import { OrganizationsHeader } from '../../components/Tables/StandardTable/headers/OrganizationsHeader';
import { setUserDataToLocalStorage } from '../../utils/auth';
import { setUserData } from '../../redux/actions/login';
import { ROUTES } from '../../constants/routes';

export const Organizations = () => {
  const FIRST_PAGE = 1;
  const dispatch = useDispatch();
  const api = useMemo(() => new Api(), []);
  const [countItemsPerPage, setCountItemsPerPage] = useState('');
  const [searchItem, setSearchItem] = useState('');
  const [configSettings, setConfigSettings] = useState('');

  const organizationsList = useSelector((state) => state.organizations.organizationsList);
  const currentPage = useSelector((state) => state.organizations.currentPage);
  const lastPage = useSelector((state) => state.organizations.lastPage);
  const firstPage = useSelector((state) => state.organizations.firstPage);
  const nextPage = useSelector((state) => state.organizations.nextPage);
  const prevPage = useSelector((state) => state.organizations.prevPage);
  const totalCount = useSelector((state) => state.organizations.totalCount);
  const defaultItemsPerPage = useSelector((state) => state.organizations.defaultItemsPerPage);
  const userData = useSelector((state) => state.login.userData);
  const itemsPerPage = useSelector((state) => state.login.userData.user_settings.items_per_page);

  useEffect(() => {
    let unmounted = false;

    const organizations = api.getEntitiesPaginated(FIRST_PAGE, searchItem);
    organizations.then((response) => {
      if (!unmounted && response) {
        dispatch(getOrganizationsList(response));
      }
    });

    const config = api.getConfig();

    config.then((response) => {
      if (!unmounted && response) {
        setConfigSettings(response);
      }
    });

    return () => {
      unmounted = true;
    };
  }, [api, dispatch, searchItem, countItemsPerPage]);

  const handlePageChange = (page, searchItem) => {
    const permissions = api.getEntitiesPaginated(page, searchItem);
    permissions.then((response) => {
      if (response) {
        dispatch(getOrganizationsList(response));
      }
    });
  };

  const handleChangeItemsPerPage = (value) => {
    const updatedUserData = { ...userData };
    updatedUserData.user_settings.items_per_page = value;
    setUserDataToLocalStorage(updatedUserData);
    dispatch(setUserData(updatedUserData));
  };

  const handleSendItemsPerPage = async (e) => {
    if (itemsPerPage !== '' && e.key === 'Enter') {
      await api.patchItemsPerPage({ 'items_per_page': +itemsPerPage });
      setCountItemsPerPage(itemsPerPage);
      await api.userInfo();
    }
  };

  const handleChangeNameSearch = (value) => {
    setSearchItem(value);
  };

  const ROOT_URL = process.env.REACT_APP_URL_COPA;

  const handleEditOrganization = (entityType, entityName) => {
    const urlIbiza = configSettings.data.url;
    const urlCopa = `${ROOT_URL}${ROUTES.organizations}`;
    const redirect = `${urlIbiza}/master-data-submissions/create?entity_type=${entityType}&entity_name=${entityName}&redirect_to_url=${urlCopa}`;
    window.open(redirect, '_self');
  };

  return (
    <BackgroundStyled display={'block'}>
      <Header />
      <StandardTable
        tableColumns={getColumnsForOrganizations(handleEditOrganization)}
        tableData={organizationsList}
        currentPage={currentPage}
        lastPage={lastPage}
        firstPage={firstPage}
        nextPage={nextPage}
        prevPage={prevPage}
        totalCount={totalCount}
        itemsPerPage={`${itemsPerPage}`}
        defaultItemsPerPage={defaultItemsPerPage}
        onHandlePageChange={handlePageChange}
        onSendItemsPerPage={handleSendItemsPerPage}
        header={
          <OrganizationsHeader
            onHandleChangeNameSearch={handleChangeNameSearch}
          />
        }
        onHandleChangeItemsPerPage={handleChangeItemsPerPage}
        searchItem={searchItem}
      />
    </BackgroundStyled>
  );
};
