import { PERMISSIONS } from './permission';

export const ROUTES = {
  organizations: '/organizations',
  addOrganization: '/organizations/addOrganization',
  // editOrganization: '/organizations/editOrganization:organizationId',
  userManagement: '/userManagement',
  addUser: '/userManagement/addUser',
  editUser: '/userManagement/EditUser:userId',
  roleManagement: '/roleManagement',
  editRole: '/roleManagement/editRole:userId',
  addRole: '/roleManagement/addRole',
  duplicateRole: '/roleManagement/duplicateRole',
  permissions: '/permissions',
  editPermission: '/permissions/editPermission:userId',
  addPermission: '/permissions/addPermission',
  login: '/login',
  welcome: '/welcome',
  resetPassword: '/reset-password',
  forgotPassword: '/forgot-password',
  setPassword: '/set-password',
  userSettings: '/userSettings',
  logs: '/logs',
  accessDenied: '/access-denied',
  twoFactor: '/twoFactor',
  twoFactorAuth: '/twoFactorAuth',
  selectOrganization: '/selectOrganization',
};

export const ALLOWED_ROUTES_AFTER_LOGIN = [{
  permission: PERMISSIONS.copa_access_to_organization,
  route: ROUTES.organizations,
}, {
  permission: PERMISSIONS.copa_access_to_users,
  route: ROUTES.userManagement,
}, {
  permission: PERMISSIONS.copa_access_to_roles,
  route: ROUTES.roleManagement,
}, {
  permission: PERMISSIONS.copa_access_to_permissions,
  route: ROUTES.permissions,
}];