import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Background = styled.div`
  width: ${(props) => props.width || '100%'};
  min-height: 100vh;
  background: ${({ theme }) => theme.backgroundColor};
  display: ${(props) => props.display || 'flex'};
  flex-direction: ${(props) => props.direction || 'row'};
  align-items: ${(props) => props.align || 'center'};
  justify-content: ${(props) => props.justify || 'center'};
`;

export const BackgroundStyled = (props) => {
  return <Background {...props} />;
};

BackgroundStyled.propTypes = {
  display: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  direction: PropTypes.string,
  align: PropTypes.string,
  justify: PropTypes.string,
};
