import styled from 'styled-components';
import { SIZE } from '../../../../constants/size';

export const Styles = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  img {
    height: 24px;
    border-radius: 50%;
  }
  h3 {
    font-weight: 600;
    font-size: 12px;
    margin: 0 0 0 10px;
  }
  h4 {
    font-weight: 400;
    font-size: 12px;
    margin: 0 0 0 10px;
  }
  @media (max-width: ${SIZE.tablet}) {
    justify-content: flex-end;
  }
`;

export const RowStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  @media (max-width: ${SIZE.tablet}) {
    flex-direction: row;
    align-items: center;
    border-bottom: ${({ theme }) => `1px solid ${theme.tableBorderBottomColor}`};
    justify-content: space-between;
  }
`;

export const CellStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-bottom: ${({ theme }) => `1px solid ${theme.tableBorderBottomColor}`};
  height: 50px;
  &:last-child {
    border-bottom: 0px;
  }
  @media (max-width: ${SIZE.tablet}) {
    border-bottom: 0px;
    flex-direction: row;
    height: none;
  }
`;

export const PaddingStyled = styled.div`
  padding-left: 20px;
`;

export const TableMobile = styled.div`
  display: none;
  @media (max-width: ${SIZE.tablet}) {
    display: block;
  }
`;

export const TableDesktop = styled.div`
  display: block;
  @media (max-width: ${SIZE.tablet}) {
    display: none;
  }
`;
