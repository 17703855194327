import { showErrorToast } from '../utils/common';
import { axiosInstance, API_URL, API_URL_OLD } from './ApiConfig';

const API_PATHS = {
  TWO_FA_URL: API_URL + '/2fa/url',
  TWO_FA_URL_IMAGE: API_URL + '/2fa/image',
  TWO_FA_ENABLE: API_URL + '/2fa/enable',
  TWO_FA_AUTHENTICATE: API_URL + '/2fa/authenticate',
  TWO_FA_DISABLE: API_URL_OLD + '/2fa/disable',
};

export class TwoFactor {

  async handleApiCall(requestConfig = {}) {
    try {
      return await axiosInstance(requestConfig);

    } catch (error) {
      showErrorToast(error.response?.data?.message || '2FA api error has occurred');

      if (process.env.NODE_ENV !== 'production') {
        console.log('TwoFactor Error: ' + error.response?.data?.message);
      }
    }
  }

  async getUrl() {
    return this.handleApiCall({
      method: 'GET',
      url: API_PATHS.TWO_FA_URL,
    });
  }

  async getUrlImage() {
    return this.handleApiCall({
      method: 'GET',
      url: API_PATHS.TWO_FA_URL_IMAGE,
    });
  }

  async enable(verifyCode) {
    return this.handleApiCall({
      method: 'PATCH',
      url: API_PATHS.TWO_FA_ENABLE,
      data: { verify_code: verifyCode },
    });
  }

  async authenticate(verifyCode) {
    return this.handleApiCall({
      method: 'POST',
      url: API_PATHS.TWO_FA_AUTHENTICATE,
      data: { verify_code: verifyCode },
    });
  }

  async disable(userPassword) {
    return this.handleApiCall({
      method: 'PATCH',
      url: API_PATHS.TWO_FA_DISABLE,
      data: { password: userPassword },
    });
  }
}