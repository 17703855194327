import { showErrorToast } from '../utils/common';
import { axiosInstance, API_URL } from './ApiConfig';

const API_PATHS = {
  LOGIN: API_URL + '/login',
  SET_PASSWORD: API_URL + '/set-password',
};

export class ApiAuth {

  async handleApiCall(requestConfig = {}) {
    try {
      return await axiosInstance(requestConfig);

    } catch (error) {
      showErrorToast(error.response?.data?.message || 'An error has occurred');

      if (process.env.NODE_ENV !== 'production') {
        console.log('ApiAuth: ' + error.response?.data?.message);
      }
    }
  }

  async login(user) {
    return this.handleApiCall({
      method: 'POST',
      url: API_PATHS.LOGIN,
      data: user,
    });
  }

  async setNewPassword(newPassword) {
    return this.handleApiCall({
      method: 'POST',
      url: API_PATHS.SET_PASSWORD,
      data: newPassword,
    });
  }
}