import React from 'react';
import PropTypes from 'prop-types';
import { Input, Label, Checkmark, Container, AttributeContainer } from './StyledComponents';

export const RadioButtonList = (props) => {
  const { radioButtonSettings, checkedValue, onChangeValue, name } = props;

  const renderRadioButtons = () => {
    if (radioButtonSettings) {
      return radioButtonSettings.map((item) => {
        return (
          <AttributeContainer key={item.id}>
            <Label>{item.label}
              <Input
                type='radio'
                name={name}
                id={item.id}
                value={item.value}
                checked={checkedValue === `${item.value}`}
                onChange={onChangeValue} />
              <Checkmark>
                <div />
              </Checkmark>
            </Label>
          </AttributeContainer>
        );
      });
    }
  };

  return (
    <div>
      <Container>{renderRadioButtons()}</Container>
    </div>
  );
};

RadioButtonList.propTypes = {
  radioButtonSettings: PropTypes.array,
  checkedValue: PropTypes.string,
  onChangeValue: PropTypes.func,
  name: PropTypes.string,
};