import * as yup from 'yup';
import {
  LOGIN_MESSAGES,
  USER_MESSAGES,
  ROLE_MESSAGES,
  PERMISSION_MESSAGES,
  QR_CODE_MESSAGES, ORGANIZATION_MESSAGES,
} from '../constants/messages';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const loginValidation = yup.object({
  email: yup
    .string(LOGIN_MESSAGES.email)
    .email(LOGIN_MESSAGES.validEmail)
    .required(LOGIN_MESSAGES.requiredEmail),
  password: yup
    .string(LOGIN_MESSAGES.password)
    .min(LOGIN_MESSAGES.maxLength, LOGIN_MESSAGES.minLengthPassword)
    .required(LOGIN_MESSAGES.requiredPassword),
  terms: yup.bool().required().oneOf([true], LOGIN_MESSAGES.terms),
});

export const resetPasswordValidation = yup.object({
  email: yup
    .string(LOGIN_MESSAGES.email)
    .email(LOGIN_MESSAGES.validEmail)
    .required(LOGIN_MESSAGES.requiredEmail),
});

export const qrCodeValidation = yup.object({
  code: yup.string().required(QR_CODE_MESSAGES.requiredCode),
});

export const addUserValidation = yup.object().shape({
  email: yup
    .string(USER_MESSAGES.email)
    .email(USER_MESSAGES.validEmail)
    .required(USER_MESSAGES.requiredEmail),
  first_name: yup
    .string(USER_MESSAGES.firstName)
    .required(USER_MESSAGES.requiredFirstName),
  last_name: yup
    .string(USER_MESSAGES.lastName)
    .required(USER_MESSAGES.requiredLastName),
  phone_number: yup
    .string(USER_MESSAGES.phone)
    .required(USER_MESSAGES.requiredPhone)
    .min(5, USER_MESSAGES.phoneMinLength)
    .max(15, USER_MESSAGES.phoneMaxLength)
    .matches(phoneRegExp, USER_MESSAGES.phoneRegExp),
});

export const editUserValidation = yup.object().shape({
  email: yup
    .string(USER_MESSAGES.email)
    .email(USER_MESSAGES.validEmail)
    .required(USER_MESSAGES.requiredEmail),
  first_name: yup
    .string(USER_MESSAGES.firstName)
    .required(USER_MESSAGES.requiredFirstName),
  last_name: yup
    .string(USER_MESSAGES.lastName)
    .required(USER_MESSAGES.requiredLastName),
  phone_number: yup
    .string(USER_MESSAGES.phone)
    .required(USER_MESSAGES.requiredPhone)
    .min(5, USER_MESSAGES.phoneMinLength)
    .max(15, USER_MESSAGES.phoneMaxLength)
    .matches(phoneRegExp, USER_MESSAGES.phoneRegExp),
  user_status: yup
    .object()
    .shape({
      value: yup.string(),
      label: yup.string(),
    })
    .nullable(),
});

export const roleValidation = yup.object().shape({
  name: yup.string().required(ROLE_MESSAGES.requiredRoleTitle),
});

export const permissionValidation = yup.object().shape({
  name: yup.string().required(PERMISSION_MESSAGES.name),
  action: yup.object().required(PERMISSION_MESSAGES.action),
  app_id: yup.object().required(PERMISSION_MESSAGES.app_id),
  description: yup.string().required(PERMISSION_MESSAGES.description),
});

export const userPasswordConfirmationValidation = yup.object().shape({
  newPassword: yup
    .string(USER_MESSAGES.password)
    .min(USER_MESSAGES.maxLength, USER_MESSAGES.minLengthPassword)
    .required(USER_MESSAGES.requiredPassword),
  passwordConfirmation: yup
    .string(USER_MESSAGES.passwordConfirmation)
    .min(USER_MESSAGES.maxLength, USER_MESSAGES.minLengthPassword)
    .required(USER_MESSAGES.requiredPasswordConfirmation)
    .oneOf([yup.ref('newPassword'), null], USER_MESSAGES.passwordsCompare),
});

export const passwordUpdateValidation = yup.object().shape({
  newPassword: yup
    .string(USER_MESSAGES.password)
    .min(USER_MESSAGES.maxLength, USER_MESSAGES.minLengthPassword)
    .required(USER_MESSAGES.requiredPassword),
  passwordConfirmation: yup
    .string(USER_MESSAGES.passwordConfirmation)
    .min(USER_MESSAGES.maxLength, USER_MESSAGES.minLengthPassword)
    .required(USER_MESSAGES.requiredPasswordConfirmation)
    .oneOf([yup.ref('newPassword'), null], USER_MESSAGES.passwordsCompare),
});

export const addOrganizationValidation = yup.object().shape({
  name: yup.string().required(ORGANIZATION_MESSAGES.name),
});


