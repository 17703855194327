import * as type from '../types';

export const getPermissionsList = (data) => {
  return {
    type: type.GET_PERMISSIONS_LIST,
    payload: data,
  };
};

export const getPermissionSettings = (data) => {
  return {
    type: type.GET_PERMISSIONS_SETTINGS,
    payload: data,
  };
};

export const setFilterPermissionsStatus = (value) => {
  return {
    type: type.SET_FILTER_PERMISSIONS_STATUS,
    payload: value,
  };
};

export const setItemsPerPagePermissions = (value) => {
  return {
    type: type.SET_ITEMS_PER_PAGE_PERMISSIONS,
    payload: value,
  };
};

