const initialState = {
  permissionsList: [],
  currentPage: null,
  totalCount: null,
  defaultItemsPerPage: null,
  editPermission: '',
  filterStatus: { value: 'active', label: 'Active' },
  perPage: 20,
};

export const permissions = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_PERMISSIONS_LIST':
      return {
        ...state,
        permissionsList: action.payload.data.data,
        currentPage: action.payload.data.meta?.current_page,
        lastPage: action.payload.data.meta?.last_page,
        defaultItemsPerPage: action.payload.data.meta?.per_page,
        totalCount: action.payload.data.meta?.total,
      };
    case 'GET_PERMISSIONS_SETTINGS':
      return {
        ...state,
        editPermission: action.payload.data,
      };
    case 'SET_FILTER_PERMISSIONS_STATUS':
      return {
        ...state,
        filterStatus: action.payload,
      };
    case 'SET_ITEMS_PER_PAGE_PERMISSIONS':
      return {
        ...state,
        perPage: action.payload,
      };
    default:
      return state;
  }
};
