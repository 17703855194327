import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Template = styled.input`
  margin-top: ${(props) => props.top || '0px'};
  margin-bottom: ${(props) => props.bottom || '0px'};
  margin-left: ${(props) => props.left || '0px'};
  margin-right: ${(props) => props.right || '0px'};
  width: ${(props) => props.width || '210px'};
  padding-top: ${(props) => props.paddingTop || '1px'};
  padding-bottom: ${(props) => props.paddingBottom || '1px'};
  padding-left: ${(props) => props.paddingLeft || '10px'};
  padding-right: ${(props) => props.paddingRight || '10px'};
  border: ${({ theme }) => `1px solid ${theme.inputBorderColor}`};
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -khtml-border-radius: 4px;
  background: ${({ theme }) => theme.inputBackgroundColor};
  outline: none;
  height: 28px;
  color: ${({ theme }) => theme.inputColor};
  font-size: 12px;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;

  &:active {
    border: ${({ theme }) => `1px solid ${theme.inputBorderActiveColor}`};
  }

  &:focus {
    border: ${({ theme }) => `1px solid ${theme.inputBorderFocusColor}`};
  }

  &:placeholder {
    color: ${({ theme }) => theme.inputPlaceholderColor};
    background: ${({ theme }) => theme.inputPlaceholderBackgroundColor};
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: ${({ theme }) => `0 0 0 30px ${theme.inputAutofillColor} inset !important`};
    -webkit-text-fill-color: ${({ theme }) => `${theme.inputAutofillTextColor} !important`};
    transition: background-color 5000s ease-in-out 0s;
  }

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const InputStyled = (props) => {
  const {
    top,
    bottom,
    left,
    right,
    width,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
    ...rest
  } = props;
  return (
    <Template
      top={top}
      bottom={bottom}
      left={left}
      right={right}
      width={width}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      paddingLeft={paddingLeft}
      paddingRight={paddingRight}
      {...rest}
      autoComplete='off'
    />
  );
};

InputStyled.propTypes = {
  top: PropTypes.string,
  bottom: PropTypes.string,
  left: PropTypes.string,
  right: PropTypes.string,
  width: PropTypes.string,
  paddingTop: PropTypes.string,
  paddingBottom: PropTypes.string,
  paddingLeft: PropTypes.string,
  paddingRight: PropTypes.string,
};
