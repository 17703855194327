import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Header } from '../../components/Header/Header';
import { BackgroundStyled } from '../../components/BackgroundStyled/BackgroundStyled';
import { StandardTable } from '../../components/Tables/StandardTable/StandardTable';
import {
  getPermissionsList,
  setFilterPermissionsStatus, setItemsPerPagePermissions,
} from '../../redux/actions/permissions';
import { ApiApplications } from '../../api/ApiApplications';
import { ApiPermissions } from '../../api/ApiPermissions';
import { getColumnsForPermissions } from '../../components/Tables/StandardTable/columns/permissionsColumns';
import { PermissionsHeader } from '../../components/Tables/StandardTable/headers/PermissionsHeader';
import { Modals } from '../../components/Modals/Modals';
import { hideLoader, loadData, showLoader, showToastWithTimeout } from '../../utils/common';
import { SUCCESSFUL_TOAST_MESSAGES } from '../../constants/messages';

export const Permissions = () => {
  const FIRST_PAGE = 1;

  const [show, setShow] = useState(false);
  const [currentPermissionsId, setCurrentPermissionsId] = useState('');
  const [modalName, setModalName] = useState('');
  const [rolesCount, setRolesCount] = useState('');
  const [searchItem, setSearchItem] = useState('');

  const apiApplications = useMemo(() => new ApiApplications(), []);
  const apiPermissions = useMemo(() => new ApiPermissions(), []);

  const theme = useSelector((state) => state.theme.theme);
  const permissionsList = useSelector((state) => state.permissions.permissionsList);
  const currentPage = useSelector((state) => state.permissions.currentPage);
  const lastPage = useSelector((state) => state.permissions.lastPage);
  const totalCount = useSelector((state) => state.permissions.totalCount);
  const filterStatus = useSelector((state) => state.permissions.filterStatus);
  const defaultItemsPerPage = useSelector((state) => state.permissions.defaultItemsPerPage);
  const itemsPerPage = useSelector((state) => state.permissions.perPage);
  const [countItemsPerPage, setCountItemsPerPage] = useState(itemsPerPage);

  const dispatch = useDispatch();

  useEffect(() => {
    let unmounted = false;

    if (!unmounted) {
      (async () => {
        showLoader();
        const [permissions, applications] = await Promise.all([
          apiPermissions.getPermissions(FIRST_PAGE, searchItem, filterStatus.value, +countItemsPerPage),
          apiApplications.getApplications(),
        ]);

        if (!unmounted && permissions && applications) {
          setApplicationsData(applications.data);
          dispatch(getPermissionsList(permissions));
          dispatch(setItemsPerPagePermissions(countItemsPerPage));
          showToastWithTimeout(SUCCESSFUL_TOAST_MESSAGES);
        }
        hideLoader();
      })();
    }

    return () => {
      unmounted = true;
    };
  }, [apiPermissions, apiApplications, dispatch, FIRST_PAGE, searchItem, filterStatus.value, countItemsPerPage]);

  const [applicationsData, setApplicationsData] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = (id, modalName, rolesLength) => {
    setModalName(modalName);
    setCurrentPermissionsId(id);
    setShow(true);
    setRolesCount(rolesLength);
  };

  const handleDeletePermission = async (id) => {
    showLoader();
    const [deletePermission, permissions] = await Promise.all([
      apiPermissions.deletePermission(id),
      apiPermissions.getPermissions(FIRST_PAGE, searchItem, filterStatus.value, +countItemsPerPage),
    ]);

    if (deletePermission && permissions) {
      dispatch(getPermissionsList(permissions));
      showToastWithTimeout(SUCCESSFUL_TOAST_MESSAGES);
    }
    hideLoader();
  };

  const handleRestorePermission = async (id) => {
    showLoader();
    const [restorePermission, permissions] = await Promise.all([
      apiPermissions.restorePermission(id),
      apiPermissions.getPermissions(FIRST_PAGE, searchItem, filterStatus.value, +countItemsPerPage),
    ]);

    if (restorePermission && permissions) {
      dispatch(getPermissionsList(permissions));
      showToastWithTimeout(SUCCESSFUL_TOAST_MESSAGES);
    }
    hideLoader();
  };

  const handlePageChange = async (page, searchItem) => {
    showLoader();
    const [permissions] = await Promise.all([
      apiPermissions.getPermissions(page, searchItem, filterStatus.value, +countItemsPerPage),
    ]);

    if (permissions) {
      dispatch(getPermissionsList(permissions));
      showToastWithTimeout(SUCCESSFUL_TOAST_MESSAGES);
    }
    hideLoader();
  };

  const handleChangeItemsPerPage = (value) => {
    dispatch(setItemsPerPagePermissions(value));
  };

  const handleSendItemsPerPage = async (e) => {
    if (itemsPerPage !== '' && e.key === 'Enter') {
      setCountItemsPerPage(itemsPerPage);
    }
  };

  const handleChangeNameSearch = (value) => {
    setSearchItem(value);
  };

  const handleSelectedFilterStatus = (name, value) => {
    dispatch(setFilterPermissionsStatus(value));
  };

  return (
    <BackgroundStyled display={'block'}>
      <Header />
      {modalName === 'disable' && (
        <Modals
          onHandleClose={handleClose}
          show={show}
          onHandleSuccess={async () => await loadData(handleDeletePermission, [currentPermissionsId])}
          primaryButtonName={'Disable'}
          secondaryButtonName={'Close'}
          message={
            rolesCount > 0
              ? 'This permission is already in use. Are you sure that you want to disable?'
              : 'Are you sure you want to disable this permission?'
          }
        />
      )}
      {modalName === 'restore' && (
        <Modals
          onHandleClose={handleClose}
          show={show}
          onHandleSuccess={() => handleRestorePermission(currentPermissionsId)}
          primaryButtonName={'Restore'}
          secondaryButtonName={'Close'}
          message={'Are you sure you want to restore this permission?'}
        />
      )}
      <StandardTable
        tableColumns={getColumnsForPermissions(handleShow, filterStatus, applicationsData)}
        tableData={permissionsList}
        currentPage={currentPage}
        lastPage={lastPage}
        totalCount={totalCount}
        itemsPerPage={`${itemsPerPage}`}
        defaultItemsPerPage={+defaultItemsPerPage}
        onHandlePageChange={handlePageChange}
        onSendItemsPerPage={handleSendItemsPerPage}
        header={
          <PermissionsHeader
            onHandleChangeNameSearch={handleChangeNameSearch}
            theme={theme}
            filterStatus={filterStatus}
            onHandleSelectedFilterStatus={handleSelectedFilterStatus}
          />
        }
        onHandleChangeItemsPerPage={handleChangeItemsPerPage}
        searchItem={searchItem}
      />
    </BackgroundStyled>
  );
};
