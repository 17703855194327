import React from 'react';
import { Header } from '../../components/Header/Header';
import { BackgroundStyled } from '../../components/BackgroundStyled/BackgroundStyled';

export const Logs = () => {
  return (
    <BackgroundStyled>
      <Header />
      <h1>Logs</h1>
    </BackgroundStyled>
  );
};
