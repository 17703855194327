export const BLUE_THEME = {};
export const LIGHT_THEME = {
  checkBoxBorderColor: '#605',
  checkBoxBackgroundColor: '#605',
  backgroundColor: '#605',
  //Link Colors
  linksColor: '#fff',
  linksHoverColor: '#fff',
  //Bg Color
  //Btn Colors
  btnTextColor: '#fff',
  btnBackgroundColor: '#605',
  btnBorderColor: '#605',
  //Btn Focus Colors
  btnBackgroundFocusColor: '#605',
  btnTextFocusColor: '#605',
  btnBorderFocusColor: '#605',
  //Btn Active Colors
  btnBackgroundActiveColor: '#605',
  btnTextActiveColor: '#605',
  btnBorderActiveColor: '#605',
  //Btn Hover Colors
  btnBackgroundHoverColor: '#605',
  btnTextHoverColor: '#605',
  btnBorderHoverColor: '#605',
  btnBackgroundImageColor: 'linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, #f93 100%)',
};
export const DARK_THEME = {
  //FormStyled
  formBackgroundColor: '#1A1A1C',
  formHeaderBorderBottomColor: '#4a4a52',
  formCardTitleColor: '#fff',
  formListItemColor: '#fff',
  formDescriptionColor: '#fff',
  //AppCard
  AppCardTitleColor: '#fff',
  AppCardDescriptionColor: '#fff',
  AppCardBackgroundColor: '#1A1A1C',
  AppCardHoverBackgroundColor: '#2c2c2e',
  AppCardBorderColor: '#4A4A52',
  //Side Menu Colors
  menuNavigationBackgroundColor: 'transparent',
  menuNavigationTextColor: '#fff',
  menuNavigationTextHoverColor: '#ffa64d',
  //Description Colors
  descriptionTextColor: '#fff',
  //Chip Colors
  suggestionsBackgroundColor: '#1a1a1c',
  suggestionsColor: '#fff',
  suggestionsBorderColor: '#4a4a52',
  tagsBackgroundColor: '#45328a',
  tagsTextColor: '#fff',
  //RadioButtonColors
  labelRadioButtonColor: '#fff',
  labelRadioButtonHoverColor: '#ffa64d',
  checkmarkRadioButtonBorderColor: '#ffa64d',
  checkmarkRadioButtonBackgroundColor: 'transparent',
  checkmarkRadioButtonCheckedBackgroundColor: '#ffa64d',
  //ToggleSwitch Colors
  switchLabelStyledBackgroundColor: '#bebebe',
  switchLabelStyledBackgroundAfterColor: '#fff',
  switchColor: '#ffa64d',
  switchLabelColor: '#fff',
  //Spinner Colors
  spinnerColor: '#ffa64d',
  spinnerBackgroundColor: 'rgba(0, 0, 0, 0.2)',
  //Checkbox List Colors
  checkboxNameColor: '#fff',
  checkboxItemNameColor: '#fff',
  CheckboxListHeaderBorderColor: '#4a4a52',
  //Modal Colors
  modalTextColor: '#fff',
  ModalTextBorderBottom: '#4A4A52',
  modalBodyColor: '#1a1a1c',
  modalHeaderColor: '#1a1a1c',
  //Logo Modal Colors
  modalColor: '#fff',
  modalBodyBackgroundColor: '#1A1A1C',
  modalFooterBackgroundColor: '#1A1A1C',
  modalHeaderBackgroundColor: '#1A1A1C',
  CropperSliderBackgroundColor: '#495057',
  CropperSliderThumbBorderColor: '#3C3C42',
  CropperSliderThumbBackgroundColor: '#fff',
  CropperSliderTrackBackgroundColor: 'transparent',
  CropperContainerBorderColor: '#4A4A52',
  //Btn Tab
  btnTabColor: '#fff',
  btnTabBackgroundColor: '#1a1a1c',
  btnTabBorderBottomColor: '#3c3c42',
  btnTabHoverColor: '#ffa64d',
  btnTabBorderBottomHoverColor: '#ffa64d',
  //Btn Tab Active
  btnTabActiveColor: '#ffa64d',
  btnTabBackgroundActiveColor: '#1a1a1c',
  btnTabBorderBottomActiveColor: '#ffa64d',
  btnTabHoverActiveColor: '#ffa64d',
  btnTabBorderBottomHoverActiveColor: '#ffa64d',
  //Styled Components
  cardStyledBackgroundColor: '#1a1a1c',
  pageTitleStyledColor: '#fff',
  cardTitleStyledColor: '#fff',
  cardTitleStyledBorderBottomColor: '#4a4a52',
  //Tooltip colors
  tooltipCornerColor: '#1a1a1c',
  tooltipTextColor: '#fff',
  tooltipBorderColor: '#0a0a0a',
  tooltipBackgroundColor: '#1a1a1c',
  //Select colors
  selectMenuBorderColor: '#4a4a52',
  selectMenuBackgroundColor: '#1a1a1c',
  selectControlBackgroundColor: '#1a1a1c',
  selectControlBorderColor: '#4a4a52',
  selectDropdownIndicator: '#4a4a52',
  selectOptionBackgroundActiveColor: '#2c2c2e',
  selectOptionBackgroundHoverColor: '#2c2c2e',
  selectOptionHoverColor: '#fff',
  selectOptionBackgroundIsSelectedColor: '#2c2c2e',
  selectOptionBackgroundIsFocusedColor: '#2c2c2e',
  selectOptionBackgroundColor: '#1a1a1c',
  selectOptionColor: '#fff',
  selectSingleValueColor: '#fff',
  selectDotColor: '#33aa6a',
  //Pagination Btn colors
  paginationBtnTextColor: '#fff',
  paginationBtnBorderColor: '#3c3c42',
  paginationBtnBackgroundColor: '#29292d',
  //Pagination Btn Hover colors
  paginationBtnTextHoverColor: '#ff9933',
  paginationBtnBorderHoverColor: '#4a4a52',
  paginationBtnBackgroundHoverColor: '#4a4a52',
  //Pagination Btn Active  colors
  paginationActiveBtnTextColor: '#ff9933',
  paginationActiveBtnBorderColor: '#4a4a52',
  paginationActiveBtnBackgroundColor: '#4a4a52',
  //Pagination Btn Disabled colors
  paginationBtnDisabledTextColor: '#fff',
  paginationBtnDisabledBackgroundColor: '#3c3c42',
  paginationBtnDisabledBorderColor: '#29292d',
  //LightBulb
  statusLightBulbActiveColor: '#33aa6a',
  statusLightBulbInactiveColor: '#ff9933',
  statusLightBulbDisabledColor: '#e13732',
  //Pill colors
  pillMoreTextColor: '#ffa64d',
  pillMoreBackgroundColor: '#3c3c42',
  pillTextColor: '#fff',
  pillBackgroundColor: '#3c3c42',
  //Icons colors
  arrowDownUpIconColor: '#4a4a52',
  arrowUpIconColor: '#fff',
  arrowDownIconColor: '#fff',
  searchIconColor: '#fff',
  trashIconColor: '#d43c3b',
  arrowCounterclockwiseColor: '#ffa64d',
  editIconStyled: '#fff',
  userCircleIconColor: '#fff',
  imageIconColor: '#fff',
  //Table colors
  tableNavBackgroundColor: '#1a1a1c',
  tableBackgroundColor: '#1a1a1c',
  tableTextColor: '#fff',
  tableHeadBackgroundColor: '#1a1a1c',
  tableHeadTextColor: '#fff',
  tableBorderBottomColor: '#000',
  tableRowBackgroundColor: '#1a1a1c',
  tableRowBackgroundHoverColor: '#2e2e30',
  tableFooterColor: '#fff',
  tableBorderBottomBeforeColor: '#000',
  //Header Colors
  headerBackgroundColor: '#4a4a52',
  headerIconColor: '#fff',
  headerIconHoverColor: '#8a8a8a',
  headerActionBackgroundColor: '#4a4a52 !important',
  headerActionBorderColor: '#4a4a52 !important',
  headerDropdownMenuBackgroundColor: '#39383d',
  headerDropdownMenuTextColor: '#fff',
  headerDropdownMenuTextHoverColor: '#fff',
  headerDropdownMenuTextFocusColor: '#fff',
  headerDropdownMenuItemsBackgroundHoverColor: '#2c2c2e',
  headerDropdownMenuItemsBackgroundFocusColor: '#2c2c2e',
  activeTabRouterTextColor: '#ffa64d',
  activeTabRouterBackgroundColor: '#29282d',
  //Header Dropdown User Settings Colors
  headerDropdownUserSettingsBackgroundColor: '#39383d',
  //NavBar Colors
  iconChevronRightColor: '#e8d6a4',
  navBarContainerTextColor: '#fff',
  navBarContainerBackgroundColor: '#0a0a0a',
  navBarTabTextColor: '#fff',
  navBarTabBackgroundColor: '#1a1a1c',
  navBarTabTextFocusColor: '#ffa64d',
  navBarTabBackgroundFocusColor: '#29282d',
  navBarTabTextActiveColor: '#ffa64d',
  navBarTabBackgroundActiveColor: '#29282d',
  navBarTabTextHoverColor: '#ffa64d',
  navBarTabBackgroundHoverColor: '#29282d',
  //Icon Colors
  iconErrorColor: '#e13732',
  eyeIconColor: '#fff',
  hideIconIconColor: '#fff',
  menuIconColor: '#fff',
  plusColor: '#fff',
  closeColor: '#fff',
  plusCircleColor: '#fff',
  minusColor: '#fff',
  //Toast Colors
  iconToastSuccessColor: '#fff',
  iconToastErrorColor: '#e13732',
  textToastSuccessColor: '#fff',
  textToastErrorColor: '#e13732',
  backgroundToastColor: '#3C3C42',
  //Validation
  errorMessageValidation: '#e13732',
  //Links Router Colors
  linkRouterColor: '#fff',
  //Text Colors
  textColor: '#fff',
  //Link Colors
  linksColor: '#fff',
  linksHoverColor: '#fff',
  //Bg Colors
  backgroundColor: '#0a0a0a',
  //Btn Primary Colors
  btnPrimaryTextColor: '#000',
  btnPrimaryBackgroundColor: '#ffa64d',
  btnPrimaryBorderColor: '#ffa64d',
  //Btn Primary Focus Colors
  btnPrimaryBackgroundFocusColor: '#f93',
  btnPrimaryTextFocusColor: '#000',
  btnPrimaryBorderFocusColor: '#f93',
  //Btn Primary Active Colors
  btnPrimaryBackgroundActiveColor: '#f93',
  btnPrimaryTextActiveColor: '#000',
  btnPrimaryBorderActiveColor: '#f93',
  //Btn Primary Hover Colors
  btnPrimaryBackgroundHoverColor: '#ffba75',
  btnPrimaryTextHoverColor: '#000',
  btnPrimaryBorderHoverColor: '#ffa64d',
  btnPrimaryBackgroundImageColor: 'linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, #f93 100%)',
  //Btn Secondary Colors
  btnSecondaryTextColor: '#fff',
  btnSecondaryBackgroundColor: '#4a4a54',
  btnSecondaryBorderColor: '#4a4a54',
  //Btn Secondary Focus Colors
  btnSecondaryBackgroundFocusColor: '#4a4a54',
  btnSecondaryTextFocusColor: '#fff',
  btnSecondaryBorderFocusColor: '#4a4a54',
  //Btn Secondary Active Colors
  btnSecondaryBackgroundActiveColor: '#4a4a54',
  btnSecondaryTextActiveColor: '#fff',
  btnSecondaryBorderActiveColor: '#4a4a54',
  //Btn Secondary Hover Colors
  btnSecondaryBackgroundHoverColor: '#5a5a66',
  btnSecondaryTextHoverColor: '#fff',
  btnSecondaryBorderHoverColor: '#4a4a54',
  btnSecondaryBackgroundImageColor: 'linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, #4a4a54 100%)',
  //Btn Disabled Colors
  btnDisabledTextColor: '#4b4c51',
  btnDisabledBackgroundColor: '#29282d',
  btnDisabledBorderColor: '#29282d',
  //Btn Disabled Focus Colors
  btnDisabledBackgroundFocusColor: '#29282d',
  btnDisabledTextFocusColor: '#4b4c51',
  btnDisabledBorderFocusColor: '#29282d',
  //Btn Disabled Active Colors
  btnDisabledBackgroundActiveColor: '#29282d',
  btnDisabledTextActiveColor: '#4b4c51',
  btnDisabledBorderActiveColor: '#29282d',
  //Btn Disabled Hover Colors
  btnDisabledBackgroundHoverColor: '#29282d',
  btnDisabledTextHoverColor: '#4b4c51',
  btnDisabledBorderHoverColor: '#29282d',
  //CheckBox Colors
  checkBoxBorderColor: '#fff',
  checkBoxBackgroundColor: '#1a1a1c',
  //CheckBox Checked Colors
  checkBoxBorderCheckedColor: '#fff',
  checkBoxBackgroundCheckedColor: '#ffa64d',
  //CheckBox Focus Colors
  checkBoxBorderFocusColor: '#fff',
  checkBoxBackgroundFocusColor: '#ffa64d',
  //CheckBox Indeterminate Colors
  checkBoxIndeterminateBackgroundColor: '#ffa64d',
  checkBoxIndeterminateBorderColor: '#fff',
  //Container Colors
  containerBackgroundColor: '#4a4a52',
  //Input Colors
  inputColor: '#fff',
  inputBorderColor: '#4a4a52',
  inputBackgroundColor: '#1a1a1c',
  inputPlaceholderColor: '#4a4a52',
  inputPlaceholderBackgroundColor: '#1a1a1c',
  //Input Focus Color
  inputBorderFocusColor: '#fff',
  inputFocusColor: '#fff',
  inputBackgroundFocusColor: '#1a1a1c',
  //Input Active Color
  inputBorderActiveColor: '#fff',
  inputActiveColor: '#fff',
  inputBackgroundActiveColor: '#1a1a1c',
  inputAutofillColor: '#1a1a1c',
  inputAutofillTextColor: '#fff',
  //Label Colors
  labelColor: '#fff',
};
