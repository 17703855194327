import * as type from '../types';

export const setUserData = (status) => {
  return {
    type: type.SET_USER_DATA,
    payload: status,
  };
};

export const clearStore = () => {
  return {
    type: type.CLEAR_STORE,
  };
};

export const setUserPermissions = (value) => {
  return {
    type: type.SET_USER_PERMISSIONS,
    payload: value,
  };
};

export const setEntityAndRole = (status) => {
  return {
    type: type.SET_ENTITY_AND_ROLE,
    payload: status,
  };
};


