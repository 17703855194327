import * as type from '../types';

const initialState = {
  userData: {},
  userPermissions: undefined,
  entityAndRole: false,
};

export const login = (state = initialState, action) => {
  switch (action.type) {
    case type.SET_USER_DATA:
      return {
        ...state,
        userData: action.payload,
      };
    case type.SET_USER_PERMISSIONS:
      return {
        ...state,
        userPermissions: action.payload,
      };
    case type.SET_ENTITY_AND_ROLE:
      return {
        ...state,
        entityAndRole: action.payload,
      };
    case type.CLEAR_STORE:
      return {
        ...state,
        clearStore: true,
      };
    default:
      return state;
  }
};
