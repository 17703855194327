import React from 'react';
import PropTypes from 'prop-types';
import {
  SwitchWrapper,
  SwitchLabelStyled,
  SwitchStyled,
  SwitchLabel,
  SwitchContainer,
  SwitchButton,
} from './StyledComponents';

export const ToggleSwitch = (props) => {
  const { id, checked, onChangeStatus, label, padding } = props;
  return (
    <SwitchWrapper padding={padding}>
      <SwitchContainer>
        <SwitchLabel>{label}</SwitchLabel>
        <SwitchButton>
          <SwitchStyled id={id} type='checkbox' checked={checked} onChange={onChangeStatus} />
          <SwitchLabelStyled htmlFor={id} />
        </SwitchButton>
      </SwitchContainer>
    </SwitchWrapper>
  );
};

ToggleSwitch.propTypes = {
  id: PropTypes.string,
  checked: PropTypes.bool,
  onToggleSwitchChange: PropTypes.func,
  label: PropTypes.string,
  padding: PropTypes.string,
};
