import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const AttributeContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 10px;
`;

export const Label = styled.label`
  position: relative;
  padding: 2px 25px;
  cursor: pointer;
  font-size: 12px;
  color: ${({ theme }) => theme.labelRadioButtonColor};
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: 0.25s ease;

  &:hover {
    color: ${({ theme }) => theme.labelRadioButtonHoverColor};
  }
`;

export const Checkmark = styled.span`
  position: absolute;
  top: 2px;
  left: 0;
  height: 15px;
  width: 15px;
  border: 2px solid ${({ theme }) => theme.checkmarkRadioButtonBorderColor};
  border-radius: 50%;
  background-color: ${({ theme }) => theme.checkmarkRadioButtonBackgroundColor};
  transition: all .25s ease-in-out;

  div {
    border-radius: 50%;
    width: 0;
    height: 0;
    background-color: ${({ theme }) => theme.checkmarkRadioButtonCheckedBackgroundColor};
    margin: 6px auto;
  }
`;

export const Input = styled.input`
  display: none;

  &:checked ~ ${Checkmark} {
    div {
      border-radius: 50%;
      width: 7px;
      height: 7px;
      background-color: ${({ theme }) => theme.checkmarkRadioButtonCheckedBackgroundColor};
      margin: 2px auto;
      transition: all .25s ease-in-out;
    }
  }

  &:checked ~ ${Checkmark}:after {
    display: block;
  }
`;
