import styled from 'styled-components';

export const CheckboxItemName = styled.div`
  color: ${({ theme }) => theme.checkboxItemNameColor};
  font-size: 14px;
  padding-left: 8px;
`;

export const CheckboxItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 7px;
  margin-bottom: 30px;
`;