import styled from 'styled-components';
import PropTypes from 'prop-types';

const Label = styled.label`
  color: ${({ theme }) => theme.labelColor};
  margin-top: ${(props) => props.top || '0px'};
  margin-bottom: ${(props) => props.bottom || '0px'};
  margin-left: ${(props) => props.left || '0px'};
  margin-right: ${(props) => props.right || '0px'};
  font-size: ${(props) => props.fontSize || '12px'};
  align-self: ${(props) => props.alignSelf || 'auto'};
`;

export const LabelStyled = (props) => {
  return <Label {...props} />;
};

LabelStyled.propTypes = {
  top: PropTypes.string,
  bottom: PropTypes.string,
  left: PropTypes.string,
  right: PropTypes.string,
};
