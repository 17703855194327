import styled from 'styled-components';

const Links = styled.a`
  color: ${({ theme }) => theme.linksColor};
  text-decoration: none;
  font-size: ${(props) => props.fontSize || '12px'};
  &:hover {
    color: ${({ theme }) => theme.linksColor};
    text-decoration: underline;
  }
`;

export const LinksStyled = (props) => {
  return <Links {...props} />;
};
