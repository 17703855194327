import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { UploadModal } from './UploadModal';
import { UserLogoStyled, Wrapper } from './StyledComponents';
import { hideLoader, showLoader, showToastWithTimeout } from '../../utils/common';

export const UploadWrapper = (props) => {
  const { btnName } = props;
  const hiddenFileInput = React.useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [image, setImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const handleClick = () => {
    hiddenFileInput.current.value = null;
    hiddenFileInput.current.click();
  };

  const handleInputChange = async () => {
    const fileUploaded = hiddenFileInput.current.files[0];

    try {
      showLoader();
      const file = await readFileAsync(fileUploaded);
      setImage(file);
      setShowModal(true);
    } catch (e) {
      setShowModal(false);
      showToastWithTimeout({ success: false, text: 'An error has occurred', isShown: 'visible' });
    } finally {
      hideLoader();
    }
  };

  const readFileAsync = (file) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleModalSuccess = (croppedImage, croppedImageBlob) => {
    setCroppedImage(croppedImage);
    setShowModal(false);
    props.onHandleImage(croppedImageBlob);
  };

  return (
    <>
      <Wrapper onClick={handleClick}>
        {
          croppedImage
            ? <UserLogoStyled src={croppedImage} />
            : props.originalImageSrc
              ? <UserLogoStyled src={props.originalImageSrc} />
              : props.children
        }
      </Wrapper>
      <input
        type='file'
        name='userLogo'
        style={{ display: 'none' }}
        onChange={handleInputChange}
        ref={hiddenFileInput}
        multiple={false}
        accept={'image/png, image/jpeg'}
      />
      <UploadModal
        show={showModal}
        titleText={'Update profile picture'}
        primaryButtonText={btnName ? btnName : 'Save'}
        secondaryButtonText={'Cancel'}
        image={image}
        onHandleClose={handleModalClose}
        onHandleSuccess={handleModalSuccess}
        maxZoom={2}
        minZoom={0.1}
        zoomStep={0.1}
      />
    </>
  );
};

UploadWrapper.propTypes = {
  originalImageSrc: PropTypes.string,
  onHandleImage: PropTypes.func,
  btnName: PropTypes.string,
};
