import React from 'react';
import PropTypes from 'prop-types';
import { usePagination, DOTS } from './usePagination';
import { ChevronLeftStyled, ChevronRightStyled } from '../../utils/iconsStyled';
import {
  PaginationContainer,
  PaginationBtn,
  PaginationActiveBtn,
  PaginationDisabledBtn,
} from './StyledComponents';

export const PaginationStyled = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    lastPage,
    searchItem,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || (paginationRange && paginationRange.length < 2)) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const firstPage = 1;
  return (
    <PaginationContainer>
      {firstPage === currentPage ? (
        <PaginationDisabledBtn>
          <ChevronLeftStyled size={16} />
        </PaginationDisabledBtn>
      ) : (
        <PaginationBtn onClick={onPrevious}>
          <ChevronLeftStyled size={16} />
        </PaginationBtn>
      )}
      {paginationRange &&
        paginationRange.map((pageNumber, i) => {
          if (pageNumber === DOTS) {
            return <PaginationBtn key={i}>&#8230;</PaginationBtn>;
          }
          if (currentPage === pageNumber) {
            return (
              <PaginationActiveBtn key={i} onClick={() => onPageChange(pageNumber, searchItem)}>
                {pageNumber}
              </PaginationActiveBtn>
            );
          } else {
            return (
              <PaginationBtn key={i} onClick={() => onPageChange(pageNumber, searchItem)}>
                {pageNumber}
              </PaginationBtn>
            );
          }
        })}
      {lastPage === currentPage ? (
        <PaginationDisabledBtn>
          <ChevronRightStyled size={16} />
        </PaginationDisabledBtn>
      ) : (
        <PaginationBtn onClick={onNext}>
          <ChevronRightStyled size={16} />
        </PaginationBtn>
      )}
    </PaginationContainer>
  );
};

PaginationStyled.propTypes = {
  activeTabRouterTextColor: PropTypes.string,
  activeTabRouterBackgroundColor: PropTypes.string,
  onPageChange: PropTypes.func,
  totalCount: PropTypes.number,
  siblingCount: PropTypes.string,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
  lastPage: PropTypes.number,
  searchItem: PropTypes.string,
};
