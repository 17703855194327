import * as type from '../types';

export const getOrganizationsList = (data) => {
  return {
    type: type.GET_ORGANIZATIONS_LIST,
    payload: data,
  };
};



