import { combineReducers } from 'redux';
import { login } from './login';
import { toast } from './toast';
import { spinner } from './spinner';
import { theme } from './themes';
import { userManagement } from './userManagement';
import { roleManagement } from './roleManagement';
import { permissions } from './permissions';
import { organizations } from './organizations';
import * as type from '../types';

const rootReducer = combineReducers({
  login: login,
  toast: toast,
  spinner: spinner,
  theme: theme,
  userManagement: userManagement,
  roleManagement: roleManagement,
  permissions: permissions,
  organizations: organizations,
});

let getReducerValue = (action, state) => {
  if (action.type === type.CLEAR_STORE) {
    localStorage.clear();
    return {
      ...state,
      login: undefined,
      toast: undefined,
      spinner: undefined,
      userManagement: undefined,
      roleManagement: undefined,
      permissions: undefined,
      organizations: undefined,
    };
  } else {
    return state;
  }
};

const reducer = (state, action) => rootReducer(getReducerValue(action, state), action);

export default reducer;
