import React, { useMemo } from 'react';
import AppLogo from '../../assets/logos/logo-now-access.png';
import { Formik } from 'formik';
import { Form } from 'react-bootstrap';
import { Field } from 'formik';
import { useHistory } from 'react-router-dom';
import { PositionStyled } from './StyledComponents';
import { ButtonPrimary } from '../../components/ButtonPrimary/ButtonPrimary';
import { ButtonSecondary } from '../../components/ButtonSecondary/ButtonSecondary';
import { LabelStyled } from '../../components/LabelStyled/LabelStyled';
import { ContainerStyled } from '../../components/ContainerStyled/ContainerStyled';
import { BackgroundStyled } from '../../components/BackgroundStyled/BackgroundStyled';
import { FlexStyled } from '../../components/FlexStyled/FlexStyled';
import { ImgStyled } from '../../components/ImgStyled/ImgStyled';
import { GroupStyled } from '../../components/GroupStyled/GroupStyled';
import { resetPasswordValidation } from '../../utils/validationSchema';
import { InputValidation } from '../../components/InputValidation/InputValidation';
import { Api } from '../../api/Api';
import { ROUTES } from '../../constants/routes';

export const ForgotPassword = () => {
  const history = useHistory();
  const api = useMemo(() => new Api(), []);

  const handleRoute = () => {
    history.push(ROUTES.login);
  };

  return (
    <div>
      <PositionStyled>
        <ButtonSecondary onClick={handleRoute} buttonName='Login' />
      </PositionStyled>
      <BackgroundStyled justify='center'>
        <ContainerStyled height={'300px'}>
          <Formik
            validationSchema={resetPasswordValidation}
            initialValues={{
              email: '',
            }}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={(values, { resetForm }) => {
              const email = {
                email: values.email,
              };
              const newPassword = api.forgotPassword(email, resetForm);
              newPassword.then(async (response) => {
                if (response?.status === 200) {
                  resetForm();
                }
              });
            }}
          >
            {({ handleSubmit, handleChange }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <FlexStyled>
                  <ImgStyled bottom={'30px'} src={AppLogo} alt='logo' />
                </FlexStyled>
                <GroupStyled>
                  <LabelStyled bottom={'10px'}>Forgot Password</LabelStyled>
                  <Field
                    id={'email'}
                    component={InputValidation}
                    type='email'
                    width={'240px'}
                    name={'email'}
                    placeholder={'email'}
                    onChange={handleChange}
                  />
                </GroupStyled>
                <FlexStyled direction={'row'}>
                  <ButtonPrimary type='submit' buttonName='Send' />
                </FlexStyled>
              </Form>
            )}
          </Formik>
        </ContainerStyled>
      </BackgroundStyled>
    </div>
  );
};
