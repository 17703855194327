import React from 'react';
import styled, { keyframes } from 'styled-components';
import { CheckDouble } from '@styled-icons/boxicons-regular/CheckDouble';
import { ErrorCircle } from '@styled-icons/boxicons-regular/ErrorCircle';

const IconSuccessStyled = styled(CheckDouble)`
  color: ${({ theme }) => theme.iconToastSuccessColor};
  margin: 6px 6px 6px 15px;
`;

const ToastTextSuccessStyled = styled.span`
  margin-left: 10px;
  font-size: 12px;
  color: ${({ theme }) => theme.textToastSuccessColor};
`;

const IconErrorStyled = styled(ErrorCircle)`
  color: ${({ theme }) => theme.iconToastErrorColor};
  margin: 6px 6px 6px 15px;
`;

const ToastTextErrorStyled = styled.span`
  margin-left: 10px;
  font-size: 12px;
  color: ${({ theme }) => theme.textToastErrorColor};
`;

const ToastBodyStyled = styled.div`
  display: flex;
  align-items: center;
`;

const fadein = keyframes`
  from {
    top: 0;
    opacity: 0;
  }
  to {
    top: 57px;
    opacity: 1;
  }
`;

const fadeout = keyframes`
  from {
    top: 57px;
    opacity: 1;
  }
  to {
    top: 0;
    opacity: 0;
  }
`;

const ToastTemplate = styled.div`
  width: 290px;
  height: 40px;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.backgroundToastColor};
  position: fixed;
  top: 57px;
  right: 11px;
  animation: ${(props) => (props.visibility === 'visible' ? fadein : fadeout)} 0.2s ease-in;
  visibility: ${(props) => (props.visibility === 'visible' ? 'visible' : 'hidden')};
  transition: visibility 0.2s ease-out;
  z-index: 500;
`;

export const ToastStyled = (props) => {
  const { showToast, text, success } = props;

  return success ? (
    <ToastTemplate visibility={showToast}>
      <ToastBodyStyled>
        <IconSuccessStyled size='28px' />
        <ToastTextSuccessStyled>{text}</ToastTextSuccessStyled>
      </ToastBodyStyled>
    </ToastTemplate>
  ) : (
    <ToastTemplate visibility={showToast}>
      <ToastBodyStyled>
        <IconErrorStyled size='28px' />
        <ToastTextErrorStyled>{text}</ToastTextErrorStyled>
      </ToastBodyStyled>
    </ToastTemplate>
  );
};
