import styled from 'styled-components';

export const ReactTagsWrapper = styled.div`{
  width: 100%;

  .react-tags {
    display: flex;
    flex-direction: column-reverse;
  }

  .react-tags__search .react-tags__suggestions {
    width: ${(props) => props.width || '100%'};
    background: ${({ theme }) => theme.suggestionsBackgroundColor};
    color: ${({ theme }) => theme.suggestionsColor};
    border-radius: 4px;
    border: ${({ theme }) => `1px solid ${theme.suggestionsBorderColor}`};
    cursor: pointer;
    padding: 4px 4px;

    ul {
      margin: 0;
      padding: 0;
    }

    ul li {
      list-style-type: none;
    }
  }

  .react-tags .react-tags__selected button {
    background: ${({ theme }) => theme.tagsBackgroundColor};
    border-radius: 50px;
    border: 0;
    padding: 4px 10px;
    color: ${({ theme }) => theme.tagsTextColor};
    margin: 4px 4px;

    span::after {
      padding-left: 10px;
      content: '✖';
    }
  }

  .react-tags__search .react-tags__search-wrapper input {
    display: ${(props) => props.display || 'block'};
    margin-top: ${(props) => props.top || '0px'};
    margin-bottom: ${(props) => props.bottom || '0px'};
    margin-left: ${(props) => props.left || '0px'};
    margin-right: ${(props) => props.right || '0px'};
    width: ${(props) => `${props.width} !important` || '100% !important'};
    padding-top: ${(props) => props.paddingTop || '1px'};
    padding-bottom: ${(props) => props.paddingBottom || '1px'};
    padding-left: ${(props) => props.paddingLeft || '10px'};
    padding-right: ${(props) => props.paddingRight || '10px'};
    border: ${({ theme }) => `1px solid ${theme.inputBorderColor}`};
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -khtml-border-radius: 4px;
    background: ${({ theme }) => theme.inputBackgroundColor};
    outline: none;
    height: 28px;
    color: ${({ theme }) => theme.inputColor};
    font-size: 12px;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;

    &:active {
      border: ${({ theme }) => `1px solid ${theme.inputBorderActiveColor}`};
    }

    &:focus {
      border: ${({ theme }) => `1px solid ${theme.inputBorderFocusColor}`};
    }

    &::placeholder {
      color: ${({ theme }) => theme.inputPlaceholderColor};
      background: ${({ theme }) => theme.inputPlaceholderBackgroundColor};
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: ${({ theme }) => `0 0 0 30px ${theme.inputAutofillColor} inset !important`};
      -webkit-text-fill-color: ${({ theme }) => `${theme.inputAutofillTextColor} !important`};
      transition: background-color 5000s ease-in-out 0s;
    }

    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}
`;
