import React from 'react';
import { TabBtn, BtnStyled, BtnActiveStyled } from './StyledComponents';
export const TabButtons = ({ buttons, changeTab, activeTab }) => {
  return (
    <TabBtn>
      {buttons.map((button, i) => {
        if (button === activeTab) {
          return (
            <BtnActiveStyled key={i} onClick={() => changeTab(button)}>
              {button}
            </BtnActiveStyled>
          );
        } else {
          return (
            <BtnStyled key={i} onClick={() => changeTab(button)}>
              {button}
            </BtnStyled>
          );
        }
      })}
    </TabBtn>
  );
};
