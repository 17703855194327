import React, { useMemo, useState, useEffect } from 'react';
import { Formik, Field, Form } from 'formik';
import { Link, useHistory } from 'react-router-dom';
import { Header } from '../../../../components/Header/Header';
import { BackgroundStyled } from '../../../../components/BackgroundStyled/BackgroundStyled';
import { LabelStyled } from '../../../../components/LabelStyled/LabelStyled';
import { addOrganizationValidation } from '../../../../utils/validationSchema';
import { InputValidation } from '../../../../components/InputValidation/InputValidation';
import { GroupStyled } from '../../../../components/GroupStyled/GroupStyled';
import { ButtonPrimary } from '../../../../components/ButtonPrimary/ButtonPrimary';
import { ButtonSecondary } from '../../../../components/ButtonSecondary/ButtonSecondary';
import { Api } from '../../../../api/Api';
import {
  CardStyled,
  Container,
  PageTitleStyled,
  CardTitleStyled,
  FlexStyled, WrapperStyled, MenuNavigationStyled, BtnWrapperStyled,
} from '../../../../components/StyledComponents/StyledComponents';
import { ROUTES } from '../../../../constants/routes';
import { ScrollStyled } from '../../../../components/ScrollStyled/ScrollStyled';
import { useSelector } from 'react-redux';
import { SelectStyled } from '../../../../components/SelectStyled/SelectStyled';

const SIDE_MENU = [
  {
    label: 'Organization Information',
    activeClass: 'active',
    to: 'organizationInformation',
    spy: true,
    smooth: false,
    offset: -100,
    duration: 500,
  },
];

export const AddOrganization = () => {
    const api = useMemo(() => new Api(), []);
    const history = useHistory();

    const routerPush = () => {
      history.push(ROUTES.organizations);
    };

    const theme = useSelector((state) => state.theme.theme);
    const [entityTypesSelectData, setEntityTypesSelectData] = useState([]);
    const [defaultSelectedEntityTypes, setDefaultSelectedEntityTypes] = useState(entityTypesSelectData[0]);

    useEffect(() => {
      let unmounted = false;

      (async () => {
        const entityTypesCollection = await api.getEntityTypesCollection();

        if (!unmounted && entityTypesCollection?.status === 200) {
          setEntityTypesSelectData(selectDataFormatted(entityTypesCollection.data));
          setDefaultSelectedEntityTypes(selectDataFormatted(entityTypesCollection.data)[0]);
        }

      })();

      return () => {
        unmounted = true;
      };
    }, [api]);

    const selectDataFormatted = (data) => {
      const newData = [];
      data.forEach(e => newData.push({ value: `${e.id}`, label: `${e.name}` }));
      return newData;
    };

    const handleChangeSelectedEntityTypes = (name, value) => {
      setDefaultSelectedEntityTypes(value);
    };

    const [organizationData, setOrganizationData] = useState(null);

    const handleChangeInput = (e) => {
      setOrganizationData((prev) => ({
        ...prev,
        [e.target.id]: e.target.value,
      }));
    };

    return (
      <BackgroundStyled display={'block'}>
        <Header />
        <Container>
          <FlexStyled
            direction={'row'}
            align={'flex-start'}
            justify={'center'}
            wrap={'wrap'}
          >
            <MenuNavigationStyled>
              <PageTitleStyled padding={'20px 20px 0'}>Add Organization</PageTitleStyled>
              <ul>
                {SIDE_MENU.map(item =>
                  <li key={item.label}>
                    <ScrollStyled
                      activeClass={item.activeClass}
                      to={item.to}
                      spy={item.spy}
                      smooth={item.smooth}
                      offset={item.offset}
                      duration={item.duration}
                    >{item.label}
                    </ScrollStyled>
                  </li>)}
              </ul>
            </MenuNavigationStyled>
            <WrapperStyled width={'580px'}>
              <Formik
                validationSchema={addOrganizationValidation}
                validateOnChange={false}
                validateOnBlur={false}
                enableReinitialize={true}
                initialValues={{
                  name: organizationData?.name,
                  entity_type_id: defaultSelectedEntityTypes ? defaultSelectedEntityTypes.value : '',
                }}
                onSubmit={async (values, { resetForm }) => {
                  const organization = {
                    name: values.name,
                    entity_type_id: values.entity_type_id,
                  };
                  await api.postEntity(organization, routerPush, resetForm);
                }}
              >
                {({ handleSubmit, handleChange, setFieldValue, values }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <>
                      <FlexStyled
                        direction={'column'}
                        align={'stretch'}
                        wrap={'wrap'}
                      >
                        <CardStyled marginBottom={'15px'}>
                          <CardTitleStyled id='organizationInformation'>ORGANIZATION INFORMATION</CardTitleStyled>
                          <FlexStyled
                            direction={'row'}
                            justify={'space-between'}
                            wrap={'nowrap'}
                          >
                            <FlexStyled
                              paddingLeft={'20px'}
                              paddingRight={'20px'}
                              bottom={'20px'}
                              direction={'column'}
                              justify={'space-between'}
                            >
                              <GroupStyled top={'13px'} bottom={'13px'}>
                                <LabelStyled bottom={'8px'}>Name</LabelStyled>
                                <Field
                                  id={'name'}
                                  component={InputValidation}
                                  type='text'
                                  width={'256px'}
                                  name={'name'}
                                  placeholder={'Name'}
                                  value={organizationData?.name}
                                  onChange={handleChangeInput}
                                />
                              </GroupStyled>
                              <GroupStyled top={'13px'} bottom={'13px'} width={'258px'}>
                                <LabelStyled bottom={'8px'}>Select the organization type</LabelStyled>
                                <SelectStyled
                                  name={'entityTypes'}
                                  id={'entityTypesData'}
                                  maxMenuHeight={150}
                                  options={entityTypesSelectData}
                                  width={'256px'}
                                  theme={theme}
                                  value={defaultSelectedEntityTypes}
                                  onChange={handleChangeSelectedEntityTypes}
                                  isDot={false}
                                />
                              </GroupStyled>
                            </FlexStyled>
                          </FlexStyled>
                        </CardStyled>
                      </FlexStyled>
                      <BtnWrapperStyled>
                        <FlexStyled direction={'row'} justify={'flex-end'} top={'10px'}>
                          <ButtonPrimary type='submit' buttonName='Create' />
                          <Link to={ROUTES.organizations}>
                            <ButtonSecondary buttonName='Cancel' />
                          </Link>
                        </FlexStyled>
                      </BtnWrapperStyled>
                    </>
                  </Form>
                )}
              </Formik>
            </WrapperStyled>
          </FlexStyled>
        </Container>
      </BackgroundStyled>
    );
  }
;
