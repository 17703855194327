import styled from 'styled-components';
import PropTypes from 'prop-types';

const Position = styled.div`
  position: ${(props) => props.position || 'absolute'};
`;

export const PositionStyled = (props) => {
  return <Position {...props} />;
};

PositionStyled.propTypes = {
  position: PropTypes.string,
};
