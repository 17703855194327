import React, { useMemo } from 'react';
import AppLogo from '../../assets/logos/logo-now-access.png';
import { Formik } from 'formik';
import { Form } from 'react-bootstrap';
import { Field } from 'formik';
import { useHistory } from 'react-router-dom';
import { PositionStyled } from './StyledComponents';
import { ButtonPrimary } from '../../components/ButtonPrimary/ButtonPrimary';
import { ButtonSecondary } from '../../components/ButtonSecondary/ButtonSecondary';
import { LabelStyled } from '../../components/LabelStyled/LabelStyled';
import { ContainerStyled } from '../../components/ContainerStyled/ContainerStyled';
import { BackgroundStyled } from '../../components/BackgroundStyled/BackgroundStyled';
import { FlexStyled } from '../../components/FlexStyled/FlexStyled';
import { ImgStyled } from '../../components/ImgStyled/ImgStyled';
import { GroupStyled } from '../../components/GroupStyled/GroupStyled';
import { passwordUpdateValidation } from '../../utils/validationSchema';
import { Api } from '../../api/Api';
import { useQuery } from '../../hooks/useQuery';
import { MaskInput } from '../../components/MaskInput/MaskInput';
import { DescriptionStyled } from '../../components/StyledComponents/StyledComponents';
import { ROUTES } from '../../constants/routes';
import { showSuccessToast } from '../../utils/common';

export const ResetPassword = () => {
  let query = useQuery();
  const history = useHistory();
  const api = useMemo(() => new Api(), []);

  const handleRoute = () => {
    history.push(ROUTES.login);
  };
  const token = query.get('token');
  const email = query.get('email');

  return (
    <div>
      <PositionStyled>
        <ButtonSecondary onClick={handleRoute} buttonName='Login' />
      </PositionStyled>
      <BackgroundStyled justify='center'>
        <ContainerStyled height={'450px'}>
          <Formik
            validationSchema={passwordUpdateValidation}
            initialValues={{
              token: token,
              email: email,
              newPassword: '',
              passwordConfirmation: '',
            }}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={async (values, { resetForm }) => {
              const data = {
                token: values.token,
                email: values.email,
                password: values.newPassword,
                password_confirmation: values.passwordConfirmation,
              };
              const newPassword = await api.resetPassword(data, resetForm);
              if (newPassword?.status === 200) {
                showSuccessToast('Password Successfully Changed');
                history.push(ROUTES.login);
                resetForm();
              }
            }}
          >
            {({ handleSubmit, handleChange }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <FlexStyled>
                  <ImgStyled bottom={'20px'} src={AppLogo} alt='logo' />
                </FlexStyled>
                <DescriptionStyled>
                  <span>- Password must be at least 8 characters</span>
                  <span>- Uppercase characters (A - Z)</span>
                  <span>- Lowercase characters (a - z)</span>
                  <span>- Punctuation (eg. !, $, #, %) - special characters</span>
                  <span>- Numbers (0 - 9)</span>
                </DescriptionStyled>
                <FlexStyled>
                  <GroupStyled top={'5px'} bottom={'0px'} width={'254px'}>
                    <LabelStyled bottom={'8px'}>New Password</LabelStyled>
                    <Field
                      id={'newPassword'}
                      component={MaskInput}
                      type='text'
                      width={'100%'}
                      name={'newPassword'}
                      placeholder={'New Password'}
                      onChange={handleChange}
                    />
                  </GroupStyled>
                  <GroupStyled top={'13px'} bottom={'13px'} width={'254px'}>
                    <LabelStyled bottom={'8px'}>Password confirmation</LabelStyled>
                    <Field
                      id={'passwordConfirmation'}
                      component={MaskInput}
                      type='text'
                      width={'100%'}
                      name={'passwordConfirmation'}
                      placeholder={'Password confirmation'}
                      onChange={handleChange}
                    />
                  </GroupStyled>
                </FlexStyled>
                <FlexStyled direction={'row'}>
                  <ButtonPrimary type='submit' buttonName='Send' />
                </FlexStyled>
              </Form>
            )}
          </Formik>
        </ContainerStyled>
      </BackgroundStyled>
    </div>
  );
};
