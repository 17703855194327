import React, { useMemo } from 'react';
import AppLogo from '../../assets/logos/logo-now-access.png';
import { Formik, Field, Form } from 'formik';
import { LinksStyled } from '../../components/LinksStyled/LinksStyled';
import { LabelStyled } from '../../components/LabelStyled/LabelStyled';
import { ContainerStyled } from '../../components/ContainerStyled/ContainerStyled';
import { BackgroundStyled } from '../../components/BackgroundStyled/BackgroundStyled';
import { FlexStyled } from '../../components/FlexStyled/FlexStyled';
import { ImgStyled } from '../../components/ImgStyled/ImgStyled';
import { GroupStyled } from '../../components/GroupStyled/GroupStyled';
import { LinkRouterStyled } from '../../components/LinkRouterStyled/LinkRouterStyled';
import {
  loginValidation,
} from '../../utils/validationSchema';
import { InputValidation } from '../../components/InputValidation/InputValidation';
import { CheckboxValidation } from '../../components/CheckboxValidation/CheckboxValidation';
import { ButtonPrimary } from '../../components/ButtonPrimary/ButtonPrimary';
import { MaskInput } from '../../components/MaskInput/MaskInput';
import { ApiAuth } from '../../api/ApiAuth';
import { setUserData } from '../../redux/actions/login';
import { ROUTES } from '../../constants/routes';
import {
  getUserDataFromLocalStorage,

  setUserDataToLocalStorage, setWelcomePage,
} from '../../utils/auth';
import store from '../../redux/store';
import { hideLoader, showLoader } from '../../utils/common';

export const LoginPage = () => {
  const apiAuth = useMemo(() => new ApiAuth(), []);

  return (
    <BackgroundStyled justify='center'>
      <ContainerStyled>
        <Formik
          validationSchema={loginValidation}
          initialValues={{
            email: '',
            password: '',
            terms: false,
          }}
          validateOnChange={false}
          validateOnBlur={false}
          enableReinitialize={false}
          onSubmit={(values, { resetForm }) => {
            const user = {
              username: values.email,
              password: values.password,
            };

            (async () => {
              showLoader();
              const [userDataResponse] = await Promise.all([
                await apiAuth.login(user),
              ]);

              if (userDataResponse) {
                if (userDataResponse?.data?.data?.user_settings?.show_welcome === true) {
                  setWelcomePage('true');
                }
                setUserDataToLocalStorage(userDataResponse.data.data);
                store.dispatch(setUserData(getUserDataFromLocalStorage()));
                hideLoader();
                resetForm();
              } else {
                hideLoader();
              }
            })();
          }}
        >
          {({ handleSubmit, handleChange }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <FlexStyled>
                <ImgStyled bottom={'30px'} src={AppLogo} alt='logo' />
              </FlexStyled>
              <LabelStyled fontSize={'14px'}>Intelligent Bond Issuance Control Panel</LabelStyled>
              <GroupStyled>
                <LabelStyled>Email</LabelStyled>
                <Field
                  id={'email'}
                  component={InputValidation}
                  type='email'
                  width={'100%'}
                  name={'email'}
                  placeholder={'email'}
                  onChange={handleChange}
                />
              </GroupStyled>
              <GroupStyled>
                <LabelStyled>Password</LabelStyled>
                <Field
                  id={'password'}
                  component={MaskInput}
                  width={'100%'}
                  name='password'
                  placeholder='password'
                  onChange={handleChange}
                />
              </GroupStyled>
              <GroupStyled>
                <FlexStyled direction={'row'} justify={'flex-start'}>
                  <Field
                    component={CheckboxValidation}
                    type='checkbox'
                    name='terms'
                    onChange={handleChange}
                  />
                  <LabelStyled>
                    I agree to{' '}
                    <LinksStyled
                      href='https://support.eppf.eu/hc/en-us/articles/360004543557-PRIVACY-AND-COOKIE-POLICY'
                      target='_blank'
                      rel='noopener'
                    >
                      the terms of data processing
                    </LinksStyled>
                  </LabelStyled>
                </FlexStyled>
              </GroupStyled>
              <FlexStyled>
                <ButtonPrimary type='submit' buttonName='Login' />
                <LinkRouterStyled to={ROUTES.forgotPassword}>Forgot Password?</LinkRouterStyled>
              </FlexStyled>
            </Form>
          )}
        </Formik>
      </ContainerStyled>
    </BackgroundStyled>
  );
};

