import { Route, Redirect } from 'react-router-dom';
import { ROUTES } from '../../../constants/routes';

export const PrivateRoute = ({ component: Component, ...rest }) => {
  let isLogin = localStorage.getItem('login');

  return (
    <Route
      {...rest}
      render={(props) =>
        isLogin === 'true' ? (
          <Component key={props.match.params.id || 'empty'} {...props} />
        ) : (
          <Redirect to={{ pathname: ROUTES.login, state: { from: props.location } }} />
        )
      }
    />
  );
};